import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'classnames';
import Input from 'components/common/TextInput';
import SiteStaffTable, { ISiteStaffHeaderItem } from './SiteStaffTable';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { SvgIcon } from '@mui/material';
import { ThreeDotIcon } from 'components/common/Icons';
import Popup from './components/Popup';
import { useMutation } from '@tanstack/react-query';
import { deleteCenter, postCenters, updateCenter } from 'services/api/study';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import AutoCompleteInput from './components/AutoCompleteInput';
import { useStudyStore } from 'store/StudyStore';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { FormattedMessage, useIntl } from 'react-intl';
import { ThreeDotsDropdown } from '../../components/common';

export interface ISiteDataItem {
  id: number;
  siteCode: string;
  studyId?: string;
  name: string;
  numberOfPatients: number;
  durationOfRecruitment: number;
  country: string;
  address: any;
}

export interface ISitesHeaderItem {
  name?: string;
  title?: string;
  width?: string;
}

interface IProps {
  headerItems: ISitesHeaderItem[];
  columns: ISiteDataItem[];
  onRowClick?: (siteCode: string) => void;
  refetch?: any;
  rowSelect?: boolean;
  selectedRow?: ISiteDataItem | null;
}

const SitesTable: React.FC<IProps> = (props) => {
  const intl = useIntl();
  const { headerItems, columns, onRowClick, selectedRow, refetch } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(-1);
  const [isBackupEdit, setIsBackupEdit] = useState(-1);
  const [isAction, setIsAction] = useState(false);
  const [isTooltipError, setIsTooltipError] = useState(false);
  const [isError, setIsError] = useState(false);
  const { studyId } = useParams();
  const methods = useForm();
  const { currentStudy, loggedInStudyUser } = useStudyStore();
  const values: any = methods.watch();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (loggedInStudyUser?.role?.permissions) {
      const foundPermision = loggedInStudyUser?.role?.permissions?.find((permsn) => permsn?.name === 'ADD_SITE_AND_SITE_STAFF')
      if (foundPermision && currentStudy?.status !== 'FREEZE') {
        setIsAction(true);
      }
    }
  }, [currentStudy, loggedInStudyUser])

  const getColumns = (cnt: any) => {
    const newColbs: any = [];
    cnt?.users?.length > 0 && cnt?.users?.map((usr: any) => {
      newColbs.push({
        id: usr?.id,
        staffName: `${usr?.user?.firstName}${usr?.user?.lastName || ''}`,
        role: usr?.role?.name,
        center: cnt,
        email: usr?.user?.userAccount?.email,
        phone: usr?.user?.userAccount?.phoneNumber,
        onboardingProgress: usr?.onboardingProgress,
      })
    })
    return newColbs;
  }

  const { mutate: addCenter } = useMutation<any, unknown, ISiteDataItem, unknown>(
    (data: ISiteDataItem) => postCenters(data),
    {
      onSuccess: () => {
        refetch();
        methods.reset();
        setIsError(false);
        toast.success(intl.formatMessage({ id: 'site.addMessage' }));
      },
      onError(e: any) {
        toast.error(e?.response?.data?.message);
      },
    },
  );

  const { mutate: editCenter } = useMutation<any, unknown, { id: number, data: any }, unknown>(
    ({ id, data }: any) => updateCenter(id, data),
    {
      onSuccess: () => {
        refetch();
        setIsEdit(-1);
        setIsError(false);
        toast.success(intl.formatMessage({ id: 'site.editMessage' }));
      },
      onError(e: any) {
        toast.error(e?.response?.data?.message);
      },
    },
  );

  const onSubmit: SubmitHandler<ISiteDataItem> = (data: any) => {
    if (!data?.name || !data?.address || !data?.numberOfPatients || !data?.durationOfRecruitment) {
      setIsError(true);
    }
    const isEdit = isBackupEdit;
    if (isAction && isEdit > -1 && studyId && data[`name-${isEdit}`] !== '' && data[`address-${isEdit}`] !== '' && data[`numberOfPatients-${isEdit}`] > -1 && data[`durationOfRecruitment-${isEdit}`] > -1) {
      const foundData = columns?.length > 0 && columns?.find((col) => `${col?.id}` === `${isEdit}`);
      if (foundData && foundData?.name === data[`name-${isEdit}`] && foundData?.address?.address === data[`address-${isEdit}`]?.address && foundData?.numberOfPatients === data[`numberOfPatients-${isEdit}`] && foundData?.durationOfRecruitment === data[`durationOfRecruitment-${isEdit}`]) {
        setIsEdit(-1);
        setIsError(false);
        return ;
      }
      setIsEdit(-1);
      try {
        editCenter({
          id: isEdit,
          data: {
            studyId: studyId,
            name: data[`name-${isEdit}`],
            durationOfRecruitment: parseInt(`${data[`durationOfRecruitment-${isEdit}`]}`, 10),
            numberOfPatients: parseInt(`${data[`numberOfPatients-${isEdit}`]}`, 10),
            address: {
              id: data[`address-${isEdit}`]?.id,
              latitude: data[`address-${isEdit}`]?.latitude,
              longitude: data[`address-${isEdit}`]?.longitude,
              address: data[`address-${isEdit}`]?.address,
              countryName: data[`address-${isEdit}`]?.countryName || data[`address-${isEdit}`]?.country?.name,
            }
          }
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    } else if (isAction && studyId && data?.name && data?.address && data?.numberOfPatients && data?.durationOfRecruitment) {
      try {
        addCenter({
          ...data,
          durationOfRecruitment: parseInt(`${data.durationOfRecruitment}`, 10),
          numberOfPatients: parseInt(`${data.numberOfPatients}`, 10),
          studyId
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };

  const { mutate: delCenter } = useMutation<any, unknown, number | string, unknown>(
    (id: number | string) => deleteCenter(id),
    {
      onSuccess: () => {
        toast.success(intl.formatMessage({ id: 'site.deleteMessage' }));
        refetch();
      },
      onError(e: any) {
        setIsModalOpen(true);
        toast.error(e?.response?.data?.message);
      },
    },
  );

  const handleIsEdit = (item: any) => {
    setIsEdit(item?.id);
    setIsBackupEdit(item?.id);
    methods.setValue(`name-${item?.id}`, item.name)
    methods.setValue(`numberOfPatients-${item?.id}`, item.numberOfPatients)
    methods.setValue(`durationOfRecruitment-${item?.id}`, item.durationOfRecruitment)
    methods.setValue(`address-${item?.id}`, item?.address);
  }

  const handleOutsideClick = useCallback(
    (event: MouseEvent | React.MouseEvent<HTMLElement, MouseEvent>, clickedOut: boolean) => {
      if (clickedOut && selectedRow === null) {
        onSubmit?.(values);
      }
    },
    [values],
  );

  useOutsideClick(containerRef, handleOutsideClick);
  
  const siteStaffHeaderItems: ISiteStaffHeaderItem[] = [
    {
      title: intl.formatMessage({ id: 'site.siteStaffName' }),
      name: 'name',
      width: '254px',
    },
    {
      title: intl.formatMessage({ id: 'site.role' }),
      name: 'role',
      width: '186px'
    },
    {
      title: intl.formatMessage({ id: 'login.emailAddress' }),
      name: 'email',
    },
    {
      title: intl.formatMessage({ id: 'site.phoneNumber' }),
      name: 'phone',
    },
    {
      title: intl.formatMessage({ id: 'site.onboardingProgress' }),
      name: 'onboarding',
    },
  ];

  return (
    <div ref={containerRef} className='font-sans'>
      <Popup isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      <FormProvider {...methods}>
        <form>
          <table className='w-full min-w-full bg-white'>
            <thead>
              <tr>
                {headerItems.map((r) => (
                  <th
                    key={r.name}
                    className={clsx(
                      'text-left text-base text-blue-ocean-deep font-semibold leading-6 tracking-[0.15px] px-2.5 py-2',
                      r.width,
                    )}
                  >
                    {r.title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {isAction && (<tr className='border-b border-b-gray-very-light group border-t border-t-gray-very-light bg-transparent hover:bg-blue-light-100 overflow-hidden rounded'>
                <td className='rounded-l'></td>
                <td className='px-2.5 py-1'>
                  <Input
                    name='name'
                    register={methods.register}
                    onKeyDown={(e: any) => {
                      if (e.key === 'Enter') {
                        onSubmit?.(values);
                      }
                    }}
                    inputClassName={`h-7 !rounded placeholder:text-sm ${isError && values?.name === '' ? '!border-error' : '!border-gray-very-light group-hover:!border-blue-normal'}`}
                    className='!mt-0' placeholder={intl.formatMessage({ id: 'site.addSite' })}
                  />
                </td>
                <td className='px-2.5 py-1'>
                  <Input
                    name='numberOfPatients'
                    type='number'
                    register={methods.register}
                    onKeyDown={(e: any) => {
                      if (e.key === 'Enter') {
                        onSubmit?.(values);
                      }
                    }}
                    inputClassName={`h-7 !rounded placeholder:text-sm !w-[8rem] ${isError && values?.numberOfPatients === '' ? '!border-error' : '!border-gray-very-light group-hover:!border-blue-normal'}`}
                    className='!mt-0' placeholder=''
                  />
                </td>
                <td className='px-2.5 py-1'>
                  <Input
                    name='durationOfRecruitment'
                    register={methods.register}
                    type='number'
                    onKeyDown={(e: any) => {
                      if (e.key === 'Enter') {
                        onSubmit?.(values);
                      }
                    }}
                    inputClassName={`h-7 !rounded placeholder:text-sm !w-[10rem] ${isError && values?.durationOfRecruitment === '' ? '!border-error' : '!border-gray-very-light group-hover:!border-blue-normal'}`}
                    className='!mt-0' placeholder=''
                  />
                </td>
                <td className='px-2.5 py-1'></td>
                <td className='px-2.5 py-1 rounded-r'>
                  <AutoCompleteInput
                    name="address"
                    methods={methods}
                    isError={isTooltipError || (!values?.address && isError)}
                    tooltipText={isTooltipError ? intl.formatMessage({ id: 'site.addressError' }) : ''}
                    onSubmit={onSubmit}
                    onPlaceSelected={(place: any) => {
                      if (place.address_components) {
                        setIsTooltipError(false);
                      } else {
                        setIsTooltipError(true);
                      }
                    }}
                  />
                </td>
              </tr>)}
              {columns.map((item) => (
                <React.Fragment key={item.siteCode}>
                  <tr
                    key={item.siteCode}
                    className={clsx('border-b relative border-b-gray-very-light ', selectedRow ?  ' !z-0': 'group hover:bg-blue-light-100 cursor-pointer', selectedRow && selectedRow.siteCode === item.siteCode && 'bg-[#0973d40a]')}
                    onClick={() => {
                      isEdit === -1 && onRowClick?.(item.siteCode);
                      isEdit !== item?.id && setIsEdit(-1);
                    }}
                  >
                    <td onClick={() => setIsEdit(-1)} className='px-2.5 text-gray-dark text-sm py-2.5 text-left table-cell'>
                      <p>{item.siteCode}</p>
                    </td>
                    <td onDoubleClick={() => {
                      handleIsEdit(item);
                      onRowClick?.('-1');
                    }} className='px-2.5 text-gray-dark text-sm py-2.5 text-left table-cell'>
                      {isAction && isEdit === item?.id ?
                        <Input
                          name={`name-${item?.id}`}
                          register={methods.register}
                          onKeyDown={(e: any) => {
                            if (e.key === 'Enter') {
                              onSubmit?.(values);
                            }
                          }}
                          inputClassName='h-7 !rounded placeholder:text-sm !border-blue-normal' className='!mt-0' placeholder={intl.formatMessage({ id: 'site.addSite' })}
                        /> :
                        <p>{item.name}</p>
                      }
                    </td>
                    <td onDoubleClick={() => handleIsEdit(item)} className='px-2.5 text-gray-dark text-sm py-2.5 text-left table-cell'>
                      {isAction && isEdit === item.id ?
                        <Input
                          name={`numberOfPatients-${item?.id}`}
                          type='number'
                          register={methods.register}
                          onKeyDown={(e: any) => {
                            if (e.key === 'Enter') {
                              onSubmit?.(values);
                            }
                          }}
                          inputClassName='h-7 !rounded placeholder:text-sm !border-blue-normal !w-[8rem]' className='!mt-0' placeholder=''
                        /> : <p>{item.numberOfPatients}</p>
                      }
                    </td>
                    <td onDoubleClick={() => handleIsEdit(item)} className='px-2.5 text-gray-dark text-sm py-2.5 text-left table-cell'>
                      {isAction && isEdit === item.id ?
                        <div className='flex items-center'>
                          <Input
                            name={`durationOfRecruitment-${item?.id}`}
                            type='number'
                            register={methods.register}
                            onKeyDown={(e: any) => {
                              if (e.key === 'Enter') {
                                onSubmit?.(values);
                              }
                            }}
                            inputClassName='h-7 !rounded placeholder:text-sm !border-blue-normal !w-[7rem]' className='!mt-0 mr-2' placeholder=''
                          /> <FormattedMessage id='appointment.month' />
                        </div> : <p>{item.durationOfRecruitment} <FormattedMessage id='appointment.month' /></p>
                      }
                    </td>
                    <td onClick={() => setIsEdit(-1)} className='px-2.5 text-gray-dark text-sm py-2.5 text-left table-cell'>
                      {item.country}
                    </td>
                    <td onDoubleClick={() => handleIsEdit(item)} className='px-2.5 relative text-gray-dark text-sm py-2.5 text-left table-cell'>
                      {isAction && isEdit === item.id ?
                        <AutoCompleteInput
                          name={`address-${item?.id}`}
                          methods={methods}
                          isError={isTooltipError}
                          tooltipText={isTooltipError ? 'Address can\'t be used' : ''}
                          onSubmit={onSubmit}
                          onPlaceSelected={(place: any) => {
                            if (place.address_components) {
                              setIsTooltipError(false);
                            } else {
                              setIsTooltipError(true);
                            }
                          }}
                        /> : <p className='truncate max-w-[18rem]'>{item?.address?.address}</p>
                      }
                    </td>
                    <td onClick={(e) => e.stopPropagation()}>
                      {isAction && (<ThreeDotsDropdown
                        dotIcon={<SvgIcon component={ThreeDotIcon} className='cursor-pointer text-blue-ocean-deep' />}
                        classes={{
                          container: 'hidden w-4 mr-3 z-100 group-hover:block',
                          // container: 'block',
                          button: '!px-0 absolute -right-5 -top-4',
                          content: '-top-10 !-right-8 !mt-0 w-fit border-none',
                        }}
                        customContent={
                          <div className='bg-blue-oil rounded-[2px] text-xs font-medium w-max text-left text-white'>
                          <span
                            onClick={() => item.id && delCenter(item.id)}
                            className='cursor-pointer block p-1.5 hover:bg-blue-ocean-deep'
                          >
                            <FormattedMessage id="delete" />
                          </span>
                          </div>
                        }
                      />)}
                    </td>
                  </tr>
                  {selectedRow && selectedRow.siteCode === item.siteCode &&
                    <tr>
                      <td colSpan={headerItems.length}>
                        <div className='bg-white/30 px-[38px] z-30 relative py-3.5 bg-white border border-t-0 border-[rgba(9, 115, 212, 0.35)] rounded-b'>
                          <SiteStaffTable isAction={isAction} selectedCenter={selectedRow} methods={methods} refetch={refetch} headerItems={siteStaffHeaderItems} columns={getColumns(item)} />
                        </div>
                      </td>
                    </tr>
                  }
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </form>
      </FormProvider>
    </div>
  );
};

export default SitesTable;
