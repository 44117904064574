import { Calendar, Checkbox, CheckBoxList, Radio, Select } from 'components/common';
import FileUploadWithProgressBar from 'components/common/FileUploadWithProgressBar';
import RatingStar from 'components/common/RatingStar';
import Input from 'components/common/TextInput';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { addAnsUploadFile } from 'services/api/steps';
import { useQuestionnaireStore } from 'store/QuestionnaireStore';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import clsx from 'classnames';
import QueryQuestionnaire from '../QueryQuestionaire';
import usePermissions from 'hooks/usePermissions';
import { useParams } from 'react-router-dom';
import { useStudyStore } from '../../../../store/StudyStore';
import { useCentersStore } from '../../../../store/centers';
import ScaleInput from '../../StudyBuilder/components/PreviewQuestions/ScaleInput';
import { setsdvAnswer } from '../../../../services/api/questionnaire';
import RepeatedQuestion from './RepeatedQuestion';
import { addAsterisk, formatAnsDate } from '../../../../utils/string';

interface IQuestionOptions {
  id: number;
  label: string;
  value: string;
}

interface QuestionProps {
  title: string;
  id?: number;
  required?: boolean;
  questionNumber?: number;
  questionType?: string;
  questionOptions?: any;
  inputPlaceHolder?: any;
  description?: any;
  methods?: any;
  answer: string;
  dateTime?: string;
  checkType?: (automatic?: any) => void;
  automatic?: any;
  uploadFileAns?: (res: any) => void;
  onBlur?: (value: any) => void;
  questionairetype?: string;
  dateTimeType?: string;
  stars?: string;
  isStandardForm?: boolean;
  styleType?: string;
  showAnswer?: boolean;
  isSiteStaff?: boolean;
  handleDeleteAnswer?: any;
  startValue?: any;
  endValue?: any;
  increment?: any;
  answerData?: any;
  refetch?: any;
  repeatedQuestions?: any;
  parentQuestionId?: any;
  isLastQuestion?: any;
  titleQuestionId?: any;
  otherError?: any;
  isSubLastQuestion?: boolean;
  isQuestionEmpty?: boolean;
  subtitle?: string;
  startLegend?: any;
  endLegend?: any;
  handleAnsBeforeSdv?: any;
  numberType?: 'DECIMAL' | 'INTEGER';
}

const PickerTypeEnum: any = {
  DATE: 'date',
  TIME: 'time',
  DATE_AND_TIME: 'datetime',
  YEAR: 'year',
  YEAR_AND_MONTH: 'yearmonth',
};

const Question = ({
  id,
  required,
  methods,
  title,
  answer,
  checkType,
  automatic,
  questionType,
  questionOptions,
  inputPlaceHolder = '',
  dateTimeType,
  onBlur,
  uploadFileAns,
  stars,
  showAnswer,
  isSiteStaff,
  questionairetype,
  startValue,
  endValue,
  increment,
  answerData,
  styleType,
  refetch,
  repeatedQuestions,
  handleDeleteAnswer,
  parentQuestionId,
  isLastQuestion,
  questionNumber,
  titleQuestionId,
  isSubLastQuestion,
  isQuestionEmpty,
  subtitle,
  numberType,
  isStandardForm,
  otherError,
  startLegend,
  endLegend,
  handleAnsBeforeSdv
}: QuestionProps) => {
  const intl = useIntl();
  const { questionnaireId } = useParams();
  const [selectedRadioBtns, setSelectedRadioBtns] = useState(undefined);
  const [uploadId, setUploadId] = useState(-1);
  const [progress, setProgress] = useState(0);
  const [newAnswerData, setNewAnswerData] = useState(answerData);
  const [selectedTable, setSelectedTable] = useState(null);
  const { stepsData, consentFormData } = useQuestionnaireStore();
  const { hasNotAccess, isFreezeStudy } = usePermissions();
  const isQueries = !hasNotAccess('VIEW_QUERIES');
  const isViewPatient = !hasNotAccess('VIEW_PATIENTS');
  const mutation = useMutation(addAnsUploadFile);
  const { loggedInStudyUser, currentStudy } = useStudyStore();
  const { centers, selectedCenter } = useCentersStore();

  const { mutate: updateSDV } = useMutation<unknown, unknown, number, unknown>(
    (answerId: number) => setsdvAnswer(answerId),
    {
      onSuccess(res: any) {
        setNewAnswerData(res);
      },
      onError() {},
    },
  );

  useEffect(() => {
    setNewAnswerData(answerData)
  }, [answerData]);

  const handleOnBlur = (e: any) => {
    onBlur?.(e);
  };

  const handleFileUpload = async (e: any, id: number) => {
    setUploadId(id);
    if (e === '') {
      setProgress(0);
      methods?.setValue(`fileUpload${id}`, '');
      return;
    }
    const maxAllowedSize = 9.8 * 1024 * 1024;
    if (e.size < maxAllowedSize) {
      if (id) {
        try {
          const res = await mutation.mutateAsync({
            stepId: stepsData?.stepId || 0,
            questionId: id,
            payload: e,
            onProgress: (progressEvent: any) => {
              const progres = Math.round((progressEvent.loaded / progressEvent.total) * 100);
              setProgress(progres);
              // You can update your UI with the progress here
            },
          });
          if (res) {
            uploadFileAns?.(res);
          }
        } catch (e: any) {
          setProgress(-100);
        }
      }
    } else {
      toast.error(intl.formatMessage({ id: 'error.fileLimitSize' }));
    }
  };

  const getCheckboxAns = (answer: string) => {
    const idList = answer.replace('[', '').replace(']', '').split(',');
    const valueList = questionOptions?.reduce((newValue: any, ques: any) => {
      if (idList.find((ids) => `${ids}` === `${ques.id}`)) {
        newValue.push(ques.value);
      }
      return newValue;
    }, []);
    return valueList || [];
  };

  const getAnsValue = (answer: string) => {
    const ans = answer.replace('[', '').replace(']', '');
    return questionOptions?.find((qs: any) => `${qs.id}` === `${ans}`)?.value || '';
  };

  useLayoutEffect(() => {
    if (answer) {
      setSelectedRadioBtns(methods.getValues(`radioBtns${id}`) || getAnsValue(`${answer}`));
    } else if (!answer && questionairetype === 'E_CRF') {
      setSelectedRadioBtns(undefined);
    }
  }, [answer, getAnsValue]);

  useEffect(() => {
    switch (questionType) {
      case 'SCALE':
        methods.setValue(`scale${id}`, answer);
        break;
      case 'TEXT_INPUT':
        methods.setValue(`textInput${id}`, answer);
        break;
      case 'NUMBER_VALUE':
        methods.setValue(`numberTxt${id}`, answer);
        break;
      case 'CHECKBOX':
        methods.setValue(`checkList${id}`, getCheckboxAns(`${answer}`));
        if (
          getCheckboxAns(`${answer}`) &&
          getCheckboxAns(`${answer}`)?.length > 0 &&
          getCheckboxAns(`${answer}`)?.find((val: any) => val.trim().toLowerCase() === 'other')
        ) {
          methods.setValue(`otherCheck-${id}`, newAnswerData?.answer || '');
        }
        break;
      case 'DROPDOWN':
        methods.setValue(`selectField${id}`, getAnsValue(`${answer}`));
        if (
          getAnsValue(`${answer}`) &&
          getAnsValue(`${answer}`)?.trim().toLowerCase() === 'other'
        ) {
          methods.setValue(`otherDropdown-${id}`, newAnswerData?.answer || '');
        }
        break;
      case 'DATE_TIME': {
        const newAns =
          answer && answer !== '' && dateTimeType && formatAnsDate(answer, dateTimeType);
        newAns &&
          `${new Date(newAns)}` !== 'Invalid Date' &&
          methods.setValue(`dateTime${id}`, new Date(newAns));
        break;
      }
      case 'RADIO_BUTTONS':
        methods.setValue(`radioBtns${id}`, getAnsValue(`${answer}`));
        if (
          getAnsValue(`${answer}`) &&
          getAnsValue(`${answer}`)?.trim().toLowerCase() === 'other'
        ) {
          methods.setValue(`otherRadio-${id}`, newAnswerData?.answer || '');
        }
        break;
      case 'RATING':
        methods.setValue(`rating${id}`, answer);
        break;
      case 'FILE_UPLOAD':
        methods.setValue(`fileUpload${id}`, answer);
        break;
      default:
        null;
    }
  }, [answer, questionType]);

  const handleScale = (e: any) => {
    methods.setValue(`scale${id}`, e);
  };

  const isConsentDone = (consentFormData && consentFormData?.status === 'SIGNED') || !(consentFormData && consentFormData?.status);
  const checkIsAnswer = () => {
    return checkType?.(automatic) && (newAnswerData ? !newAnswerData?.sdv : true) && isConsentDone;
  };

  const renderAnswer = () => {
    switch (questionType) {
      case 'SCALE':
        return (
          <div>
            <ScaleInput
              name={`scale${id}`}
              control={methods.control}
              start={startValue}
              end={endValue}
              startLegend={startLegend}
              endLegend={endLegend}
              mainClass='w-[190px] pl-4'
              islegendSide={(startLegend || endLegend) && parentQuestionId === null}
              increment={increment}
              onChange={handleScale}
              onBlur={(e) => handleOnBlur(parseInt(e, 10))}
              disabled={checkIsAnswer() ? false : true}
            />{' '}
          </div>
        );
      case 'TEXT_INPUT':
        return (
          <div
            className={clsx(
              'h-12 flex w-full items-center ',
              methods?.formState?.errors?.[`textInput${id}`] ? 'ml-0' : 'ml-0',
            )}
          >
            {showAnswer ? (
              showAnswer
            ) : (
              <Input
                name={`textInput${id}`}
                placeholder={inputPlaceHolder}
                register={methods?.register}
                noErrorMessage={true}
                onBlur={handleOnBlur}
                errors={methods?.formState?.errors}
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') {
                    e.target.blur(e.target.value);
                  }
                }}
                className='!mt-0 w-full'
                inputClassName={clsx(
                  'h-[28px] !border-blue-normal-35 !rounded-[4px]',
                  automatic && 'cursor-not-allowed',
                )}
                readOnly={checkIsAnswer() ? false : true}
              />
            )}
          </div>
        );
      case 'NUMBER_VALUE':
        return (
          <div className={'h-12 flex w-full items-center'}>
            {showAnswer ? (
              showAnswer
            ) : (
              <Input
                name={`numberTxt${id}`}
                placeholder={inputPlaceHolder}
                register={methods?.register}
                onBlur={handleOnBlur}
                type='number'
                errors={methods?.formState?.errors}
                noErrorMessage={true}
                onKeyDown={(e: any) => {
                  if (
                    e.key === 'e' ||
                    e.key === '-' ||
                    e.key === '+' ||
                    e.key === '.' ||
                    e.key === ','
                  ) {
                    if (numberType === 'DECIMAL' && e.key === '.') return;
                    e.preventDefault();
                  }
                  if (e.key === 'Enter') {
                    e.target.blur(e.target.value);
                  }
                }}
                className={clsx('!mt-0 w-full', automatic && 'cursor-not-allowed')}
                inputClassName='h-[28px] !border-blue-normal-35 !rounded-[4px] text-right !p-0 !pr-4 hide-arrow'
                readOnly={checkIsAnswer() ? false : true}
              />
            )}
          </div>
        );
      case 'DROPDOWN': {
        let otherErrMsg = null;

        if (otherError?.find((e: any) => e === `otherDropdown-${id}`)) {
          otherErrMsg = `Other ${intl.formatMessage({ id: 'isRequired' })}`;
        }
        return (
          <div
            className={clsx(
              '!my-0 w-full flex items-center h-[34px]',
              automatic && 'cursor-not-allowed',
              showAnswer && 'border-none',
            )}
          >
            {showAnswer ? (
              showAnswer
            ) : (
              <div className='w-full'>
                <Select
                  control={methods?.control}
                  label=''
                  onBlur={(e) => {
                    handleOnBlur(e);
                    methods?.setValue(`otherDropdown-${id}`, '');
                  }}
                  className={`shadow-none !min-w-[120px] ${!isStandardForm && '!max-w-[290px]'} !p-0 w-full `}
                  mainClass={clsx(
                    methods?.formState?.errors?.[`selectField${id}`]
                      ? '[&>button]:h-[34px] [&>button]:px-1 [&>button]:py-0 p-3 relative !border-danger-100'
                      : '[&>button]:p-1 !border-blue-normal-35',
                    '[&>div]:w-full !py-0 !px-2 rounded-[4px] !border !max-h-[28px] !bg-white [&>div>ul]:!pl-0',
                  )}
                  isSearchable={true}
                  disabled={checkIsAnswer() ? false : true}
                  options={questionOptions.map((item: IQuestionOptions) => ({
                    label: item.label,
                    value: item.value,
                  }))}
                  noTranslation={true}
                  isCheckIcon={true}
                  errors={methods?.formState?.errors}
                  noErrorMessage={true}
                  buttonClassName='!pl-0 text-blue-oil'
                  name={`selectField${id}`}
                  placeholder={intl.formatMessage({ id: 'questionnaire-field.select' })}
                  optionsClassName='!text-blue-oil !text-sm !py-[6px]'
                  optWrapperClassName={`!border !border-blue-normal-35 !rounded-b-[4px] !top-[28px] -left-[2px] ${questionOptions?.length < 9 ? '!h-fit' : '!max-h-[15rem] !h-[15rem]'}`}
                />
                {values &&
                  values[`selectField${id}`] &&
                  values[`selectField${id}`]?.trim().toLowerCase() === 'other' && (
                    <Input
                      name={`otherDropdown-${id}`}
                      placeholder={intl.formatMessage({ id: 'questionnaire-field.pleaseSpecify' })}
                      register={methods?.register}
                      noErrorMessage={true}
                      onBlur={() => handleOnBlur(values[`selectField${id}`])}
                      customError={otherErrMsg || ''}
                      errors={methods?.formState?.errors}
                      onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                          e.target.blur(e.target.value);
                        }
                      }}
                      className='!mt-2 w-full'
                      inputClassName={clsx(
                        'h-[28px] !border-blue-normal-35 !rounded-[4px]',
                        automatic && 'cursor-not-allowed',
                      )}
                      readOnly={checkIsAnswer() ? false : true}
                    />
                  )}
              </div>
            )}
          </div>
        );
      }
      case 'CHECKBOX': {
        let otherErrMsg = null;
        if (otherError?.find((e: any) => e === `otherCheck-${id}`)) {
          otherErrMsg = `Other ${intl.formatMessage({ id: 'isRequired' })}`;
        }
        return (
          <div className='h-max w-full [&>div>div>section:!justify-start]'>
            {showAnswer ? (
              showAnswer
            ) : (
              <>
                <CheckBoxList
                  register={methods.register}
                  methods={methods}
                  name={`checkList${id}`}
                  className={`!gap-x-0 w-full ${styleType === 'vertical' ? '!block' : 'flex !flex-wrap !max-w-[464px] !justify-start'}`}
                  options={questionOptions}
                  handleBlur={(e: any) => {
                    handleOnBlur(e);
                    methods.setValue(`checkList${id}`, e);
                    methods?.setValue(`otherCheck-${id}`, '');
                    setTimeout(() => {
                      methods?.trigger(`checkList${id}`);
                    }, 50);
                  }}
                  optionsClassName='!border-none !py-1.5 !pl-0 !gap-x-[0.5rem] [&>svg]:m-[2px]'
                  disabled={checkIsAnswer() ? false : true}
                  checkboxClassName='!w-4 !h-4 !rounded'
                  labelClassName={`text-base-black ${styleType === 'vertical' ? 'max-w-[20rem] min-w-[10rem] !w-full' : 'text-wrap max-w-[450px] min-w-[70px] !w-auto'}`}
                  noErrorMessage={true}
                />
                {values &&
                  values[`checkList${id}`] &&
                  values[`checkList${id}`]?.length > 0 &&
                  values[`checkList${id}`]?.find(
                    (val: any) => val.trim().toLowerCase() === 'other',
                  ) && (
                    <Input
                      name={`otherCheck-${id}`}
                      placeholder={intl.formatMessage({ id: 'questionnaire-field.pleaseSpecify' })}
                      register={methods?.register}
                      noErrorMessage={true}
                      onBlur={() => handleOnBlur(values[`checkList${id}`])}
                      errors={methods?.formState?.errors}
                      customError={otherErrMsg || ''}
                      onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                          e.target.blur(e.target.value);
                        }
                      }}
                      className='!mt-0 w-full'
                      inputClassName={clsx(
                        'h-[28px] !border-blue-normal-35 !rounded-[4px]',
                        automatic && 'cursor-not-allowed',
                      )}
                      readOnly={checkIsAnswer() ? false : true}
                    />
                  )}
              </>
            )}
          </div>
        );
      }
      case 'DATE_TIME':
        return (
          <div className='flex items-center gap-5 h-12 -ml-[1px]'>
            {showAnswer ? (
              showAnswer
            ) : (
              <Calendar
                control={methods.control}
                name={`dateTime${id}`}
                label=''
                className={clsx(
                  '!border-none no-border-mui [&>div]!flex-reverse [&>div>div>input]:!text-left',
                  methods?.formState?.errors?.[`dateTime${id}`]
                    ? ' [&>div]:!h-[34px] [&>div>div]:!border-danger-100 [&>div>.MuiInputBase-root]:!border [&>div>div>input]:p-[2px]'
                    : '[&>div>div>input]:pl-0',
                )}
                // format={'dd/MM/yyyy hh:mm aa'}
                placeholder='Select a date'
                errors={methods?.formState?.errors}
                noErrorMessage={true}
                inputDisable={true}
                onBlur={(e: any) => {
                  handleOnBlur(e);
                  setTimeout(() => {
                    methods?.trigger(`dateTime${id}`);
                  }, 50);
                }}
                // inputDisable={true}
                disabled={checkIsAnswer() ? false : true}
                type={(dateTimeType && PickerTypeEnum[dateTimeType]) || 'date'}
              />
            )}
          </div>
        );
      case 'RADIO_BUTTONS': {
        let otherErrMsg = null;
        if (otherError?.find((e: any) => e === `otherRadio-${id}`)) {
          otherErrMsg = `Other ${intl.formatMessage({ id: 'isRequired' })}`;
        }
        return (
          <div className='w-full'>
            <div className={clsx('h-max relative w-full', showAnswer && 'my-2')}>
              {showAnswer ? (
                showAnswer
              ) : (
                <>
                  <Radio
                    register={methods.register}
                    methods={methods}
                    name={`radioBtns${id}`}
                    options={questionOptions}
                    handleBlur={(e: any) => {
                      const radioValue = methods.getValues(`radioBtns${id}`);
                      handleOnBlur(e === radioValue ? '' : e);
                      methods?.setValue(`otherRadio-${id}`, '');
                      methods.setValue(`radioBtns${id}`, e === radioValue ? '' : e);
                      setTimeout(() => {
                        methods?.trigger(`radioBtns${id}`);
                      }, 50);
                    }}
                    optionsClassName={`w-full !gap-x-0 ${styleType === 'vertical' ? ' !block !my-1 ' : 'flex !flex-wrap !max-w-[464px] !justify-start'}`}
                    disabled={checkIsAnswer() ? false : true}
                    labelClassName={`text-base-black ${styleType === 'vertical' ? 'max-w-[20rem] min-w-[10rem] !w-full' : 'text-wrap min-w-[70px] !w-auto -mt-[4px]'}`}
                    className={clsx('border-none !py-2 !pl-0 !pr-1 !gap-x-[0.5rem] !items-start')}
                    wrapperClassName={
                      methods?.formState?.errors?.[`radioBtns${id}`]
                        ? '[&>div]:rounded-[6px] [&>div]:px-[2px] relative -left-[1px] '
                        : ''
                    }
                    radioClassName='!w-4 !h-4 !min-w-4 !min-h-4 gap-x-2'
                    selectedValue={selectedRadioBtns}
                    noErrorMessage={true}
                  />
                  {values &&
                    values[`radioBtns${id}`] &&
                    values[`radioBtns${id}`]?.trim().toLowerCase() === 'other' && (
                      <Input
                        name={`otherRadio-${id}`}
                        placeholder={intl.formatMessage({
                          id: 'questionnaire-field.pleaseSpecify',
                        })}
                        register={methods?.register}
                        noErrorMessage={true}
                        onBlur={() => handleOnBlur(values[`radioBtns${id}`])}
                        errors={methods?.formState?.errors}
                        customError={otherErrMsg || ''}
                        onKeyDown={(e: any) => {
                          if (e.key === 'Enter') {
                            e.target.blur(e.target.value);
                          }
                        }}
                        className='!mt-0 w-full'
                        inputClassName={clsx(
                          'h-[28px] !border-blue-normal-35 !rounded-[4px]',
                          automatic && 'cursor-not-allowed',
                        )}
                        readOnly={checkIsAnswer() ? false : true}
                      />
                    )}
                </>
              )}
            </div>
          </div>
        );
      }
      case 'FILE_UPLOAD':
        return (
          <div className='h-12 flex items-center'>
            {showAnswer ? (
              showAnswer
            ) : (
              <FileUploadWithProgressBar
                register={methods.register}
                disabled={checkIsAnswer() ? false : true}
                name={`fileUpload${id}`}
                id={id}
                imageCard={true}
                uploadId={uploadId}
                progress={progress}
                accept='.png,.jpeg,.jpg,.webp,.heic,.pdf,.docx,.doc,.txt,.odf'
                handleUpload={(e: any) => {
                  if (e === '') {
                    handleDeleteAnswer?.();
                  }
                  handleFileUpload?.(e, id || 0);
                }}
              />
            )}
          </div>
        );
      case 'RATING':
        return (
          <div className='h-12'>
            {showAnswer ? (
              showAnswer
            ) : (
              <RatingStar
                control={methods.control}
                name={`rating${id}`}
                stars={stars}
                onBlur={(e) => handleOnBlur(e)}
                disabled={checkIsAnswer() ? false : true}
              />
            )}
          </div>
        );
      default:
        return null;
    }
  };

  const foundCenter = centers?.find((center: any) => `${center?.id}` === `${selectedCenter}`);
  const isNotSiteStaff =
    !isSiteStaff &&
    (`${foundCenter?.address?.country?.id}` === `${loggedInStudyUser?.country?.id}` ||
      loggedInStudyUser?.country?.name === 'Global');

  const handleSetSDV = () => {
    methods?.setValue('verifySdv', true);
    if (questionType === 'TEXT_INPUT' || questionType === 'NUMBER_VALUE') {
      if (newAnswerData && newAnswerData?.id) {
        updateSDV(newAnswerData?.id);
        return;
      }
      handleAnsBeforeSdv(id, questionType);
      return;
    }
    newAnswerData && newAnswerData?.id && updateSDV(newAnswerData?.id);
  };

  const values = methods?.watch();

  const checkVerify = (newAnswerData: any) => {
    return (
      questionairetype === 'E_CRF' &&
      isViewPatient &&
      ((newAnswerData?.sdv && isFreezeStudy()) || !isFreezeStudy()) &&
      (isNotSiteStaff || (newAnswerData?.sdv && isSiteStaff))
    );
  };
  const isStyledQuestion = questionType && !['TITLE'].includes(questionType) && !parentQuestionId;

  const checkIsQuery =
    questionnaireId?.split('-')?.[0] !== 'sf' &&
    isQueries &&
    questionairetype === 'E_CRF' &&
    (isNotSiteStaff || isSiteStaff);
  const questionNumbering = titleQuestionId
    ? String.fromCharCode(65 + (questionNumber || 0)).toLowerCase()
    : questionNumber;

  return (
    <div
      className={`border-pink-lace border-dashed px-[32px] ${title && !parentQuestionId && !isLastQuestion && (questionType !== 'TITLE' || (questionType === 'TITLE' && isQuestionEmpty)) && (!titleQuestionId || isSubLastQuestion) ? 'border-b pb-[24px] mb-[20px] pt-[4px]' : titleQuestionId ? 'pb-[24px] pt-[4px]' : ''}`}
    >
      {parentQuestionId === null &&
        (questionType === 'REPEATED' ? (
          <RepeatedQuestion
            repeatedQuestions={repeatedQuestions}
            title={title}
            refetch={refetch}
            checkIsAnswer={checkIsAnswer}
            questionOptions={questionOptions?.length > 0 ? questionOptions : []}
            PickerTypeEnum={PickerTypeEnum}
            checkVerify={checkVerify}
            updateSDV={updateSDV}
            questionNumber={questionNumber}
            setSelectedTable={setSelectedTable}
            selectedTable={selectedTable}
            checkIsQuery={checkIsQuery}
          />
        ) : (
          <div className='flex justify-between gap-3 group items-start'>
            <div className='flex w-1/2 max-w-1/2 items-start'>
              <div>
                <p
                  className={`${questionType === 'TITLE' && '!text-xl'} flex font-normal font-sans text-blue-oil mr-1 text-left break-all ${titleQuestionId ? 'pl-[1.4rem] pb-4' : 'pb-4'}`}
                >
                  {isStyledQuestion ? (
                    <p className='flex'>
                      <span className='mr-1 break-keep'> {`${questionNumbering}. `} </span>
                      <p
                        className='px-1 mr-0.5'
                        dangerouslySetInnerHTML={{
                          __html: questionType !== 'TITLE' && required ? addAsterisk(title) : title,
                        }}
                      ></p>
                    </p>
                  ) : (
                    `${questionNumbering}. ${title}${questionType !== 'TITLE' && required ? ' *' : ''}`
                  )}
                </p>
                {subtitle && (
                  <p className='font-normal pl-[1.4rem] pb-4 font-sans text-blue-oil mr-1 text-left break-all'>
                    {subtitle}
                  </p>
                )}
              </div>
              <div className='w-3'>
                {questionType !== 'TITLE' && checkIsQuery ? (
                  <QueryQuestionnaire id={id || 0} methods={methods} />
                ) : null}
              </div>
            </div>
            {questionType !== 'TITLE' && (
              <div
                className={`flex justify-start gap-4 items-start ${isStandardForm ? 'w-1/2 max-w-1/2' : '!w-[49%] !min-w-[49%]'}`}
              >
                <div
                  className={`flex ${(isStandardForm || questionType === 'DROPDOWN') && 'w-full'} ${styleType === 'vertical' && (questionType === 'CHECKBOX' || questionType === 'RADIO_BUTTONS') && 'w-max'}`}
                >
                  {renderAnswer()}
                </div>
                {checkVerify(newAnswerData) ? (
                  <Checkbox
                    control={{
                      value: newAnswerData?.sdv,
                      checked: newAnswerData?.sdv,
                      onChange: handleSetSDV,
                    }}
                    name={`verifySdv${id}`}
                    mainClass={`${styleType === 'vertical' && (questionType === 'CHECKBOX' || questionType === 'RADIO_BUTTONS') ? '!my-1' : '!my-auto'} !w-[70px]`}
                    label={
                      newAnswerData?.sdv
                        ? intl.formatMessage({ id: 'questionnaire-field.verified' })
                        : intl.formatMessage({ id: 'questionnaire-field.verify' })
                    }
                    disabled={
                      newAnswerData?.sdv ||
                      currentStudy?.status != 'EXECUTION' ||
                      isFreezeStudy() ||
                      !isConsentDone
                    }
                    className={`!w-4 !h-4 ${newAnswerData?.sdv ? 'flex' : 'hidden'} group-hover:flex`}
                    inputClass={`!accent-base-white !bg-base-white !border-[1.185px] !w-4 !h-4 !shrink-0 ${values[`verifySdv${id}`] || newAnswerData?.sdv ? '!border-green-light !text-green-light' : '!border-blue-oil !text-blue-oil'} !appearance-none`}
                    svgClass={
                      values[`verifySdv${id}`] || newAnswerData?.sdv ? 'stroke-green-light' : ''
                    }
                  />
                ) : (
                  <></>
                )}
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default Question;
