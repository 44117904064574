import { IRoleCard } from 'types/roles';
import { IStudyUser } from 'types/user';
import { FiLoader } from 'react-icons/fi';
import clsx from 'classnames';
import User from './User';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getStudyUsers } from 'services/api/users';
import CreateNewUser from './CreateNewUser';
import { ICountry } from 'types/countries';
import usePermissions from 'hooks/usePermissions';
import { FormattedMessage, useIntl } from 'react-intl';

interface IProps {
  roles?: IRoleCard[];
  countries: ICountry[];
}

const UsersList: React.FC<IProps> = ({ roles = [], countries = [] }: IProps) => {
  const intl = useIntl();
  const { studyId } = useParams();
  const { isFreezeStudy, hasNotAccess } = usePermissions();

  const {
    data: users,
    isLoading: isUsersLoading,
    refetch: usersRefetch,
  } = useQuery({
    queryKey: ['user-list'],
    queryFn: () => getStudyUsers(studyId ?? ''),
    refetchOnMount: 'always',
  });
  const disableActions = isFreezeStudy() || hasNotAccess('ADD_USERS');

  const headers: { title: string; name: string; className?: string }[] = [
    { title: intl.formatMessage({ id: 'users.collaboratorName' }), name: 'collaboratorName' },
    { title: intl.formatMessage({ id: 'site.role' }), name: 'role' },
    { title: intl.formatMessage({ id: 'login.emailAddress' }), name: 'emailAddress' },
    { title: intl.formatMessage({ id: 'site.country' }), name: 'country' },
    { title: intl.formatMessage({ id: 'site.sites' }), name: 'sites' },
    { title: intl.formatMessage({ id: 'site.phoneNumber' }), name: 'phoneNumber' },
    { title: '', name: '', className: 'w-[30px]' },
  ];
  
  return (
  <div className='pt-[22px] h-[853px] flex flex-col justify-between items-center w-full relative overflow-auto no-scrollbar px-[24px] bg-white mt-4 container mx-auto pb-[38px] border-[1px] border-purple rounded-lg'>
    <div className='w-full'>
      <table className='relative table-auto  border-collapse  border-spacing-2 w-full '>
        <thead className=' pt-[6px]'>
        <tr className=''>
          {headers.map((r) => (
            <th
              key={r.name}
              className={clsx(
                'pb-3.5 px-2 pr-[10px] text-base font-medium text-blue-ocean-deep text-left',
                r?.className,
              )}
            >
              {r.title}
            </th>
          ))}
        </tr>
        </thead>
        {!disableActions && <CreateNewUser roles={roles} usersRefetch={usersRefetch} />}
        {isUsersLoading == false && !users ? (
          <div className='flex justify-center items-center flex-1 w-full h-full'>
            <p className='text-blue-ocean-deep'>
              <FormattedMessage id="error.errorGettingData" />
            </p>
          </div>
        ) : !users ? (
          <div className='relative left-[100%] flex justify-center items-center w-full'>
            <FiLoader className='w-20 h-20 animate-spin place-self-center text-blue-ocean-deep' />
          </div>
        ) : (
          <tbody className=''>
          {users.map((user: IStudyUser) => (
            <User
              roles={roles}
              key={user.id}
              user={user}
              usersRefetch={usersRefetch}
              countries={countries}
            />
          ))}
          </tbody>
        )}

        <div className='flex justify-center items-center w-full absolute top-[200] left-0'>
          <img
            className='h-[413px] w-[443px] m-x-auto'
            src={process.env.PUBLIC_URL + '/assets/images/users/user-list.png'}
            alt='users list'
          />
        </div>
      </table>
    </div>
  </div>
  );
};

export default UsersList;
