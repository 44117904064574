import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  FunctionComponent,
  ReactNode,
} from 'react';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { TextInput, Select } from 'components/common';
import clsx from 'classnames';
import usePermissions from 'hooks/usePermissions';
import QuestionMultiSelect from '../../../ParticipantPages/QuestionnaireStatus/QuestionMultiSelect';
import { useIntl } from 'react-intl';

interface IProps {
  handleCallBack?: any;
  children: ReactNode;
  props?: any;
  defaultValue?: any;
  defaultIsOpen?: boolean;
  inputPlaceHolder?: string;
  inputContainerClassName?: string;
  wrapperClassName?: string;
  inputType?: string;
  selectOptions?: { label: string; value: string | number }[];
  keyName?: string;
  renderValue?: any;
}

const EditCell: FunctionComponent<IProps> = ({
  handleCallBack = () => {},
  children,
  defaultValue,
  defaultIsOpen = false,
  inputPlaceHolder = '',
  inputContainerClassName = '',
  wrapperClassName,
  inputType,
  selectOptions,
  keyName = '',
  renderValue = null,
}) => {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState<any>(null);
  const [value, setValue] = useState(defaultValue);
  const containerRef = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLLabelElement>(null);
  const { isFreezeStudy, hasNotAccess } = usePermissions();

  const handleOutsideClick = useCallback(
    (event: MouseEvent | React.MouseEvent<HTMLElement, MouseEvent>, clickedOut: boolean) => {
      if (clickedOut && event.target !== labelRef.current && isOpen) {
        setIsOpen(false);
        handleCallBack({ [keyName]: value });
      }
    },
    [setIsOpen, value, isOpen],
  );

  useEffect(() => {
    if (defaultIsOpen) {
      setIsOpen(true);
    }
  }, [defaultIsOpen]);

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter' && isOpen) {
      handleCallBack({ [keyName]: value });
      setIsOpen(false);
    }
  };

  useOutsideClick(containerRef, handleOutsideClick);
  const disableActions = isFreezeStudy() || hasNotAccess('ADD_USERS');

  return (
    <td
      className={clsx(
        'cursor-pointer w-fit text-[#6C757D] text-center align-middle	 text-sm h-9',
        wrapperClassName,
        isOpen ? ' ' : '',
      )}
    >
      <div className={`${inputType === 'multiSelect' && 'h-full'}`}>
        {isOpen ? (
          <div ref={containerRef} className='w-full'>
            {inputType === 'multiSelect' ? (
                <QuestionMultiSelect
                  options={selectOptions ?? []}
                  control={{
                    value: value,
                    onChange: (value: any) => {
                      setValue(value);
                      handleCallBack({ [keyName]: value });
                      setIsOpen(false);
                    },
                  }}
                  name='newUserSite'
                  mainClass='mt-[4px]'
                  placeholder={intl.formatMessage({ id: 'patient-profile.add' })}
                  optWrapperClassName='!border !border-blue-light-200 !rounded-b-[6px] !min-w-full !top-[30px]'
                  renderValue={(e: any) => value && value?.length > 0 ? renderValue(e) : null}
                />
            ) : (inputType === 'select' ? (
              <Select
                customControl={{
                  value: `${value}` ?? '',
                  onChange: (val: any) => {
                    setValue(val);
                    handleCallBack({ [keyName]: val });
                    setIsOpen(false);
                  },
                }}
                noTranslation={true}
                name='countryName'
                placeholder='Country...'
                options={selectOptions ?? []}
                mainClass='h-[28px]'
                className={`bg-white border-2 border-[rgba(9,115,212,0.35)] rounded-[4px] h-[28px] w-full min-w-[100%] ${value !== 'all' ? 'bg-blue-normal' : 'bg-base-white'}  [&>div]:border-none`}
                buttonClassName={`w-full !py-0 !px-1 rounded-full ${value === 'all' ? '[&>span]:text-white [&>svg]:text-white' : '[&>span]:text-blue-ocean-deep [&>svg]:text-blue-ocean-deep'}`}
              />
            ) : (
              <TextInput
                control={{
                  value: value,
                  onChange: (e: any) => setValue(e.target.value),
                }}
                name={'title1'}
                placeholder={inputPlaceHolder ?? 'Type ...'}
                noTranslation={true}
                className={clsx(
                  'h-full !mt-0 [&>input]:h-[24px] [&>input]:mt-0 [&>input]:pl-1 pt-0 pb-0 [&>input]:rounded',
                  inputContainerClassName,
                )}
                inputClassName='border-r-none border-l-none border-t-none'
                onKeyDown={handleKeyDown}
                // typeValue={inputType}
                {...(inputType === 'number' && { type: 'number' })}
              />
            ))}
          </div>
        ) : (
          <div
            className={`flex justify-start items-center ${inputType === 'multiSelect' && 'h-full'}`}
            onClick={(e: any) => {
              if (!disableActions) {
                e.stopPropagation();
                setValue(defaultValue);
                setIsOpen(!isOpen);
              }
            }}
          >
            {children}
          </div>
        )}
      </div>
    </td>
  );
};

export default EditCell;
