import { useMutation, useQuery } from '@tanstack/react-query';
import { memo, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { FiLoader, FiPlusCircle } from 'react-icons/fi';
import { Calendar, Select } from 'components/common';
import Input from 'components/common/TextInput';
import {
  studyTypeOptions,
  areaOfMedicalOptions,
  interventionOptions,
  selectTrialPhaseOptions,
} from 'constants/study';
import { fetchDisease, fetchStudyById, updateStudy } from 'services/api/study';
import { IDisease, IStudy, StudyUpdate } from 'types/study';
import TextArea from 'components/common/TextArea';
import { addDays, differenceInDays } from 'date-fns';
import SelectToAdd from 'components/common/SelectToAdd';
import { Opt } from 'types/form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { FormattedMessage, useIntl } from 'react-intl';

type StudySettingsForm = Omit<StudyUpdate, 'id'>;

function Settings() {
  const { studyId } = useParams();
  const [conditionsOpt, setConditionOpt] = useState<Opt[]>([]);
  const [groups, setGroups] = useState<any>([]);
  const [isDisable, setIsDisable] = useState(false);
  const {
    data: loadedStudy,
    isLoading: loadingStudy,
    refetch,
  } = useQuery<IStudy>({
    queryKey: ['study'],
    queryFn: () => fetchStudyById(studyId as string),
    refetchOnMount: 'always',
  });

  const intl = useIntl();

  useEffect(() => {
    if (location?.pathname?.includes('details')) {
      setIsDisable(true);
    }
  }, []);

  const validationSchema = Yup.object({
    title: Yup.string().required(intl.formatMessage({ id: 'error.titleIsRequired' })),
    shortTitle: Yup.string().required(intl.formatMessage({ id: 'error.shortTitle' })),
    studyType: Yup.string().required(intl.formatMessage({ id: 'error.studyType' })),
    sponsorName: Yup.string().required(intl.formatMessage({ id: 'error.sponsorName' })),
    medicalArea: Yup.string().required(intl.formatMessage({ id: 'error.medicalArea' })),
    interventionType: Yup.string().required(intl.formatMessage({ id: 'error.interventionType' })),
    trialPhase: Yup.string().required(intl.formatMessage({ id: 'error.trialPhase' })),
    numberOfPatient: Yup.number().typeError(intl.formatMessage({ id: 'error.numberOfPatient' })).required(),
    conditions: Yup.array().required(intl.formatMessage({ id: 'error.conditions' })),
    startDate:
      Yup.date().required(intl.formatMessage({ id: 'error.startDate' })) ||
      Yup.string().required(intl.formatMessage({ id: 'error.startDate' })),
    enrollmentDate:
      Yup.date().required(intl.formatMessage({ id: 'error.enrollmentDate' })) ||
      Yup.string().required(intl.formatMessage({ id: 'error.enrollmentDate' })),
    completionDate:
      Yup.date().required(intl.formatMessage({ id: 'error.completionDate' })) ||
      Yup.string().required(intl.formatMessage({ id: 'error.completionDate' })),
    summaryStudy: Yup.string().required(intl.formatMessage({ id: 'error.summaryStudy' })),
    primaryObjective: Yup.string().required(intl.formatMessage({ id: 'error.primaryObjective' })),
    secondaryObjective: Yup.string().required(intl.formatMessage({ id: 'error.secondaryObjective' })),
    studyDesign: Yup.string().required(intl.formatMessage({ id: 'error.studyDesign' })),
    exclusionCriteria: Yup.string().required(intl.formatMessage({ id: 'error.exclusionCriteria' })),
    inclusionCriteria: Yup.string().required(intl.formatMessage({ id: 'error.inclusionCriteria' })),
    financialIncentiveAmount: Yup.string().required(intl.formatMessage({ id: 'error.financialIncentiveAmount' })),
  }).required();

  const { data: diseaseList } = useQuery<IDisease[]>({
    queryKey: ['disease'],
    queryFn: () => fetchDisease(),
  });

  const { mutate: updateStudyMutation } = useMutation<StudyUpdate, unknown, StudyUpdate, unknown>(
    (data: StudyUpdate) => updateStudy(data),
    {
      onSuccess() {
        refetch();
      },
    },
  );

  // const { mutate: updateLogo } = useMutation<any, unknown, any, unknown>(
  //   (data: any) => updateStudyLogo(data),
  //   {
  //     onSuccess: () => {
  //       refetch();
  //     },
  //   },
  // );

  useEffect(() => {
    if (diseaseList && diseaseList?.length > 0) {
      setConditionOpt(diseaseList.map((ds) => ({ label: ds.name, value: ds.id })));
    }
  }, [diseaseList]);

  const {
    control,
    register,
    reset,
    handleSubmit,
    watch,
    trigger,
    formState: { errors },
  } = useForm<StudySettingsForm>({
    resolver: yupResolver(validationSchema) as any,
    defaultValues: useMemo(() => {
      const diffDays =
        loadedStudy?.completionDate &&
        loadedStudy?.startDate &&
        differenceInDays(new Date(loadedStudy?.completionDate), new Date(loadedStudy?.startDate));
      return {
        ...loadedStudy,
        conditions:
          loadedStudy?.conditions && loadedStudy?.conditions?.length > 0
            ? loadedStudy.conditions?.map((c) => `${c.id}`)
            : undefined,
        durationOfStudy: diffDays || 0,
      };
    }, [loadedStudy]),
  });

  useEffect(() => {
    if (loadedStudy?.studyType === 'RANDOMIZED' && loadedStudy?.randomizationGroups) {
      const group = loadedStudy?.randomizationGroups?.map((g: any) => ({
        name: g?.name,
        title: `Randomized Group ${g?.id}`,
      }));
      setGroups(group);
    }
  }, [loadedStudy?.studyType]);

  useEffect(() => {
    refetch();
  }, [studyId]);

  const values: any = watch();

  useEffect(() => {
    const diffDays =
      loadedStudy?.completionDate &&
      loadedStudy?.startDate &&
      differenceInDays(new Date(loadedStudy?.completionDate), new Date(loadedStudy?.startDate));
    reset({
      ...loadedStudy,
      conditions:
        loadedStudy?.conditions && loadedStudy?.conditions?.length > 0
          ? loadedStudy.conditions?.map((c) => `${c.id}`)
          : undefined,
      durationOfStudy: diffDays || 0,
    });
  }, [loadedStudy]);

  const onSubmit: SubmitHandler<StudySettingsForm> = (data) => {
    if (data?.conditions?.length > 0) {
      data?.durationOfStudy && delete data.durationOfStudy;
      const updateData = {
        ...data,
        id: parseInt(studyId as string),
        numberOfPatient: parseInt(`${data?.numberOfPatient}`, 10),
        conditions: data.conditions?.map((c: string | number) => parseInt(`${c}`, 10)),
        startDate: data?.startDate && new Date(data?.startDate).toISOString(),
        enrollmentDate: data?.enrollmentDate && new Date(data?.enrollmentDate).toISOString(),
        completionDate: data?.completionDate && new Date(data?.completionDate).toISOString(),
      } as StudyUpdate;

      updateStudyMutation(updateData);
    }
  };

  const handleBlur = (e: any) => {
    if (
      e?.target?.name &&
      values &&
      (values[e.target.name] === '' ||
        (loadedStudy &&
          ((loadedStudy[e.target.name as keyof IStudy] &&
            values[e.target.name] &&
            loadedStudy[e.target.name as keyof IStudy] !== values[e.target.name]) ||
            loadedStudy[e.target.name as keyof IStudy] === null)))
    ) {
      trigger().then((res) => {
        res
          ? onSubmit({ ...values, [e.target.name]: values[e.target.name] })
          : toast.error(intl.formatMessage({ id: 'error.allFieldsRequired' }));
      });
    }
  };

  const handleBlurSelect = (data: any, key: any) => {
    const prevData: any = watch();
    if (key && data && prevData) {
      trigger().then((res) => {
        res ? onSubmit({ ...prevData, [key]: data }) : toast.error(intl.formatMessage({ id: 'error.allFieldsRequired' }));
      });
    }
  };

  const handleBlurCalendar = (key: any) => {
    const prevData: any = watch();
    if (key && prevData && prevData[key]) {
      trigger().then((res) => {
        res
          ? onSubmit({ ...prevData, [key]: prevData[key] })
          : toast.error(intl.formatMessage({ id: 'error.allFieldsRequired' }));
      });
    }
  };

  const financeErrorMsg = errors?.financialIncentiveAmount?.message as string | undefined;
  return (
    <div className='bg-base-white min-w-[1250px] w-auto h-fit flex my-10 mx-6 pt-[13px] pb-8 rounded-lg flex-col pl-[2.18rem] pr-4'>
      {loadingStudy ? (
        <FiLoader className='w-20 h-20 animate-spin  self-center text-blue-ocean-deep' />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <h1 className='text-[2rem] font-serif leading-[2.625rem] text-blue-ocean-deep text-left'>
            {intl.formatMessage({ id: 'create-study.studyDetails' })}
          </h1>
          <div className='w-full'>
            <Input
              className='mt-8'
              errors={errors}
              readOnly={isDisable}
              register={register}
              inputClassName={`${isDisable ? '!border-gray-light !text-gray-dark !bg-white !h-fit !max-h-[4.875rem]' : 'h-[4.875rem]'}`}
              onBlur={handleBlur}
              label={intl.formatMessage({ id: 'site.cipNumber' })}
              name='cipNumber'
            />
            <Input
              className='mt-8'
              errors={errors}
              readOnly={isDisable}
              inputClassName={`${isDisable ? '!border-gray-light !text-gray-dark !bg-white !h-fit !max-h-[4.875rem]' : 'h-[4.875rem]'}`}
              onBlur={handleBlur}
              register={register}
              label={intl.formatMessage({ id: 'create-study.studyTitle' })}
              name='title'
            />
            <Input
              className='mt-8'
              errors={errors}
              onBlur={handleBlur}
              register={register}
              readOnly={isDisable}
              inputClassName={`${isDisable ? '!border-gray-light !text-gray-dark !bg-white !h-fit !max-h-[4.875rem]' : 'h-[4.875rem]'}`}
              label={intl.formatMessage({ id: 'allStudies.shortTitle' })}
              name='shortTitle'
            />
          </div>
          <div className='w-full flex gap-8 mt-6'>
            <Select
              control={control}
              errors={errors}
              isSearchable={true}
              onBlur={(e) => handleBlurSelect(e, 'studyType')}
              className='flex-1'
              label={intl.formatMessage({ id: 'create-study.studyType' })}
              placeholder={intl.formatMessage({ id: 'create-study.selectedStudyType' })}
              disabled={isDisable}
              mainClass={`${isDisable && '!border-gray-light !text-gray-dark !bg-white'}`}
              options={studyTypeOptions}
              name='studyType'
            />
            <Select
              control={control}
              errors={errors}
              isSearchable={true}
              onBlur={(e: any) => handleBlurSelect(e, 'interventionType')}
              label={intl.formatMessage({ id: 'create-study.interventionType' })}
              placeholder={intl.formatMessage({ id: 'create-study.selectInterventionType' })}
              className='flex-1'
              name='interventionType'
              disabled={isDisable}
              mainClass={`${isDisable && '!border-gray-light !text-gray-dark !bg-white'}`}
              options={interventionOptions}
            />
            {/* <Input register={register} label='Disease' className='flex-1' name='treatedDisease' /> */}
          </div>
          {values?.studyType === 'RANDOMIZED' && <div className="w-full grid grid-cols-[48.8%_48.8%] gap-8 mt-6">
            {groups && groups?.length > 0 && groups?.map((group: any, i: any) => (
              <div className='' key={i}>
                <Input
                  className='!mt-0'
                  readOnly={isDisable}
                  inputClassName={`${isDisable ? '!border-gray-light !text-gray-dark !bg-white !h-fit !max-h-[4.875rem]' : 'h-[4.875rem]'}`}
                  onBlur={handleBlur}
                  register={register}
                  label={group?.title}
                  noTranslation={true}
                  errors={errors}
                  control={{
                    value: group?.name,
                    ...control,
                  }}
                  name={`group-${i + 1}`}
                />
              </div>
            ))}
            {groups?.length < 4 && <div
              className={`w-[75px] mt-6 flex flex-col justify-center items-center ${isDisable ? 'cursor-not-allowed' : 'cursor-pointer'}`}>
              <FiPlusCircle className={`w-6 h-6 stroke-2 ${isDisable ? 'text-gray-light' : 'text-blue-ocean-deep'}`} />
              <p className={`${isDisable ? 'text-gray-light' : 'text-blue-ocean-deep'} pt-3 text-sm font-semibold`}>
                <FormattedMessage id="study.addGroup" />
              </p>
            </div>}
          </div>}
          <div className='w-full flex gap-8 mt-8'>
            <Select
              control={control}
              errors={errors}
              isSearchable={true}
              onBlur={(e: any) => handleBlurSelect(e, 'medicalArea')}
              label={intl.formatMessage({ id: 'create-study.medicalSpecialty' })}
              placeholder={intl.formatMessage({ id: 'create-study.selectedArea' })}
              className='flex-1'
              disabled={isDisable}
              optWrapperClassName='!h-[15rem] !max-h-[15rem]'
              mainClass={`${isDisable && '!border-gray-light !text-gray-dark !bg-white'}`}
              options={areaOfMedicalOptions}
              name='medicalArea'
            />
            <Input
              register={register}
              errors={errors}
              className='flex-1 !mt-0'
              onBlur={handleBlur}
              readOnly={isDisable}
              inputClassName={`${isDisable ? '!border-gray-light !text-gray-dark !bg-white !h-fit !max-h-[4.875rem]' : 'h-[4.875rem]'}`}
              label={intl.formatMessage({ id: 'site.noOfPatients' })}
              name='numberOfPatient'
            />
            {/* <FileUpload label='Upload logo' register={register} name='logo' /> */}
          </div>
          <SelectToAdd
            control={control}
            errors={errors}
            options={conditionsOpt}
            defaultValue={
              loadedStudy?.conditions && loadedStudy?.conditions?.length > 0
                ? loadedStudy.conditions?.map((c) => `${c.id}`)
                : []
            }
            buttonClassName='!justify-between'
            label={intl.formatMessage({ id: 'create-study.conditions' })}
            onBlur={(e: any) => handleBlurSelect(e, 'conditions')}
            className='mt-8 w-full max-w-[21.1875rem]'
            disabled={isDisable}
            mainClass={`${isDisable && '!border-gray-light !text-gray-dark !bg-white'}`}
            placeholder={intl.formatMessage({ id: 'create-study.selectCondition' })}
            name='conditions'
          />
          <div className='w-full flex gap-8 mt-[3.75rem]'>
            <Calendar
              control={control}
              errors={errors}
              onBlur={() => handleBlurCalendar('startDate')}
              className={`flex-1 my-0 ${isDisable && 'no-border-mui [&>div>div>button]:hidden [&>div>input]:!text-left [&>div>input]:!text-black [&>div]:rounded-lg [&>div]:border [&>div]:border-gray-light '}`}
              label={intl.formatMessage({ id: 'create-study.studyStartDate' })}
              name='startDate'
              type='date'
            />
            <Calendar
              control={control}
              errors={errors}
              onBlur={() => handleBlurCalendar('enrollmentDate')}
              className={`flex-1 my-0 ${isDisable && 'no-border-mui [&>div>div>button]:hidden [&>div>input]:!text-left [&>div>input]:!text-black [&>div]:rounded-lg [&>div]:border [&>div]:border-gray-light '}`}
              minDate={new Date(values.startDate)}
              disabled={!values.startDate}
              label={intl.formatMessage({ id: 'create-study.enrollmentDate' })}
              name='enrollmentDate'
              type='date'
            />
            <Calendar
              control={control}
              errors={errors}
              onBlur={() => handleBlurCalendar('completionDate')}
              className={`flex-1 my-0 ${isDisable && 'no-border-mui [&>div>div>button]:hidden [&>div>input]:!text-left [&>div>input]:!text-black [&>div]:rounded-lg [&>div]:border [&>div]:border-gray-light '}`}
              disabled={!values.startDate || !values.enrollmentDate}
              minDate={
                values.enrollmentDate && new Date(addDays(new Date(values.enrollmentDate), 1))
              }
              label={intl.formatMessage({ id: 'create-study.studyEndDate' })}
              name='completionDate'
              type='date'
            />
            <Input
              register={register}
              readOnly
              label={intl.formatMessage({ id: 'create-study.durationOfStudy' })}
              name='durationOfStudy'
              className='flex flex-1 mt-0'
              inputClassName={`${isDisable ? '!border-gray-light !text-gray-dark !bg-white !h-fit !max-h-[4.875rem]' : 'h-[4.875rem]'}`}
            />
          </div>
          <div className='w-full flex gap-8 mt-8'>
            <div className='flex gap-8 w-full'>
              <Input
                errors={errors}
                register={register}
                onBlur={handleBlur}
                label={intl.formatMessage({ id: 'create-study.nameOfTheSponsor' })}
                name='sponsorName'
                className='flex w-3/5 !mt-0'
                readOnly={isDisable}
                inputClassName={`${isDisable ? '!border-gray-light !text-gray-dark !bg-white !h-fit !max-h-[4.875rem]' : 'h-[4.875rem]'}`}
              />
              <Input
                errors={errors}
                register={register}
                onBlur={handleBlur}
                label={intl.formatMessage({ id: 'create-study.sponsorEmail' })}
                name='sponsorEmail'
                className='flex w-3/5 !mt-0'
                readOnly={isDisable}
                inputClassName={`${isDisable ? '!border-gray-light !text-gray-dark !bg-white !h-fit !max-h-[4.875rem]' : 'h-[4.875rem]'}`}
              />
            </div>
          </div>
          <div className='w-full flex gap-8 mt-8'>
            <div className='flex gap-8 w-full'>
              <Select
                control={control}
                errors={errors}
                isSearchable={true}
                onBlur={(e: any) => handleBlurSelect(e, 'selectTrialPhase')}
                label={intl.formatMessage({ id: 'create-study.selectTrialPhase' })}
                className='w-2/5'
                placeholder={intl.formatMessage({ id: 'create-study.selectTrialPhase' })}
                name='trialPhase'
                disabled={isDisable}
                mainClass={`${isDisable && '!border-gray-light !text-gray-dark !bg-white'}`}
                options={selectTrialPhaseOptions}
              />
            </div>
            {/* <FileUpload
          label={intl.formatMessage({ id: 'create-study.uploadLogo' })}
          register={register}
          onFileUpload={handleUpload}
          accept='.png,.WebP,.jpeg'
          name='logo'
        /> */}
          </div>
          <div className='w-full flex gap-8'>
            <TextArea
              errors={errors}
              name='summaryStudy'
              onBlur={handleBlur}
              className='mt-12 flex-1'
              control={control}
              label={intl.formatMessage({ id: 'create-study.summaryOfStudy' })}
              readOnly={isDisable}
              textClass={`${isDisable && '!border-gray-light !text-gray-dark !bg-white'}`}
            />
          </div>
          <div className='w-full flex gap-8'>
            <TextArea
              errors={errors}
              name='primaryObjective'
              onBlur={handleBlur}
              className='mt-8 flex-1'
              control={control}
              label={intl.formatMessage({ id: 'create-study.primaryObjective' })}
              rows={4}
              readOnly={isDisable}
              textClass={`${isDisable && '!border-gray-light !text-gray-dark !bg-white'}`}
            />
          </div>
          <div className='w-full flex gap-8'>
            <TextArea
              errors={errors}
              name='secondaryObjective'
              className='mt-16 flex-1'
              onBlur={handleBlur}
              control={control}
              label={intl.formatMessage({ id: 'create-study.secondaryObjective' })}
              rows={4}
              readOnly={isDisable}
              textClass={`${isDisable && '!border-gray-light !text-gray-dark !bg-white'}`}
            />
          </div>
          <div className='w-full flex gap-8'>
            <TextArea
              errors={errors}
              name='studyDesign'
              onBlur={handleBlur}
              className='mt-16 flex-1'
              control={control}
              label={intl.formatMessage({ id: 'create-study.studyDesign' })}
              rows={4}
              readOnly={isDisable}
              textClass={`${isDisable && '!border-gray-light !text-gray-dark !bg-white'}`}
            />
          </div>
          <div className='w-full flex gap-8'>
            <TextArea
              errors={errors}
              name='inclusionCriteria'
              onBlur={handleBlur}
              className='mt-8 flex-1'
              control={control}
              label={intl.formatMessage({ id: 'create-study.inclusionCriteria' })}
              rows={4}
              readOnly={isDisable}
              textClass={`${isDisable && '!border-gray-light !text-gray-dark !bg-white'}`}
            />
          </div>
          <div className='w-full flex gap-8'>
            <TextArea
              errors={errors}
              name='exclusionCriteria'
              className='mt-8 flex-1'
              onBlur={handleBlur}
              control={control}
              label={intl.formatMessage({ id: 'create-study.exclusionCriteria' })}
              rows={4}
              readOnly={isDisable}
              textClass={`${isDisable && '!border-gray-light !text-gray-dark !bg-white'}`}
            />
          </div>
          <div>
            <div className='w-full flex gap-2.5'>
              <Input
                name='financialIncentiveAmount'
                className='mt-9 w-32'
                register={register}
                onBlur={handleBlur}
                label={intl.formatMessage({ id: 'create-study.amountOfFinancial' })}
                readOnly={isDisable}
                inputClassName={`${isDisable ? '!border-gray-light !text-gray-dark !bg-white !h-fit !max-h-[4.875rem]' : 'h-[4.875rem]'}`}
              />
              <p className='text-grey-dark text-base leading-6 font-inter self-end pb-3'>
                {intl.formatMessage({ id: 'create-study.perParticipant' })}
              </p>
            </div>
            {financeErrorMsg ? <div className='text-error text-left'>{financeErrorMsg}</div> : ''}
          </div>
          {!isDisable && <div className="flex gap-x-4 justify-end mt-7 mr-1">
            <button
              type="button"
              className="w-[150px] h-12 p-2.5 rounded-[90px] border border-blue-ocean-deep text-blue-ocean-deep justify-center items-center gap-2.5 inline-flex"
            >
              {intl.formatMessage({ id: 'back' })}
            </button>
            {/* <button
          type='submit'
          disabled={loadingStudy}
          className='w-[150px] h-12 p-2.5 text-base-white bg-blue-ocean-deep rounded-[90px] justify-center items-center gap-2.5 inline-flex'
        >
          {loadingStudy ? <FiLoader className='w-6 h-6 animate-spin self-center' /> : {intl.formatMessage({ id: 'save' })}}
        </button> */}
          </div>}
        </form>
        )}
    </div>
);
}

export default memo(Settings);
