import { UseFormRegister, FieldValues } from 'react-hook-form';
import { FieldErrors } from '../../../types/form';
import clsx from 'classnames';
import { TickIcon } from 'components/shared/QuestionnaireField/Icons';
interface IOption {
  label: string;
  value: string;
}
interface IProps {
  label?: string;
  name?: string;
  options: IOption[];
  className?: string;
  optionsClassName?: string;
  checkboxClassName?: string;
  labelClassName?: string;
  error?: string;
  control?: {
    onChange: (value: any) => void;
    onBlur?: (value: any) => void;
    value: any;
  };
  handleBlur?: (value: any) => void;
  placeholder?: string;
  defaultValue?: string[];
  register?: UseFormRegister<any> | any;
  errors?: FieldErrors<FieldValues>;
  disabled?: boolean;
  wrapperClassName?: string;
  noErrorMessage?: boolean;
  methods?: any;
  selectedOptionClassName?:string;
  onFocus?: any;
}
export const CheckBoxList = ({
  name = '',
  register,
  control,
  methods,
  label,
  options,
  className,
  optionsClassName,
  checkboxClassName,
  labelClassName,
  handleBlur,
  disabled,
  errors,
  wrapperClassName,
  noErrorMessage,
  selectedOptionClassName,
  onFocus,
  ...props
}: IProps) => {
  const errorMsg = errors?.[name]?.message as string | undefined;
  const checkProps = methods?.getValues(name);

  const getChecked = (value: any) => {
    return (
      checkProps &&
      checkProps?.length > 0 &&
      Array.isArray(checkProps) &&
      checkProps?.find((val: any) => val === value)
    );
  };

  const getData = (value: any) => {
    const checked = getChecked(value);

    const data = checked
      ? checkProps.filter((ch: any) => `${ch}` != `${value}`)
      : checkProps && checkProps && checkProps?.length > 0
        ? [...checkProps, value]
        : [value];

    control?.onChange(data && data?.length === 0 ? '' : data);
    methods?.setValue(name, data && data?.length === 0 ? '' : data);
    return data;
  };

  const handleChange = (value: any) => {
    getData(value);
  };

  return (
    <div className={clsx('flex flex-col', wrapperClassName)}>
      <div className='flex justify-start flex-col items-start'>
        {label && (
          <label
            data-testid='radioinput-label'
            htmlFor={name}
            className='pl-1 block text-blue-oil mb-2 font-medium leading-6'
          >
            {label}
          </label>
        )}
        <section className={clsx('flex gap-x-4 justify-start items-center flex-1', className)}>
          {options &&
            options.map(({ label, value }) => (
              <div
                key={value}
                onClick={() => {
                  if (!disabled) {
                    const data = getData(value);
                    handleBlur?.(data && data?.length === 0 ? '' : data);
                  }
                }}
                className={clsx(
                  'rounded-[12px] border-ocean-deep border-2 py-[9px] px-[17px] flex items-center gap-1 p-1 gap-x-3 relative',
                  disabled ? 'cursor-not-allowed' : 'cursor-pointer',
                  optionsClassName,
                  getChecked(value) && selectedOptionClassName
                )}
              >
                <input
                  {...(control ? { ...control } : register ? { ...register(name) } : {})}
                  {...props}
                  onChange={() => {
                    handleChange(value);
                  }}
                  value={value}
                  onFocus={onFocus}
                  checked={getChecked(value) ? true : false}
                  disabled={disabled}
                  type='checkbox'
                  className={clsx(
                    'w-6 h-6 accent-blue-ocean-deep relative peer shrink-0 appearance-none border-2 border-gray-medium rounded-sm  bg-white',
                    getChecked(value) && 'checked:bg-blue-ocean-deep checked:border-0',
                    disabled ? 'cursor-not-allowed' : 'cursor-pointer',
                    checkboxClassName,
                  )}
                />
                {getChecked(value) && <TickIcon />}
                <span className={clsx('text-base text-dark', labelClassName)}>{label}</span>
              </div>
            ))}
        </section>
      </div>
      {!noErrorMessage && errorMsg ? <div className='text-left text-error'>{errorMsg}</div> : ''}
    </div>
  );
};
export default CheckBoxList;
