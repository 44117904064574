
const getAnswer = (answers: any, qid: number) => {
  const found: any = answers?.length > 0 && answers.find((ans: any) => ans.questionId === qid);

  return found;
};

const getRepeat = (repeat: string) => {
  switch (repeat) {
    case 'MONTHLY': return 'Month';
    case 'WEEKLY': return 'Week';
    case 'DAILY': return 'Day';
    case 'YEARLY': return 'Year';
    default: return 'Day';
  }
}

export const createEproList = (newOpts: any) => {
  return newOpts?.reduce((arr: any, option: any) => {
    if (option?.type === 'QUESTIONNAIRE' && option?.step?.questionnaire && option?.step?.questionnaire?.eproSchedule) {
      const questionnaire = option?.step?.questionnaire;
      let totalfoundAns: any = 0;
      let foundAns: any = '';
      let totalScore: any = 0;

      questionnaire?.questions && questionnaire?.questions?.length > 0 && questionnaire.questions.map((question: any) => {
        foundAns = question?.id && getAnswer(option?.answers, question?.id);
        if (foundAns) {
          totalfoundAns++;
          if (question?.isScored && question?.questionOptions && question?.questionOptions?.length > 0) {
            question?.questionOptions?.map((questOpt: any) => {
              if (foundAns?.optionId && foundAns?.optionId?.includes('[')) {
                const optId = foundAns?.optionId?.split('[')[1].split(']')[0];
                if (`${optId}` === `${questOpt?.id}` && questOpt?.score) {
                  totalScore =  totalScore + questOpt?.score;
                }
              }
            })
          } else if (question?.isScored && foundAns && foundAns?.answer) {
            totalScore =  totalScore + parseInt(`${foundAns?.answer}`, 10);
          }
        }
      })

      const findStep = arr && arr?.length > 0 && arr?.findIndex((item: any) => item.stepId === option?.step?.id);
      let repeatIndex = 1
      arr.map((itm: any) => {
        if (itm?.questionnaireId === questionnaire?.id) {
          repeatIndex = itm?.eproRepeatation?.filter((ep: any) => ep?.occurence.includes(getRepeat(questionnaire?.eproSchedule?.repeat)))?.length + 1;
        }
        return itm;
      });

      const filteredQuestions: any = questionnaire?.questions && questionnaire?.questions?.length > 0 ? questionnaire?.questions?.filter((qst: any) => qst?.questionType != 'TITLE') : [];

      if (arr?.length > 0 && findStep > -1) {
        const tempArr = arr;
        tempArr[findStep] = {
          ...tempArr[findStep],
          eproRepeatation: [ ...tempArr[findStep].eproRepeatation, {
            id: option?.id,
            occurence: `${getRepeat(questionnaire?.eproSchedule?.repeat)} ${repeatIndex || 1}`,
            progress: totalfoundAns,
            score: totalScore,
            date: option?.dueDate,
            answers: option?.answers,
            totalQuestion: filteredQuestions && filteredQuestions?.length
          }]
        }
      } else {
        arr.push({
          stepId: option?.step?.id,
          questionnaireId: questionnaire?.id,
          patientId: option?.patientId,
          questionnaire: questionnaire,
          eproSchedule: questionnaire?.eproSchedule,
          eproRepeatation: [{
            id: option?.id,
            occurence: `${getRepeat(questionnaire?.eproSchedule?.repeat)} ${repeatIndex || 1}`,
            progress: totalfoundAns,
            score: totalScore,
            date: option?.dueDate,
            answers: option?.answers,
            totalQuestion: filteredQuestions && filteredQuestions?.length
          }]
        })
      }
    }
    return arr;
  }, [])
}