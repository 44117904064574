import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Checkbox } from 'components/common';
import { BoldIcon, ItalicIcon, UnderlinedIcon, PickColorIcon } from './TextEditIcons';
import clsx from 'classnames';
import { BsFillCircleFill } from 'react-icons/bs';
import { useIntl } from 'react-intl';

interface IProps {
  onChangeHandler?: any;
  onBlur?: any;
  defaultValue?: any;
  titleQuestionId?: boolean;
  filterRequired?: any;
  question?: any;
  tmpRequired?: any;
  handleRequired?: any;
  toggleQuestionTypePopover?: any;
  setValue?: any;
  handleKeyDown?: any;
  setRequired?: any;
}
const TextEditorToolBox: React.FC<IProps> = ({
  onChangeHandler,
  onBlur,
  defaultValue,
  titleQuestionId,
  filterRequired,
  question,
  tmpRequired,
  handleRequired,
  setValue,
}: IProps) => {
  const [showIcons, setShowIcons] = useState<boolean>(false);
  const [showColors, setShowColors] = useState<boolean>(false);
  // const [textColor, setTextColor] = useState<string>('#000');
  const editorRef: any = useRef<HTMLDivElement | null>(null);

  const intl = useIntl();
  const colors = [
    'rgb(0,0,0)',
    'rgb(77,77,77)',
    'rgb(153,153,153)',
    'rgb(230,229,229)',
    'rgb(255,255,255)',
    'rgb(228,72,77)',
    'rgb(227,152,80)',
    'rgb(228,230,89)',
    'rgb(151,231,89)',
    'rgb(75,231,89)',
    'rgb(78,231,157)',
    'rgb(84,230,230)',
    'rgb(84,153,227)',
    'rgb(85,74,226)',
    'rgb(156,72,227)',
  ];

  const toggleIcons = () => setShowIcons(!showIcons);

  const toggleColorPicker = () => setShowColors(!showColors);

  const applyCommand = (command: string, value: string = '') => {
    document.execCommand(command, false, value);
  };

  const applyColor = (color: string) => {
    // setTextColor(color);
    applyCommand('foreColor', color);
    setShowColors(false);
  };

  const setCaretPosition = (element: HTMLElement, offset: number) => {
    const range = document.createRange();
    const sel = window.getSelection();
    let currentOffset = 0;

    const traverseNodes = (node: Node) => {
      if (node.nodeType === 3) {
        const nextOffset = currentOffset + node.textContent!.length;
        if (currentOffset <= offset && offset <= nextOffset) {
          range.setStart(node, offset - currentOffset);
          range.setEnd(node, offset - currentOffset);
          return true;
        }
        currentOffset = nextOffset;
      } else if (node.nodeType === 1) {
        for (let i = 0; i < node.childNodes.length; i++) {
          if (traverseNodes(node.childNodes[i])) return true;
        }
      }
      return false;
    };

    traverseNodes(element);

    if (sel) {
      sel.removeAllRanges();
      sel.addRange(range);
    }
  };
  const getCaretPosition = (element: HTMLElement) => {
    let caretOffset = 0;
    const sel = window.getSelection();
    if (sel && sel.rangeCount > 0) {
      const range = sel.getRangeAt(0);
      const preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(element);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      caretOffset = preCaretRange.toString().length;
    }
    return caretOffset;
  };
  const handleInput = useCallback(() => {
    if (editorRef.current) {
      const caretPosition = getCaretPosition(editorRef.current);
      setValue(editorRef.current.innerText);
      setValue(editorRef.current.innerHTML);
      setCaretPosition(editorRef.current, caretPosition);
    }
  }, [setValue]);

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.innerHTML = defaultValue;
      editorRef.current.style.direction = 'ltr';
      editorRef.current.style.textAlign = 'left';

      editorRef.current.blur();
    }
  }, []);
  useEffect(() => {
    if (editorRef?.current) setValue(editorRef?.current?.innerHTML);
  }, [editorRef?.current?.innerHTML]);

  const handleKeyDownNew = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const { current } = editorRef;
    if (e.key === 'Enter') {
      e.preventDefault();
      onChangeHandler && onChangeHandler({ target: { value: current.innerHTML } });
      editorRef.current.blur();
    }
  };

  return (
    <>
      <div className='relative flex items-start justify-start w-[80%] '>
        <div
          contentEditable
          ref={editorRef}
          id={`text-toolbox-editor-${question?.sortOrder ?? 0}${question?.id ? question?.id : ''}`}
          className={clsx(
            'inline-block p-1 outline-none focus:outline-none border-[1px] hover:!border-purple focus:border-purple !rounded-[4px] min-h-8 w-[313px] text-left pl-2 group-hover:bg-white group-hover:border-purple',
            titleQuestionId ? '!border-gray-300' : 'border-white',
            document.activeElement === editorRef?.current && '!bg-white',
          )}
          style={{ direction: 'ltr', textAlign: 'left' }}
          onInput={handleInput}
          onBlur={() => {
            console.log('call onblur tool ');
            onBlur();
          }}
          onKeyDown={handleKeyDownNew}
        />

        <button
          onClick={toggleIcons}
          className='z-100 opacity-50 group-hover:opacity-100  flex gap-x-1 items-center justify-center ml-1 mt-1'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='21'
            height='21'
            viewBox='0 0 21 21'
            fill='none'
          >
            <path
              d='M7.875 17.5H13.125'
              stroke='#012C54'
              strokeWidth='0.913043'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M3.5 6.125V3.5H17.5V6.125'
              stroke='#012C54'
              strokeWidth='0.913043'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M10.5 3.5V17.5'
              stroke='#012C54'
              strokeWidth='0.913043'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='13'
            height='13'
            viewBox='0 0 10 10'
            fill='none'
            className={clsx(showIcons && 'rotate-180', 'mt-[2px]')}
          >
            <path
              d='M3.75 7.5L6.25 5L3.75 2.5'
              stroke='#012C54'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </button>

        {showIcons && (
          <div className='z-10 bg-white group-hover:bg-blue-very-light rounded flex space-x-2 mt-1'>
            <BoldIcon className={'text-blue-500'} onClick={() => applyCommand('bold')} />
            <ItalicIcon onClick={() => applyCommand('italic')} className={'text-blue-500'} />
            <UnderlinedIcon onClick={() => applyCommand('underline')} className={'text-blue-500'} />
            <PickColorIcon onClick={toggleColorPicker} className={'text-blue-500'} />
          </div>
        )}
        {!filterRequired && !['REPEATED', 'TITLE']?.includes(question?.questionType) ? (
          <Checkbox
            label={intl.formatMessage({ id: 'questionnaire-field.edit-tab.mandatory' })}
            name={'mandatary' + question?.id}
            control={{
              value: tmpRequired,
              checked: tmpRequired,
              onChange: (e) => {
                handleRequired(e.target.checked);
              },
            }}
            mainClass='!mt-2 flex justify-center items-center'
            className='ml-2 w-fit [&>label]:text-xs [&>label]:text-gray-300 [&>input]:w-3 [&>input]:h-3'
          />
        ) : null}
        {showColors && (
          <div className='absolute top-8 right-[20px] border border-gray-300 shadow-md p-2 rounded grid grid-cols-5 gap-2 !bg-white z-100'>
            {colors.map((color) => (
              <button
                key={color}
                onClick={() => applyColor(color)}
                className={clsx(
                  'w-6 h-6 rounded-full',
                  color === 'rgb(255,255,255)' && 'border-1 border-[rgb(204,206,209)]',
                )}
                style={{ backgroundColor: color }}
              >
                <BsFillCircleFill className='invisible' />
              </button>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default TextEditorToolBox;
