import { useState } from 'react';
import { Button, Modal } from 'components/common';
import { StudyBuilderContainer } from './components';
import { FiEye } from 'react-icons/fi';
import { updateStudyStatus, fetchStudyById } from 'services/api/study';
import { useMutation, useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { StudyUpdateStatus, IStudy } from 'types/study';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuestionnaireStore } from 'store/QuestionnaireStore';
import usePermissions from 'hooks/usePermissions';
import useStandardForms from './components/StandardFormsComponents/useStandardForms';
import { FormattedMessage } from 'react-intl';

export function StudyBuilder() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [preview, setPreview] = useState(false);
  // const [page, setPage] = useState('add-questionnaire');

  const [showPreview, setShowPreview] = useState(false);
  const closeModal = () => setIsModalOpen(false);
  const { studyId, questionnaireId } = useParams();

  const { selectedQuestionnairy, page, setPage } = useQuestionnaireStore();
  const { hasNotAccess } = usePermissions();
  const navigate = useNavigate();
  const { standardForms } = useStandardForms();

  const { data: loadedStudy, refetch: refetchStudy } = useQuery<IStudy>({
    queryKey: ['study'],
    queryFn: () => fetchStudyById(studyId as string),
  });

  const { mutate: changeStudyStatusMutation } = useMutation<
    StudyUpdateStatus,
    unknown,
    StudyUpdateStatus,
    unknown
  >((data: StudyUpdateStatus) => updateStudyStatus(data), {
    onSuccess: () => {
      closeModal();
      navigate(`/study/${studyId}/summary`);
    },
    onError(e: any) {
      toast.error(e?.response?.data?.message);
    },
  });

  const handleStudyStatus = () => {
    changeStudyStatusMutation({
      studyId: studyId,
      status: 'EXECUTION',
    });
  };
  console.log(
    'checkkk: ',
    loadedStudy &&
      (loadedStudy?.loggedInStudyUser?.role?.permissions?.length === 0 ||
        hasNotAccess('STUDY_BUILDER')),
  );
  if (
    loadedStudy &&
    (loadedStudy?.loggedInStudyUser?.role?.permissions?.length === 0 ||
      hasNotAccess('STUDY_BUILDER'))
  )
    return <div className='flex-1 bg-background-secondary'></div>;
  else
    return (
      <div className='mt-11  w-full h-[calc(100vh-190px)] flex flex-1 flex-col bg-background-secondary max-w-screen-2xl mx-auto px-6 xxl:px-0'>
        <Modal
          className={{
            header: 'text-2xl text-blue-ocean-deep mt-3 mx-9',
          }}
          isOpen={isModalOpen}
          onClose={closeModal}
          hideCloseIcon={true}
        >
          <p className='p-4 max-w-[413px] text-blue-ocean-deep text-[20px] font-medium leading-9 mb-7 text-center'>
            <FormattedMessage id='study-builder.executeMessage' />
          </p>
          <div className='flex justify-center items-center gap-x-[23px]'>
            <Button
              onClick={closeModal}
              className='bg-white border-2 border-blue-ocean-deep w-[130px] rounded-full h-[33px] flex justify-center items-center'
            >
              <span className='text-blue-ocean-deep  text-base font-medium leading-6'>Not now</span>
            </Button>
            <Button
              onClick={handleStudyStatus}
              className='border-2 border-blue-ocean-deep w-[130px] text-base bg-blue-ocean-deep  rounded-full h-[33px] flex justify-center items-center text-blue-ocean-deep text-[18px] font-medium leading-6 text-white hover:text-blue-ocean-deep'
            >
              <FormattedMessage id='study-builder.execute' />
            </Button>
          </div>
        </Modal>
        <div className='pl-6  flex justify-between items-center  bg-background-secondary pb-1'>
          <h2 className='cursor-pointer text-left font-serif text-blue-ocean-deep text-[24px]'>
            <FormattedMessage id='study-builder.welcomeStudyBuilder' />
          </h2>

          <div className='flex justify-center items-center pr-4'>
            <Button
              onClick={() => setIsModalOpen(true)}
              className='bg-white border-2 border-blue-ocean-deep rounded-full h-[33px] flex justify-center items-center pt-[7px]'
            >
              <span className='text-blue-ocean-deep text-[18px] font-medium leading-6'>
                <FormattedMessage id='study-builder.goExecution' />
              </span>
            </Button>
            {((selectedQuestionnairy?.id && selectedQuestionnairy?.questionnaire) ||
              showPreview ||
              questionnaireId === 'consent' ||
              page === 'standard-form') && (
              <Button
                onClick={() => setPreview(!preview)}
                className='flex justify-center items-center gap-x-2 pr-0'
              >
                <span className='text-blue-ocean-deep text-[18px] font-medium leading-6'>
                  <FormattedMessage id='study-builder.preview' />
                </span>
                {!preview ? (
                  <img
                    src={process.env.PUBLIC_URL + '/assets/images/study/closeEyeIcon.png'}
                    className='w-[1.85rem] h-[1.85rem]'
                    alt='close eye icon'
                  />
                ) : (
                  <FiEye className='text-[29px] text-blue-ocean-deep' />
                )}
              </Button>
            )}
          </div>
        </div>
        <StudyBuilderContainer
          page={page}
          setPage={setPage}
          preview={preview}
          setShowPreview={setShowPreview}
          refetchStudy={refetchStudy}
          standardForms={standardForms}
        />
      </div>
    );
}
