import { FunctionComponent, useState, useRef } from 'react';
import { ThreeDotsDropdown } from 'components/common';
import { ThreeDotIcon } from 'components/common/Icons';
import { IStudyUser, IEditStudyUser, IDeleteStudyUser } from 'types/user';
import { SvgIcon } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import {
  editStudyUser,
  blockStudyUser,
  deleteStudyUser,
  resetStudyUserPassword,
} from 'services/api/users';
import EditCell from './EditCell';
import { useParams } from 'react-router-dom';
import { ICountry } from 'types/countries';
import { toast } from 'react-toastify';
import { IRoleCard } from 'types/roles';
import clsx from 'classnames';
import usePermissions from 'hooks/usePermissions';
import { FormattedMessage, useIntl } from 'react-intl';
import { useCentersStore } from '../../../../../store/centers';

interface IProps {
  user: IStudyUser;
  countries: ICountry[];
  usersRefetch: any;
  roles: IRoleCard[];
}

const User: FunctionComponent<IProps> = ({ user, usersRefetch, roles = [], countries }: IProps) => {
  const intl = useIntl();
  const [isHover, setIsHover] = useState<any>(false);
  const { studyId } = useParams();
  const ref: any = useRef();
  const { isFreezeStudy, hasNotAccess } = usePermissions();
  const { centers } = useCentersStore();

  const { mutate: editStudyUserMutation } = useMutation<
    IEditStudyUser,
    unknown,
    IEditStudyUser,
    unknown
  >((data: IEditStudyUser) => editStudyUser(data), {
    onSuccess: () => {
      usersRefetch();
    },
    onError(error: any) {
      toast.error(error?.response?.data?.message);
    },
  });
  const { mutate: blockStudyUserMutation } = useMutation<number, unknown, number, unknown>(
    (data: number) => blockStudyUser(data),
    {
      onSuccess: () => {
        usersRefetch();
        handleCloseMoreBtn();
      },
      onError(error: any) {
        toast.error(error?.response?.data?.message);
      },
    },
  );
  const { mutate: resetStudyUserPasswordMutation } = useMutation<unknown, unknown, string, unknown>(
    (data: string) => resetStudyUserPassword(data),
    {
      onSuccess: () => {
        usersRefetch();
        handleCloseMoreBtn();
      },
      onError(error: any) {
        toast.error(error?.response?.data?.message);
      },
    },
  );

  const { mutate: deleteStudyUserMutation } = useMutation<
    unknown,
    unknown,
    IDeleteStudyUser,
    IDeleteStudyUser
  >((data: IDeleteStudyUser) => deleteStudyUser(data), {
    onSuccess: () => {
      handleCloseMoreBtn();
      usersRefetch();
    },
    onError(error: any) {
      toast.error(error?.response?.data?.message);
    },
  });

  const handleEditUser = (data: any) => {
    const payload: IEditStudyUser = {
      studyUserId: user.id,
      firstName: user.user.firstName,
      roleId: user.role.id ?? 1,
      email: user.user.userAccount.email,
      phoneNumber: user.user.userAccount.phoneNumber,
      studyId: Number(studyId) ?? 1,
      countryName: user.country.name,
      centerId: user.centerId,
      allowedCenters: user.allowedCenters,
      ...data,
    };

    if ((data.allowedCenters && user.allowedCenters?.length === 0 && data.allowedCenters?.length === 0) ||
      (data?.countryName && data?.countryName === user.country.name) ||
      (data?.phoneNumber && data?.phoneNumber === user.user.userAccount.phoneNumber) ||
      (data?.roleId && data?.roleId === user.role.id) ||
      (data?.firstName && data?.firstName === user.user.firstName)
    ) return '';

    editStudyUserMutation(payload);
  };

  const getCountriesOptions = () =>
    countries.map((country: ICountry) => ({
      label: country.name,
      value: country.name,
    }));

  const handleDeleteStudyUser = () => {
    deleteStudyUserMutation({ studyId: studyId ?? 1, userId: user.user.id });
  };

  const handleBlockStudyUser = (id: number) => {
    blockStudyUserMutation(id);
  };
  const handleResetStudyUserPassword = (email: string) => {
    resetStudyUserPasswordMutation(email ?? '');
  };
  const handleCloseMoreBtn = () => {
    if (ref.current) ref.current?.click();
  };
  const getRolesOptions = () =>
    roles
      .filter((role: IRoleCard) => role.category !== 'SITE_STAFF')
      .map((role: IRoleCard) => ({ label: role?.name, value: role.id }));

  const getSitesOptions = () => {
    return centers?.reduce((newCntr: any, cntr: any) => {
      if (`${cntr?.address?.country?.id}` === `${user?.country?.id}` || user?.country?.name === 'Global') {
        newCntr?.push({
          label: cntr?.name,
          value: cntr?.id,
          key: `${cntr?.address?.country?.countryIsoCode || ''}${cntr?.id}`,
        });
      }
      return newCntr;
    }, []);
  }
  const disableActions = isFreezeStudy() || hasNotAccess('ADD_USERS');

  const getSites = () => {
    const sitesData =  user?.allowedCenters?.reduce((newVal: any, val: number, i: number) => {
      const foundCenter = centers?.find((cntr: any) => cntr?.id === val);
      if (newVal != '' && foundCenter?.name?.includes(newVal)) return newVal;
      newVal = `${foundCenter?.name}${(user?.allowedCenters?.length === 1 || i === user?.allowedCenters?.length) ? '' : ', '}${newVal}`
      return newVal;
    }, '');
    return sitesData;
  }

  const renderValue = (value: any) => {
    return value?.map((val: number, i: number) => {
      const foundCenter = centers?.find((cntr: any) => cntr?.id === val);
      return `${foundCenter?.name}${i === value?.length ? '' : ', '}`;
    })
  }

  return (
    <tr
      ref={ref}
      onMouseEnter={!disableActions ? () => setIsHover(true) : () => {}}
      onMouseLeave={!disableActions ? () => setIsHover(false) : () => {}}
      className={clsx(
        `border-b-2 h-9 border-b-gray-very-light relative border-2 border-white  ${isHover ? 'rounded-lg bg-blue-light-100 !border-[rgba(9,115,212,0.35)]' : ''}`,
        user?.user?.userAccount?.isBlocked && 'opacity-40',
      )}
    >
      <EditCell
        handleCallBack={(data: any) => handleEditUser(data)}
        defaultValue={user.user.firstName}
        defaultIsOpen={false}
        inputPlaceHolder={intl.formatMessage({ id: 'users.typeOption' })}
        wrapperClassName='!w-[209px] px-2'
        keyName='firstName'
      >
        <span className='truncate text-ellipsis w-full inline-block text-left'>
          {user.user.firstName}
        </span>
      </EditCell>
      <EditCell
        wrapperClassName='!w-[259px] px-3'
        handleCallBack={(data: any) => handleEditUser(data)}
        defaultValue={user.role.id}
        defaultIsOpen={false}
        inputPlaceHolder={`${intl.formatMessage({ id: 'users.selectRole' })}...`}
        inputType='select'
        selectOptions={getRolesOptions()}
        keyName='roleId'
      >
        <span className='truncate text-ellipsis w-full inline-block text-left'>
          {user.role.name}
        </span>
      </EditCell>

      <td className='pl-2 text-left align-middle w-[209px] text-[#6C757D] text-sm py-[4px]'>
        {user.user.userAccount.email}
      </td>
      <EditCell
        wrapperClassName='!w-[209px] pl-2'
        handleCallBack={(data: any) => handleEditUser(data)}
        defaultValue={user.country.name}
        defaultIsOpen={false}
        inputPlaceHolder={`${intl.formatMessage({ id: 'users.countryName' })}...`}
        inputType='select'
        selectOptions={getCountriesOptions()}
        keyName='countryName'
      >
        <span className='truncate text-ellipsis w-full inline-block text-left'>
          {user.country.name}
        </span>
      </EditCell>
      <EditCell
        wrapperClassName='!w-[259px] px-3'
        handleCallBack={(data: any) => handleEditUser(data)}
        defaultValue={user.allowedCenters}
        defaultIsOpen={false}
        inputPlaceHolder={`${intl.formatMessage({ id: 'site.sites' })}...`}
        inputType='multiSelect'
        selectOptions={getSitesOptions()}
        keyName='allowedCenters'
        renderValue={renderValue}
      >
        <span className='w-full max-w-[160px] inline-block !text-left'>
          {getSites()}
        </span>
      </EditCell>
      <EditCell
        wrapperClassName='!w-[209px] pl-2'
        handleCallBack={(data: any) => handleEditUser(data)}
        defaultValue={user.user.userAccount.phoneNumber}
        defaultIsOpen={false}
        inputPlaceHolder={`${intl.formatMessage({ id: 'site.phoneNumber' })}...`}
        keyName='phoneNumber'
        inputType='number'
      >
        <span
          className={clsx(
            'truncate text-ellipsis w-full inline-block text-left',
            !user.user.userAccount.phoneNumber && 'text-white',
          )}
        >
          {user.user.userAccount.phoneNumber ?? '-'}
        </span>
      </EditCell>
      <td className='w-[30px] -mr-4'>
        {!user?.user?.userAccount?.isBlocked && (
          <ThreeDotsDropdown
            dotIcon={<SvgIcon component={ThreeDotIcon} className='cursor-pointer ' />}
            classes={{
              container: isHover ? 'absolute right-0 block' : 'absolute right-0 hidden',
              button: '!px-0',
              content: '-top-[67px] w-fit -mt-[51px] border-none w-max	 ',
            }}
            customContent={
              <ul className='bg-blue-oil rounded-[3px] pb-1 w-max	'>
                <li>
                  <span
                    onClick={() => handleBlockStudyUser(user.user.userAccount.id)}
                    className='cursor-pointer bg-blue-oil w-10 h-5 rounded-[2px] px-[7px] py-1 text-[10px] text-white'
                  >
                    <FormattedMessage id="users.blockSignIn" />
                  </span>
                </li>
                <li>
                  <span
                    onClick={() => handleResetStudyUserPassword(user.user.userAccount.email)}
                    className='cursor-pointer bg-blue-oil w-10 h-5 rounded-[2px] px-[7px] py-1 text-[10px] text-white'
                  >
                    <FormattedMessage id="reset-password" />
                  </span>
                </li>
                <li>
                  <span
                    onClick={() => handleDeleteStudyUser()}
                    className='cursor-pointer bg-blue-oil w-10 h-5 rounded-[2px] px-[7px] py-1 text-[10px] text-white'
                  >
                    <FormattedMessage id="delete" />
                  </span>
                </li>
              </ul>
            }
          />
        )}
      </td>
    </tr>
  );
};

export default User;
