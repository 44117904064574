import { Tick, Video } from 'components/common/Icons';
import { Calendar, Modal, TextInput, Tooltip } from 'components/common';
import { FormProvider, useForm } from 'react-hook-form';
import { FiClock, FiChevronDown, FiFileText, FiBell } from 'react-icons/fi';
import SelectTime from '../ParticipantAppointment/components/SelectTime';
import { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import MeetingCompleteCard from './MeetingCompleteCard';
import { FormattedMessage, useIntl } from 'react-intl';
import { consentStatusEnum } from 'types/questionnaire';
import { useMutation, useQuery } from '@tanstack/react-query';
import { format, isAfter, isBefore, isSameDay, isSameHour } from 'date-fns';
import { MeetingStatusR, editAppointment, fetchSlots, getAppointmentVideo } from 'services/api/appointment';
import { useNavigate, useParams } from 'react-router-dom';
import { useStudyStore } from 'store/StudyStore';
import { disableWeekends } from 'utils/number';
import useAddConsentForm from 'hooks/useAddConsentForm';
import { rtcProps } from 'services/api/auth';
import { toast } from 'react-toastify';
import useAuthStore from 'store/authStore';
import { useQuestionnaireStore } from 'store/QuestionnaireStore';
import { ISignForm, getConsetFormPatient, sendPatientEmail, signConsetForm } from 'services/api/questionnaire';
import { IAppointmentForm } from '../ParticipantAppointment/components/NewMeetingModal';
import usePermissions from 'hooks/usePermissions';
import { useCountryInfo } from '../../../../hooks/useCountryInfo';
import { ICountryInfo } from '../../../../types/countries';

const ConsentFormQuestion = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { participantId, studyId } = useParams();
  const { loggedInStudyUser } = useStudyStore();
  const [defaultExpanded, setDefaultExpanded] = useState(false);
  const [queryDate, setQueryDate] = useState<any>();
  const [completedStep, setCompletedStep] = useState(0);
  const [videoChannel, setVideoChannel] = useState('');
  const [appointmentId, setAppointmentId] = useState('');
  const [appointmentData, setAppointmentData] = useState<any>();
  const [consentData, setConsentData] = useState('');
  const [tooltipText, setTooltipText] = useState('sendReminderPatient');
  const { userData } = useAuthStore();
  const countryData: ICountryInfo = useCountryInfo();
  const { hasNotAccess, isFreezeStudy } = usePermissions();
  const { consentFormData, isDoneMeeting, setIsDoneMeeting } = useQuestionnaireStore();

  const [contractorData, setContractorData] = useState({
    ipAddress: '',
    city: '',
    country: ''
  });


  const isSiteStaff = loggedInStudyUser?.role?.category === 'SITE_STAFF' && loggedInStudyUser?.role?.permissions?.length > 0 && !hasNotAccess('ECONSENT') && !isFreezeStudy();

  const formMethods = useForm();
  const {
    setValue,
    reset
  } = formMethods;

  const mutation = useAddConsentForm();

  const { refetch: refetchConsent } = useQuery({
    queryKey: ['consetFormPatient', participantId],
    queryFn: () => getConsetFormPatient(participantId as string),
  });

  const { data: videoData, refetch: videoRefetch } = useQuery({
    queryKey: ['appointmentsVideo', appointmentId],
    queryFn: () => appointmentId && getAppointmentVideo(appointmentId),
  });

  const { data, refetch } = useQuery({
    queryKey: ['slots'],
    queryFn: () => queryDate && fetchSlots(parseInt(`${participantId}`, 10), parseInt(`${loggedInStudyUser?.id}`, 10), format(queryDate, 'yyyy-MM-dd')),
    refetchOnMount: 'always',
  });


  const { mutate: sendPatientRequest } = useMutation<any, unknown, any, unknown>(
    () => sendPatientEmail(consentFormData?.id),
    {
      onSuccess() {
        setTooltipText('reminderSent');
      },
      onError(error: any) {
        toast.error(error?.response?.data?.message ?? intl.formatMessage({ id: 'error.createFolder' }));
      },
    },
  );

  const { mutate: sendSignForm } = useMutation<any, unknown, any, unknown>(
    (payload: ISignForm) => signConsetForm(consentFormData?.id, parseInt(`${loggedInStudyUser?.id}`, 10), payload),
    {
      onSuccess() {
        closeModal();
        refetchConsent();
      },
      onError(error: any) {
        toast.error(error?.response?.data?.message ?? intl.formatMessage({ id: 'error.createFolder' }));
      },
    },
  );

  const { mutate: updateAppointment } = useMutation<any, unknown, IAppointmentForm, unknown>(
    (data: IAppointmentForm) => editAppointment(completedStep === 1 ? consentFormData?.clinicianAppointmentTwo?.id : consentFormData?.clinicianAppointmentOne?.id, data),
    {
      onSuccess: () => {
        toast.success(intl.formatMessage({ id: 'appointment.meetingEdit' }));
        refetchConsent();
      },
      onError(e: any) {
        toast.error(e?.response?.data?.message);
      },
    },
  );

  const isMeetingEnd = (endDate: any) => {
    return endDate && isBefore(new Date(endDate), new Date());
  }

  const isMeeting1NotEnd = consentFormData ? consentFormData?.status === consentStatusEnum?.WAITING_FOR_APPOINTMENT_2 && consentFormData?.clinicianAppointmentOne?.centerUserTimeslot.endDate && !isMeetingEnd(consentFormData?.clinicianAppointmentOne?.centerUserTimeslot.endDate) : false;
  const isMeeting2NotEnd = consentFormData ? consentFormData?.status === consentStatusEnum?.WAITING_FOR_SIGNATURE && consentFormData?.clinicianAppointmentTwo?.centerUserTimeslot.endDate && !isMeetingEnd(consentFormData?.clinicianAppointmentTwo?.centerUserTimeslot.endDate) : false;

  const consentStatus = isMeeting1NotEnd ? consentStatusEnum?.BOOKED_APPOINTMENT_1 : isMeeting2NotEnd ? consentStatusEnum?.BOOKED_APPOINTMENT_2 : consentFormData?.status;

  useEffect(() => {
    countryData && setContractorData({
      city: countryData?.city,
      country: countryData?.countryData?.name,
      ipAddress: countryData?.ip
    })
  }, [countryData]);

  const handleSignForm = () => {
    sendSignForm({
      date: new Date(),
      country: contractorData?.country,
      city: contractorData?.city,
      ipAddress: contractorData?.ipAddress
    })
  }

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    queryDate && refetch();
  }, [queryDate, loggedInStudyUser, participantId])

  useEffect(() => {
    console.log(consentFormData, 'consentFormData');
    if (!(consentFormData && consentFormData?.id)) {
      refetchConsent();
    }
    setCompletedStep(0);
    setIsDoneMeeting(0);
  }, [participantId])

  useEffect(() => {
    videoRefetch();
  }, [appointmentId])

  const bookAppointment1Step = () => {
    setIsDoneMeeting(0);
    setAppointmentId(consentFormData?.clinicianAppointmentOne?.appointment?.id)
    setAppointmentData({
      appointment: consentFormData?.clinicianAppointmentOne?.appointment,
      id: consentFormData?.id,
      title: consentFormData?.clinicianAppointmentOne?.appointment.title,
      start: consentFormData?.clinicianAppointmentOne?.centerUserTimeslot.beginDate,
      end: consentFormData?.clinicianAppointmentOne?.centerUserTimeslot.endDate,
      centerUser: consentFormData?.clinicianAppointmentOne?.centerUser,
    })
    setVideoChannel(consentFormData?.clinicianAppointmentOne?.appointment?.videoChannel);
    setValue('time', {
      begin: consentFormData?.clinicianAppointmentOne?.centerUserTimeslot?.beginDate,
      end: consentFormData?.clinicianAppointmentOne?.centerUserTimeslot?.endDate
    })
    setValue('startDate', new Date(consentFormData?.clinicianAppointmentOne?.centerUserTimeslot?.beginDate));
    setQueryDate(new Date(consentFormData?.clinicianAppointmentOne?.centerUserTimeslot?.beginDate));
  }

  const bookAppointment2Step = () => {
    setIsDoneMeeting(0);
    setCompletedStep(1);
    setAppointmentId(consentFormData?.clinicianAppointmentTwo?.appointment?.id)
    setAppointmentData({
      appointment: consentFormData?.clinicianAppointmentTwo?.appointment,
      id: consentFormData?.id,
      title: consentFormData?.clinicianAppointmentTwo?.appointment.title,
      start: consentFormData?.clinicianAppointmentTwo?.centerUserTimeslot.beginDate,
      end: consentFormData?.clinicianAppointmentTwo?.centerUserTimeslot.endDate,
      centerUser: consentFormData?.clinicianAppointmentTwo?.centerUser,
    })
    setVideoChannel(consentFormData?.clinicianAppointmentTwo?.appointment?.videoChannel);
    setValue('time', {
      begin: consentFormData?.clinicianAppointmentTwo?.centerUserTimeslot?.beginDate,
      end: consentFormData?.clinicianAppointmentTwo?.centerUserTimeslot?.endDate
    })
    setValue('startDate', new Date(consentFormData?.clinicianAppointmentTwo?.centerUserTimeslot?.beginDate));
    setQueryDate(new Date(consentFormData?.clinicianAppointmentOne?.centerUserTimeslot?.beginDate));
  }

  useEffect(() => {
    switch (consentStatus) {
      case consentStatusEnum.WAITING_FOR_APPOINTMENT_1:
        setCompletedStep(0);
        setIsDoneMeeting(0);
        break;
      case consentStatusEnum.BOOKED_APPOINTMENT_1:
        bookAppointment1Step();
        break;
      case consentStatusEnum.WAITING_FOR_APPOINTMENT_2:
        reset();
        setCompletedStep(1);
        break;
      case consentStatusEnum.BOOKED_APPOINTMENT_2:
        bookAppointment2Step();
        break;
      case consentStatusEnum.WAITING_FOR_SIGNATURE:
        setCompletedStep(2);
        setDefaultExpanded(true);
        break;
      case consentStatusEnum.SIGNED:
        setCompletedStep(3);
        setDefaultExpanded(true);
        break;
      case consentStatusEnum.PATIENT_DECLINED:
        setCompletedStep(3);
        break;
    }
    if (consentFormData && consentFormData?.questionnaire?.staticContents && consentFormData?.questionnaire?.staticContents?.length > 0) {
      setConsentData('');
      let newText = '';
      consentFormData?.questionnaire?.staticContents?.map((stData: any) => {
        newText = newText + ' ' + stData?.instruction;
        setConsentData(newText);
      });
    }
    if (consentFormData && consentFormData?.patientSignature && consentStatus !== consentStatusEnum.PATIENT_DECLINED) {
      setValue('signaturePatient', consentFormData?.patient?.userProfile?.firstName);
    }
  }, [consentFormData])

  const handleChange = (e: any) => {
    setValue('startDate', e);
    setQueryDate(e);
    setValue('time', {
      begin: null,
      end: null
    })
  }

  const formSteps = [
    `${intl.formatMessage({ id: 'appointment.meeting' })} 1`,
    `${intl.formatMessage({ id: 'appointment.meeting' })} 2`,
    intl.formatMessage({ id: 'appointment.signatures' }),
  ]

  const values = formMethods.watch();

  const handleSubmitForm = (time: any) => {
    const centerUserId = loggedInStudyUser?.id
    if (consentStatus === consentStatusEnum.BOOKED_APPOINTMENT_1 || consentStatus === consentStatusEnum.BOOKED_APPOINTMENT_2) {
      updateAppointment({
        begin: format(new Date(time?.begin), 'yyyy-MM-dd\'T\'HH:mm:ss.SSS\'Z\'') || '',
        end: format(new Date(time?.end), 'yyyy-MM-dd\'T\'HH:mm:ss.SSS\'Z\'') || '',
        appointmentLocationType: 'REMOTE',
        appointment: {
          appointmentType: 'CONSENT',
          appointmentStatus: 'PENDING',
          title: `Consent Meeting ${completedStep + 1}`,
          note: ''
        }
      })
    } else if (time && time?.begin && time?.end) {
      const mutationData = {
        id: consentFormData?.id,
        stepNo: completedStep + 1,
        data: {
          begin: format(new Date(time?.begin), 'yyyy-MM-dd\'T\'HH:mm:ss.SSS\'Z\'') || '',
          end: format(new Date(time?.end), 'yyyy-MM-dd\'T\'HH:mm:ss.SSS\'Z\'') || '',
          appointmentLocationType: 'REMOTE',
          centerUserId: centerUserId,
          repeat: null,
          appointment: {
            appointmentType: 'CONSENT',
            appointmentStatus: 'PENDING',
            title: `Consent Meeting ${completedStep + 1}`,
            patientId: Number(participantId),
            organizer: 'SITE_STAFF',
            note: ''
          }
        }
      };
      mutation.mutate(mutationData);
    }
  }

  const handleSetValue = (key: string, e: any) => {
    setValue(key, e);
    handleSubmitForm(e);
  }

  async function handleJoinCall(e: any) {
    e.preventDefault();
    try {
      const video = (await navigator.mediaDevices.enumerateDevices()).find(
        (device) => device.kind === 'videoinput',
      );

      if (video && consentData) {
        navigate(
          `/video-call/${rtcProps.appId}/${videoChannel}/${encodeURIComponent(videoData)}/${participantId}/${studyId}`, {
          state: { ...appointmentData, page: `consentForm-${completedStep + 1}`, consentData }
        }
        );
      } else {
        throw new Error(intl.formatMessage({ id: 'appointment.videoConnectError' }));
      }
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err.message);
      }
    }
  }

  const checkJoin = () => {
    return appointmentData?.appointmentStatus !== MeetingStatusR.DECLINED &&
      appointmentData?.appointmentStatus !== MeetingStatusR.CONFIRMED &&
      isAfter(new Date(), new Date(appointmentData?.start)) &&
      isBefore(new Date(), new Date(appointmentData?.end))
  }

  const isNotSigned = consentStatus !== consentStatusEnum.WAITING_FOR_SIGNATURE && consentStatus !== consentStatusEnum.SIGNED && consentStatus !== consentStatusEnum.PATIENT_DECLINED;

  const getTimeSlots = () => {
    return isSameDay(new Date(queryDate), new Date())
      ? data?.availableTimeslots.filter((e: any) => {
        const startDate = new Date(e.begin);
        const nowDate = new Date();
        return isSameHour(startDate, nowDate) ?
          nowDate.getMinutes() >= 30 ? startDate.getMinutes() >= 30 && isSameHour(startDate, nowDate) : isSameHour(startDate, nowDate)
          : isAfter(startDate, nowDate)
      })
      : isAfter(new Date(queryDate), new Date()) ? data?.availableTimeslots : []
  }

  return (
    <div className='w-full flex-1 text-left h-full overflow-y-auto  border-[1px] border-purple rounded-lg no-scrollbar bg-white'>
      <FormProvider {...formMethods}>
        <form>
          <div className='p-6 border-b border-purple'>
            <p className='text-xl leading-7 font-medium text-blue-ocean-deep'>
              <FormattedMessage id='study-builder.consentForm' />
            </p>
          </div>
          <div className='px-[1.875rem] py-[2.188rem]'>
            <div className=''>
              <div className='relative flex justify-between items-center'>
                <hr className='absolute top-[1.188rem] left-1/2 -translate-x-1/2 w-[calc(100%-3rem)] border-black' />
                {formSteps?.map((item: string, i: number) => (
                  <div className="z-10" key={i}>
                    <div
                      className={`w-[2.375rem] h-[2.375rem] mx-auto rounded-full ${completedStep === i ? 'bg-purple border-purple' : 'bg-[#FFEEEE] border-purple'} border-[0.313rem] flex justify-center items-center`}>
                      {completedStep > i && (<Tick
                        className={`${completedStep > i ? 'text-white' : 'text-gray-very-light'} !w-4 !h-[15px]`} />)}
                    </div>
                    <p className="text-sm font-semibold text-blue-ocean-deep mt-2">
                      {item}
                    </p>
                  </div>
                ))}
              </div>
              <div className={`w-full ${isNotSigned && 'mt-[5.875rem]'}`}>
                {consentStatus === consentStatusEnum.PATIENT_DECLINED && (
                  <div className='flex justify-center mt-[4rem]'>
                    <p className='text-blue-ocean-deep text-lg font-medium text-center leading-6 mr-1' dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'appointment.patientHasDeclined' }) }} ></p>
                  </div>
                )}
                {isSiteStaff && isNotSigned ? (
                  <div className='max-w-[27.5rem] mx-auto'>
                    {isDoneMeeting === 0 && (
                      <>
                        {(consentStatus === consentStatusEnum.WAITING_FOR_APPOINTMENT_1 || consentStatus === consentStatusEnum.WAITING_FOR_APPOINTMENT_2) && (
                          <div className='flex justify-center'>
                            <p className='text-blue-ocean-deep text-lg font-medium text-center leading-6 mr-1' dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'appointment.selectDateTime' }) }} ></p>
                            <p className='font-bold text-blue-ocean-deep text-lg text-center leading-6'><FormattedMessage id="appointment.meeting" />{' '}{completedStep + 1}{' '}:</p>
                          </div>
                        )}
                        {(consentStatus === consentStatusEnum.BOOKED_APPOINTMENT_1 || consentStatus === consentStatusEnum.BOOKED_APPOINTMENT_2) && (
                          <div className='flex justify-center'>
                            <p className='font-bold text-blue-ocean-deep text-lg text-center leading-6'>
                              <FormattedMessage id="appointment.meetingSetFor" />
                            </p>
                          </div>
                        )}
                        <div className='flex justify-center items-center gap-[1.125rem] mt-1'>
                          <Calendar
                            customControl={{
                              value: values.startDate,
                              onChange: (e) => handleChange(e),
                            }}
                            className='[&>div]:h-12 [&>div]:!border [&>div]:!border-blue-oil no-border-mui [&>div]:rounded-lg w-[11.875rem]'
                            inputDisable={true}
                            minDate={new Date()}
                            format='dd/MM/yyyy'
                            name="startDate"
                            type='date'
                            placeholder='Date'
                            iconPosition='start'
                            shouldDisableDate={(e: any) => disableWeekends(e)}
                            label=''
                          />
                          <SelectTime
                            icon={<FiClock className='w-5 h-5' />}
                            buttonClasses='!flex-row-reverse !border-blue-ocean-deep !text-blue-oil !justify-end !rounded-lg !h-12 !gap-5'
                            defaultValue={values?.time}
                            name='time' setValue={handleSetValue}
                            availableSlots={getTimeSlots() || []}
                          />
                        </div>
                        {(consentStatus === consentStatusEnum.BOOKED_APPOINTMENT_1 || consentStatus === consentStatusEnum.BOOKED_APPOINTMENT_2) && (
                          <button
                            onClick={handleJoinCall}
                            disabled={!checkJoin()}
                            className={`px-6 py-1 ${!checkJoin() ? 'border-gray-medium cursor-not-allowed' : 'border-blue-oil hover:bg-blue-light focus:bg-blue-light cursor-pointer text-blue-oil '} w-[12.875rem] h-[3.25rem] mt-[1.125rem] mx-auto flex justify-center items-center gap-[0.688rem] border rounded-lg`}
                          >
                            <Video />
                            <p className='text-base font-semibold'>
                              <FormattedMessage id="appointment.startVideoCall" />
                            </p>
                          </button>
                        )}
                      </>
                    )}
                    {consentStatus === consentStatusEnum.WAITING_FOR_APPOINTMENT_2 && isDoneMeeting === 1 && (
                      <div className='w-fit mx-auto mt-[4.188rem]'>
                        <MeetingCompleteCard isSiteStaff={isSiteStaff} meetingData={{ endDate: consentFormData?.clinicianAppointmentOne?.centerUserTimeslot?.endDate, meetingNo: 1 }} />
                      </div>
                    )}
                    {consentStatus === consentStatusEnum.WAITING_FOR_SIGNATURE && isDoneMeeting === 2 && (
                      <div className='w-fit mx-auto mt-[4.188rem]'>
                        <MeetingCompleteCard isSiteStaff={isSiteStaff} meetingData={{ endDate: consentFormData?.clinicianAppointmentTwo?.centerUserTimeslot?.endDate, meetingNo: 2 }} />
                      </div>
                    )}
                  </div>
                ) : (
                  <div className='flex justify-center'>
                    {(consentStatus === consentStatusEnum.WAITING_FOR_APPOINTMENT_1 || consentStatus === consentStatusEnum.WAITING_FOR_APPOINTMENT_2) && (
                      <p className='text-blue-ocean-deep text-lg font-medium text-center leading-6 mr-1'>
                        <FormattedMessage id={`appointment.waitMeeting${completedStep + 1}Set`} />
                      </p>
                    )}
                    {(consentStatus === consentStatusEnum.BOOKED_APPOINTMENT_1 || consentStatus === consentStatusEnum.BOOKED_APPOINTMENT_2) && (
                      <p className='text-blue-ocean-deep text-lg font-medium text-center leading-6 mr-1'>
                        <FormattedMessage id={`appointment.meeting${completedStep + 1}Set`} />
                      </p>
                    )}
                  </div>
                )}
                {isSiteStaff && consentStatus === consentStatusEnum.WAITING_FOR_SIGNATURE && isDoneMeeting === 2 && (
                  <div className='w-fit mx-auto mt-[4.188rem]'>
                    <MeetingCompleteCard isSiteStaff={isSiteStaff} meetingData={{ startDate: consentFormData?.clinicianAppointmentTwo?.centerUserTimeslot?.beginDate, meetingNo: 2 }} />
                  </div>
                )}
                {!isSiteStaff && consentStatus === consentStatusEnum.WAITING_FOR_SIGNATURE && (
                  <div className='flex justify-center mt-[5.875rem]'>
                    <p className='text-blue-ocean-deep text-lg font-medium text-center leading-6 mr-1' dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'appointment.awaitingSignature' }) }} ></p>
                  </div>
                )}
                {!isSiteStaff && consentStatus === consentStatusEnum.SIGNED && (
                  <div className='w-fit mx-auto mt-[4.188rem]'>
                    <MeetingCompleteCard isSiteStaff={isSiteStaff} />
                  </div>
                )}
                <div className={`${isNotSigned ? 'mt-24' : 'mt-10'}`}>
                  <Accordion className='!shadow-none' onChange={() => setDefaultExpanded(!defaultExpanded)} expanded={defaultExpanded}>
                    <AccordionSummary
                      className='!bg-background !rounded-lg !px-3 [&>div]:!my-2.5'
                      expandIcon={<FiChevronDown className='w-6 h-6' />}
                      aria-controls='panel1-content'
                      id='panel1-header'
                    >
                      <div className='flex items-center gap-2'>
                        <div className='w-6 h-6 bg-blue-ocean-deep rounded-full text-white flex items-center justify-center'>
                          <FiFileText />
                        </div>
                        <p className='text-sm font-semibold'>
                          <FormattedMessage id="study-builder.consentForm" />
                        </p>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails className='!bg-background !rounded-lg !text-gray-dark !mt-0.5 !pt-3 !px-6'>
                      <div className='text-left' dangerouslySetInnerHTML={{ __html: consentData }} />
                      {isSiteStaff && (consentStatus === consentStatusEnum.WAITING_FOR_SIGNATURE || consentStatus === consentStatusEnum.SIGNED || consentStatus === consentStatusEnum.PATIENT_DECLINED) && (
                        <div className='text-sm'>
                          <div className='flex justify-between items-center text-sm'>
                            <p className='text-sm w-max'>
                              <FormattedMessage id='appointment.pleaseSignForm' />
                            </p>
                            <div className='w-[60%]'>
                              <p onClick={() => consentFormData?.signer === null ? openModal() : null} className={`font-serif text-center text-xl ${consentFormData?.signer !== null ? 'cursor-not-allowed text-font' : 'cursor-pointer text-blue-ocean-deep'}`}>
                                {consentFormData?.signer?.user?.firstName ? consentFormData?.signer?.user?.firstName : <FormattedMessage id='appointment.clickHereSign' />}
                              </p>
                              {consentFormData?.studyUserSignature?.date ? (
                                <div className='flex justify-between border-t !border-gray-dark pt-2'>
                                  <p className={`text-sm ${consentFormData?.signer !== null ? 'text-font' : 'text-blue-ocean-deep'}`}><FormattedMessage id='appointment.signatureOf' /> Dr. {userData?.firstName}</p>
                                  <p className={`text-sm ${consentFormData?.signer !== null ? 'text-font' : 'text-blue-ocean-deep'}`}>
                                    {format(new Date(consentFormData?.studyUserSignature?.date), 'dd')} of {format(new Date(consentFormData?.studyUserSignature?.date), ' MMM,yyyy')} - {consentFormData?.studyUserSignature?.city} - {consentFormData?.studyUserSignature?.country}
                                  </p>
                                </div>
                              ) : (
                                <div className='flex justify-between border-t !border-gray-dark pt-2'>
                                  <p className='text-sm text-font'><FormattedMessage id='appointment.signatureOf' /></p>
                                  <p className='text-sm text-font'>Date - City - Country</p>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className='flex justify-between items-center text-sm'>
                            <div className='flex'>
                              <p className='text-sm w-max flex relative mr-1'><FormattedMessage id='appointment.pleaseAwaitPatientSign' /></p>
                              {consentFormData && consentFormData?.patientSignature === null && (
                                <Tooltip className='ml-14 -mt-2' text={intl.formatMessage({ id: `appointment.${tooltipText}` })} >
                                  <p onClick={() => tooltipText === 'sendReminderPatient' && isSiteStaff ? sendPatientRequest('') : null} className='align-sub'><FiBell /></p>
                                </Tooltip>
                              )}
                            </div>
                            <div className='w-[60%]'>
                              {consentStatus === consentStatusEnum.PATIENT_DECLINED ? (
                                <p className='text-font font-serif text-center text-xl mt-[3rem]' dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'appointment.patientHasDeclined' }) }} ></p>
                              ) : (
                                  <TextInput
                                  register={formMethods.register}
                                  name='signaturePatient'
                                  label=''
                                  readOnly
                                  placeholder=''
                                  inputClassName='font-serif cursor-not-allowed text-font text-center text-xl placeholder:text-blue-ocean-deep border-none !bg-transparent'
                                  className='w-full'
                                />
                              )}
                              {consentFormData && consentStatus !== consentStatusEnum.PATIENT_DECLINED && consentFormData?.patientSignature ? (
                                <div className='flex justify-between border-t !border-gray-dark pt-2'>
                                  <p className='text-sm text-font'><FormattedMessage id='appointment.signatureOf' /> {consentFormData?.patient?.userProfile?.firstName}</p>
                                  {consentFormData?.patientSignature?.date && <p className='text-sm text-font'>{format(new Date(consentFormData?.patientSignature?.date), 'dd')} of {format(new Date(consentFormData?.patientSignature?.date), ' MMM,yyyy')}  - {consentFormData?.patientSignature?.city} - {consentFormData?.patientSignature?.country}</p>}
                                </div>
                              ) : (
                                <div className='flex justify-between border-t !border-gray-dark pt-2'>
                                  <p className='text-sm text-font'><FormattedMessage id='appointment.signatureOf' /></p>
                                  <p className='text-sm text-font'>Date - City - Country</p>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </form>
        <Modal
          className={{
            content: 'p-8',
          }}
          hideCloseIcon
          isOpen={isModalOpen}
          onClose={closeModal}
        >
          <h1 className='text-blue-oil mb-6 text-2xl font-normal font-serif leading-[34px] tracking-tight'>
            <FormattedMessage id="appointment.consentPolicy" />
          </h1>
          <p className='text-xl mb-12 max-w-[34rem] font-medium leading-7'>
            <FormattedMessage id="appointment.agreeSignMessage" />
          </p>
          <div className='flex gap-3 justify-end'>
            <button
              type='button'
              title='sign-form'
              onClick={handleSignForm}
              className='h-10 p-2.5 px-11 text-base-white bg-blue-ocean-deep rounded-[90px] justify-center items-center gap-2.5 inline-flex'
            >
              <FormattedMessage id="yes" />
            </button>
            <button
              type='button'
              title='close-modal'
              onClick={closeModal}
              className='h-10 p-2.5 px-11 rounded-[90px] border border-blue-ocean-deep text-blue-ocean-deep justify-center items-center gap-2.5 inline-flex'
            >
              <FormattedMessage id="no" />
            </button>
          </div>
        </Modal>
      </FormProvider>
    </div>
  );
};

export default ConsentFormQuestion;
