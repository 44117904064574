import InfoBar from './InfoBar';
import Input from '../../../../../components/common/TextInput';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import usePermissions from '../../../../../hooks/usePermissions';
import { useStudyStore } from '../../../../../store/StudyStore';
import useAddPatientDataForm from '../../../../../hooks/useAddPatientDataForm';
import { FormattedMessage, useIntl } from 'react-intl';
import { useOutsideClick } from '../../../../../hooks/useOutsideClick';
import useGetPatientData from '../../../../../hooks/useGetPatientData';
import { useParams } from 'react-router-dom';

const InfoCard: React.FC = () => {
  const intl = useIntl();
  const { participantId } = useParams();
  const methods = useForm();
  const { hasNotAccess, isFreezeStudy } = usePermissions();
  const { editNurseMutation, nurseMutation, editParticipantsMutation } = useAddPatientDataForm();
  const { patientData } = useGetPatientData();
  const { loggedInStudyUser } = useStudyStore();
  const [isEdit, setIsEdit] = useState(-1);
  const [isEditContact, setIsEditContact] = useState(-1);
  const [isNurseForm, setIsNurseForm] = useState(false);
  const [nurseList, setNurseList] = useState<any>([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const isSiteStaff = loggedInStudyUser?.role?.category === 'SITE_STAFF' && loggedInStudyUser?.role?.permissions?.length > 0 && !hasNotAccess('ADD_PATIENTS') && !isFreezeStudy();
  const values = methods.watch();

  useEffect(() => {
    setIsEdit(-1);
  }, []);

  useEffect(() => {
    setNurseList(patientData && patientData?.nurses && patientData?.nurses?.length > 0 && patientData?.nurses?.map((nurse: any) =>
      ({
        ...nurse,
        email: nurse?.userProfile?.userAccount?.email,
        firstName: nurse?.userProfile?.firstName,
      })
    ));
  }, [patientData]);

  const onSuccess = () => {
    setIsEdit(-1);
    methods?.reset();
    setIsEditContact(-1);
  }

  const onSubmit: any = (data: any) => {
    if (isSiteStaff && (data?.phoneNumber || data?.address)) {
      try {
        editParticipantsMutation.mutate(
          {
            id: parseInt(`${participantId}`, 10),
            firstName: patientData?.firstName,
            email: patientData?.email,
            phoneNumber:  data.phoneNumber !== patientData?.userProfile?.userAccount?.phoneNumber ? data.phoneNumber : patientData?.userProfile?.userAccount?.phoneNumber,
            address: data.address !== patientData?.userProfile?.address ? data.address : patientData?.userProfile?.address,
          }, { onSuccess }
        );
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    } else if (isSiteStaff && isEdit > -1 && data[`email-${isEdit}`] !== '' && data[`firstName-${isEdit}`] !== '') {
      try {
        if (data[`email-${isEdit}`] && data[`firstName-${isEdit}`] && nurseList?.find((nurse: any) => nurse?.id === isEdit)?.email === data[`email-${isEdit}`] && nurseList?.find((nurse: any) => nurse?.id === isEdit)?.firstName === data[`firstName-${isEdit}`]) {
          setIsEdit(-1);
          return;
        }
        editNurseMutation.mutate({
          id: isEdit,
          data: {
            email: data[`email-${isEdit}`],
            firstName: data[`firstName-${isEdit}`],
          },
        }, { onSuccess }
        );
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    } else if (data?.email && data?.firstName) {
      try {
        nurseMutation.mutate({
          email: data?.email,
          firstName: data?.firstName,
        }, { onSuccess });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  }

  const handleOutsideClick = useCallback(
    (event: MouseEvent | React.MouseEvent<HTMLElement, MouseEvent>, clickedOut: boolean) => {
      if (clickedOut) {
        setIsNurseForm(false);
      }
    },
    [values],
  );

  useOutsideClick(containerRef, handleOutsideClick);

  const handleIsEdit = (item: any) => {
    if (!isSiteStaff) {
      return 0;
    }
    setIsEdit(item?.id);
    methods.setValue(`firstName-${item?.id}`, item?.firstName);
    methods.setValue(`email-${item?.id}`, item?.email);
  };

  useEffect(() => {
    methods.setValue('phoneNumber', patientData?.userProfile?.userAccount?.phoneNumber);
    methods.setValue('address', patientData?.userProfile?.address);
  }, [patientData]);

  const handleIsEditContact = () => {
    if (!isSiteStaff) {
      return 0;
    }
    setIsEditContact(patientData?.id);
    methods.setValue('phoneNumber', patientData?.userProfile?.userAccount?.phoneNumber);
    methods.setValue('address', patientData?.userProfile?.address);
  };

  return (
    <div ref={containerRef} className='border border-purple bg-orange flex py-3 rounded-lg'>
      <FormProvider {...methods}>
        <form className='flex w-full'>
          <div className="border-r border-gray-light w-[85%] max-xl:w-[77%] pr-8">
            <InfoBar />
            <div className="flex px-10">
              <div className="text-start w-max mr-16">
                <p className="font-medium text-blue-oil text-sm mb-1.5">
                  <FormattedMessage id='patient-profile.attendingPhysician' />
                </p>
                <p className="font-normal text-blue-oil mb-1">Dr. {patientData?.studyInviter?.user?.firstName}</p>
                <p className="font-normal text-blue-ocean-deep text-xs uppercase mb-0.5">
                  <FormattedMessage id='email' />
                </p>
                <p className="font-normal text-blue-oil text-xs">{patientData?.studyInviter?.user?.userAccount?.email}</p>
              </div>
              {nurseList && nurseList?.length > 0 && nurseList?.map((nurse: any, i: number) => (
                <div key={nurse?.id} className="text-start w-max mr-16 ml-4">
                  <p className="font-medium text-blue-oil text-sm mb-1.5">
                    <FormattedMessage id="patient-profile.nurse" />{' '}{i + 1}
                  </p>
                  {isEdit === nurse?.id ? (
                    <Input
                      name={`firstName-${isEdit}`}
                      register={methods.register}
                      inputClassName="h-[1.3rem] !w-full !p-0 !rounded placeholder:text-sm placeholder:!text-gray-light !border-none"
                      className="!mt-0"
                      onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                          e.target.blur(e.target.value);
                        }
                      }}
                      onBlur={(e) => onSubmit?.({ ...values, firstName: e.target.value })}
                      placeholder={intl.formatMessage({ id: 'patient.name' })}
                    />
                  ) : (
                    <p
                      onDoubleClick={() => handleIsEdit(nurse)}
                      className="font-normal text-blue-oil mb-1">
                      {nurse?.firstName}
                    </p>
                  )}
                  <p className="font-normal text-blue-ocean-deep text-xs uppercase mb-0.5">
                    <FormattedMessage id='email' />
                  </p>
                  {isEdit === nurse?.id ? (
                    <Input
                      name={`email-${isEdit}`}
                      register={methods.register}
                      inputClassName="h-[1.3rem] !w-full text-xs !p-0 !rounded placeholder:text-sm placeholder:!text-gray-light !border-none"
                      className="!mt-0"
                      onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                          e.target.blur(e.target.value);
                        }
                      }}
                      onBlur={(e) => onSubmit?.({ ...values, email: e.target.value })}
                      placeholder={intl.formatMessage({ id: 'patient-profile.emailPlaceholder' })}
                    />
                  ) : (
                    <p
                      onDoubleClick={() => handleIsEdit(nurse)}
                      className="font-normal text-blue-oil text-xs">
                      {nurse?.email}
                    </p>
                  )}
                </div>
              ))}
              {isSiteStaff && (!nurseList || (nurseList && nurseList?.length < 3)) && (
                <div className="text-start w-max mr-16">
                  <p onClick={() => setIsNurseForm(true)} className="font-medium text-blue-oil text-sm mb-2 cursor-pointer">
                    <FormattedMessage id='patient-profile.addNurse' />
                  </p>
                  {isNurseForm && (<div>
                    <Input
                      name='firstName'
                      register={methods.register}
                      inputClassName="h-[1.3rem] !w-full !p-0 !rounded placeholder:text-sm placeholder:!text-gray-light !border-none"
                      className="!mt-0"
                      onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                          e.target.blur(e.target.value);
                        }
                      }}
                      onBlur={(e) => onSubmit?.({ ...values, firstName: e.target.value })}
                      placeholder={intl.formatMessage({ id: 'patient.name' })}
                    />
                    <p className="font-normal text-blue-ocean-deep text-xs uppercase mt-1">
                      <FormattedMessage id='email' />
                    </p>
                    <Input
                      name='email'
                      register={methods.register}
                      inputClassName="h-[1.3rem] !w-full text-xs !p-0 !rounded placeholder:text-sm placeholder:!text-gray-light !border-none"
                      className="!mt-0"
                      onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                          e.target.blur(e.target.value);
                        }
                      }}
                      onBlur={(e) => onSubmit?.({ ...values, email: e.target.value })}
                      placeholder={intl.formatMessage({ id: 'patient-profile.emailPlaceholder' })}
                    />
                  </div>)}
                </div>
              )}
            </div>
          </div>
          <div className="pl-8 text-left pr-3">
            <p className="font-medium text-left text-black text-sm mb-5 mt-3">
              <FormattedMessage id='patient-profile.contactInformation' />
            </p>
            <div className="text-start w-max">
              <p className="font-normal text-blue-ocean-deep text-xs uppercase mb-0.5">
                <FormattedMessage id='email' />
              </p>
              <p className="font-normal text-blue-oil text-sm mb-3">{isSiteStaff ? patientData?.userProfile?.userAccount?.email : '*****'}</p>
              <p className="font-normal text-blue-ocean-deep text-xs uppercase mb-0.5">
                <FormattedMessage id='users.mobile' />
              </p>
              {isEditContact === -1 && patientData?.userProfile?.userAccount?.phoneNumber ? (
                <p
                  onDoubleClick={handleIsEditContact}
                  className="font-normal text-blue-oil text-sm mb-3">
                  {isSiteStaff ? patientData?.userProfile?.userAccount?.phoneNumber : '*****'}
                </p>
              ) : (
                <>
                  <Input
                    name='phoneNumber'
                    type='number'
                    register={methods.register}
                    inputClassName="h-[1.3rem] [&::-webkit-inner-spin-button]:appearance-none !w-full mb-3 !p-0 text-sm !rounded placeholder:text-sm placeholder:!text-gray-light !border-none"
                    className="!mt-0"
                    disabled={!isSiteStaff}
                    defaultValue={patientData?.userProfile?.userAccount?.phoneNumber}
                    onKeyDown={(e: any) => {
                      if (e.key === 'Enter') {
                        e.target.blur(e.target.value);
                      }
                    }}
                    onBlur={(e) => onSubmit?.({ ...values, phoneNumber: e.target.value })}
                    placeholder={isSiteStaff ? intl.formatMessage({ id: 'site.phoneNumber' }) : ''}
                  />
                </>
              )}
              <p className="font-normal text-blue-ocean-deep text-xs uppercase mb-0.5">
                <FormattedMessage id='site.address' />
              </p>
              {isEditContact === -1 && patientData?.userProfile?.address ? (
                <p
                  onDoubleClick={handleIsEditContact}
                  className="font-normal text-blue-oil text-sm truncate max-w-[9rem]">
                  {isSiteStaff ? patientData?.userProfile?.address : '*****'}
                </p>
              ) : (
                <Input
                  name='address'
                  register={methods.register}
                  inputClassName="h-[1.3rem] !w-full text-sm !p-0 !rounded placeholder:text-sm placeholder:!text-gray-light !border-none"
                  className="!mt-0"
                  disabled={!isSiteStaff}
                  defaultValue={patientData?.userProfile?.address}
                  onKeyDown={(e: any) => {
                    if (e.key === 'Enter') {
                      e.target.blur(e.target.value);
                    }
                  }}
                  onBlur={(e) => onSubmit?.({ ...values, address: e.target.value })}
                  placeholder={isSiteStaff ? intl.formatMessage({ id: 'site.address' }) : ''}
                />
              )}
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default InfoCard;
