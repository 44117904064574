import React, { useContext, useEffect, useState } from 'react';
import {
  Calendar,
  CalendarBody,
  CalendarHeader,
  useCalendar,
} from 'react-hook-calendar';
import { FiChevronLeft, FiChevronRight, FiCalendar, FiPlus, FiSidebar } from 'react-icons/fi';
import NewMeetingModal from './components/NewMeetingModal';
import { CalendarGrid } from './components/NewCalendarGrid';
import { useQuery } from '@tanstack/react-query';
import { IAppointment, fetchAppointments } from 'services/api/appointment';
import { useStudyStore } from 'store/StudyStore';
import { useParams } from 'react-router-dom';
import Appointment from './components/Appointment';
import { addHours, differenceInMinutes, isAfter, isBefore, isSameDay, startOfDay } from 'date-fns';
import DatePick from './components/DatePick';
import { LanguageContext } from 'pages/App/LanguageContext';
import { FormattedMessage } from 'react-intl';
import usePermissions from 'hooks/usePermissions';

function TodayButton() {
  const { setDate } = useCalendar();

  return (
    <div className='flex items-center gap-2'>
      <FiSidebar className='rotate-90 text-blue' />
      <button className='rounded-md text-blue-ocean-deep' onClick={() => setDate(new Date())}>
        <FormattedMessage id="appointment.today" />
      </button>
    </div>
  );
}

function TimeLegend() {
  const { viewTimes } = useCalendar();
  const numHours = (viewTimes.end - viewTimes.start) / (60 * 60 * 1000);
  const startHour = viewTimes.start / (60 * 60 * 1000);

  return (
    <div className={`grid w-full h-full grid-rows-[repeat(${numHours}, 1fr)]`}>
      {[...Array(numHours)].map((_, index) => (
        <div
          key={index + startHour}
          className={`row-span-[${index * 1 + 1}] flex justify-center align-top text-sm text-blue-ocean-deep p-1 border border-gray-200 h-20`}
        >
          {index + startHour}
        </div>
      ))}
    </div>
  );
}

const PageControl = () => {
  const { goBackward, goForward } = useCalendar();
  // Displaying the view period
  return (
    <div className='flex items-center justify-between gap-[5px]'>
      <div className='flex gap-[18px]'>
        <FiChevronLeft className='text-[1.5rem] text-blue-ocean-deep cursor-pointer' onClick={goBackward} />
        <FiChevronRight className='text-[1.5rem] text-blue-ocean-deep cursor-pointer' onClick={goForward} />
      </div>
    </div>
  );
}

const AgendaCalendar: React.FC = () => {
  const { dlocale } = useContext(LanguageContext);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>({});
  const [appointmentList, setAppointmentList] = useState<IAppointment[]>([]);
  const { loggedInStudyUser } = useStudyStore();
  const { participantId, studyId } = useParams();
  const [currentTime, setCurrentTime] = useState(new Date());
  const { hasNotAccess, isFreezeStudy } = usePermissions();

  const isSiteStaff = loggedInStudyUser?.role?.category === 'SITE_STAFF' && loggedInStudyUser?.role?.permissions?.length > 0 && !hasNotAccess('TELEVISIT') && !isFreezeStudy();

  useEffect(() => {
    // Update current time every minute
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);

    return () => clearInterval(interval);
  }, []); 
  
  const calculateCurrentPosition = () => {
    const calendarStartTime = new Date();
    calendarStartTime.setHours(8, 0, 0, 0); // Set the start time to 8:00 AM
    const diffInMinutes = differenceInMinutes(currentTime.getTime(), calendarStartTime.getTime())
    const totalMinutes = 720; // Total number of minutes between 8:00 AM and 8:00 PM
    const currentPosition = (diffInMinutes / totalMinutes) * 100;

    return currentPosition;
  };

  const currentPosition = calculateCurrentPosition();

  const { data, refetch } = useQuery({
    queryKey: ['appointmments'],
    queryFn: () => participantId && loggedInStudyUser && fetchAppointments(parseInt(`${participantId}`, 10), parseInt(`${loggedInStudyUser?.id}`)),
  });

  useEffect(() => {
    if (participantId && loggedInStudyUser) {
      refetch();
    }
  }, [loggedInStudyUser, participantId, studyId])

  const getColor = (status: string) => {
    switch (status) {
      case 'PENDING':
        return 'yellow-warning';
      case 'TENTATIVE':
        return 'yellow-warning';
      case 'CONFIRMED':
        return 'blue-ocean-deep';
      case 'DONE':
        return 'green-done';
      case 'DECLINED':
        return 'red-decline';
      default:
        return 'yellow-warning';
    }
  }

  useEffect(() => {
    if (data?.length > 0) {
      setAppointmentList(data?.map((apoint: IAppointment) => ({
        id: apoint?.id,
        appointment: apoint?.appointment,
        centerUser: apoint?.centerUser,
        title: apoint?.appointment?.title,
        color: getColor(apoint?.appointment?.appointmentStatus),
        start: apoint?.centerUserTimeslot?.beginDate,
        end: apoint?.centerUserTimeslot?.endDate,
        name: `${apoint?.appointment?.patient?.userProfile?.firstName} ${apoint?.appointment?.patient?.userProfile?.lastName || ''}`
      })))
    }
  }, [data])

  const handleMeeting = (e: any) => {
    if (isSiteStaff) {
      const { date } = e
      setModalData({
        date
      });
      setOpenModal(true);
    }
  }

  const startTime = addHours(startOfDay(new Date()), 8); 
  const endTime = addHours(startOfDay(new Date()), 19);
  const isBetweenStartAndEnd = isAfter(currentTime, startTime) && isBefore(currentTime, endTime);

  return (
    <div>
      {openModal && <NewMeetingModal modalData={modalData} onClose={() => {refetch(); setOpenModal(false)}} />}
      <Calendar defaultView='week' weekStartsOn={1} timeStart="8:00" timeEnd="20:00" customDays={4}>
        <div className='w-full pl-[2.875rem] pt-[1.688rem] pb-0 pr-6'>
          <div className='flex justify-between items-center w-full gap-4'>
            <div className='flex items-center gap-3 w-fit'>
              <div className='w-8 h-8 bg-blue-ocean-deep rounded p-1'>
                <FiCalendar className='text-white w-6 h-6' />
              </div>
              <p className='text-blue text-lg leading-6 font-medium'>
                <FormattedMessage id="appointment.calendar" />
              </p>
            </div>
            {isSiteStaff && (
              <button onClick={() => setOpenModal(true)} className='text-sm leading-5 font-medium flex items-center gap-2 px-2.5 h-8 bg-blue-ocean-deep text-white rounded-full hover:bg-[#16518a] transition-all'>
                <FiPlus className='w-4 h-4' />
                <FormattedMessage id="appointment.newMeeting" />
              </button>
            )}
          </div>
          <div className='flex justify-between items-center w-full gap-4 mt-4 '>
            <div className='flex'>
              <TodayButton />
              <PageControl />
            </div>
            <DatePick />
          </div>
        </div>
        <div className='flex mr-6 border-gray-100 border-r-2'>
          <div className='shrink-0 w-[50px] border-r border-gray-200' />
          <CalendarHeader className='w-full'>
            {({ date }) => (
              <div className={`${isSameDay(new Date(date), new Date()) ?  'text-blue-normal border-b-2 border-b-blue-normal' : 'text-blue border-gray-200'} border-t border-l text-left pl-[0.563rem] pt-[0.438rem] `}>
                <div className='text-2xl leading-[2.125rem] font-serif'>{date.getDate()}</div>
                <div className='text-xs leading-[1.125rem]'>
                  {Intl.DateTimeFormat(dlocale, { weekday: 'long' }).format(date)}
                </div>
              </div>
            )}
          </CalendarHeader>
        </div>
        <div className='flex grow h-full flex-row'>
          <div className='shrink-0 w-[50px] h-full'>
            <TimeLegend />
          </div>
          <CalendarBody className='h-full relative grow w-full mr-6 border-gray-100 border-r-2'>
            <CalendarGrid onClick={(e: any) => handleMeeting(e)} length='30 min' className='child border-gray-100 border-t border-l h-10 ' />
            {appointmentList.map((appointment: any) => (
              <Appointment isSiteStaff={isSiteStaff} setOpenModal={setOpenModal} setModalData={setModalData} key={appointment.id} {...appointment} />
            ))}
            {isBetweenStartAndEnd && <div className="red-line" style={{ top: `${currentPosition}%` }} />}
          </CalendarBody>
        </div>
      </Calendar>
    </div>
  );
}

export default AgendaCalendar;