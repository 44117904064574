import React, { useEffect, useState } from 'react';
import clsx from 'classnames';
import { IQuestionnaireType } from 'types/questionnaire';
import { useNavigate, useParams } from 'react-router-dom';
import { IStepStatus, IStepWithStatus } from 'types/step';
import { useQuestionnaireStore } from 'store/QuestionnaireStore';
import { FiAlertCircle } from 'react-icons/fi';
import { IQueryQuestion, useQueryQuestionStore } from 'store/queryQuestionStore';

interface Props extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'type' | 'id'> {
  title: string;
  checkResolved?: any;
  type: IQuestionnaireType;
  selectedQuestionnaire: number | string;
  status: IStepStatus;
  setSelectedQuestionnaire: React.Dispatch<React.SetStateAction<number | string>>;
  id: number | string;
  option: IStepWithStatus;
  isQueries?: boolean;
}

function QuestionnaireButton({
  title,
  type,
  id,
  selectedQuestionnaire,
  setSelectedQuestionnaire,
  option,
  isQueries,
}: Props) {
  const { setStepsData, setOpenConsentForm } = useQuestionnaireStore();
  const { queryQuestions } = useQueryQuestionStore();
  const [isResolved, setIsResolved] = useState(true);
  const { questionnaireId } = useParams() as {
    questionnaireId: string | undefined;
  };

  const navigate = useNavigate();
  function handleClick() {
    setOpenConsentForm(false);
    if (Number(questionnaireId?.split('-')?.[1]) === id) return;
    setStepsData(option);
    setSelectedQuestionnaire(id);
    const routeId = type === 'ID_VERIFICATION' ? `i-${id}` : `q-${id}`;
    navigate(routeId);
  }

  const checkQuestionaire = (questnaire: any) => {
    if (questnaire?.id === id) {
      setIsResolved(true);
      queryQuestions?.map((item: IQueryQuestion) => {
        const foundq = questnaire?.questions.find(
          (quest: any) => parseInt(`${quest.id}`, 10) === item?.baseQuestionId,
        );
        if (foundq && foundq?.id && !item.resolved) {
          setIsResolved(item.resolved);
        }
      });
    }
  };

  const checkResolved = (option: any) => {
    if (option?.folder && queryQuestions?.length > 0) {
      const foundFolder =
        option?.folder?.folders &&
        option?.folder?.folders?.length > 0 &&
        option?.folder?.folders?.find(
          (fld: any) => fld?.folder?.parentFolderId === option?.folder?.id,
        );
      if (foundFolder) {
        foundFolder?.folder?.questionnaires &&
          foundFolder?.folder?.questionnaires?.length > 0 &&
          foundFolder?.folder?.questionnaires?.map((qs: any) => {
            checkQuestionaire(qs);
          });
      }
      option?.folder?.questionnaires &&
        option?.folder?.questionnaires?.length > 0 &&
        option?.folder?.questionnaires?.map((qs: any) => {
          checkQuestionaire(qs);
        });
    } else {
      checkQuestionaire(option?.questionnaire);
    }
  };

  useEffect(() => {
    checkResolved(option);
  }, [option, queryQuestions]);

  return (
    <div
      className='flex justify-center bg-base-white rounded-lg relative items-center'
      data-type='questionnaire-button'
      data-id={id}
    >
      <button
        id={String(id)}
        type='button'
        className={clsx(
          'p-2 pl-4 rounded-lg text-blue-ocean-deep transition-all items-center justify-between w-full z-10 gap-3 group flex font-semibold',
          !questionnaireId?.includes('sf') && `${selectedQuestionnaire}` === `${id}`
            ? 'border-[1px] border-purple'
            : 'hover:border border-purple',
        )}
        key={title}
        onClick={handleClick}
      >
        <p className='text-sm font-semibold text-start truncate w-[170px]'>{title}</p>
        {isQueries && !isResolved && (
          <FiAlertCircle className='w-3 h-3 text-error cursor-pointer' />
        )}
      </button>
    </div>
  );
}

export default QuestionnaireButton;
