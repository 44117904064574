import { useCallback, useRef, useState, memo, useEffect } from 'react';
import clsx from 'classnames';
import { FiChevronRight } from 'react-icons/fi';
import { FieldErrors, FieldValues, UseControllerProps, useController } from 'react-hook-form';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { CustomControl } from 'components/common/Select/CustomControl';
import { Tooltip } from '../../common';

interface Option {
  label: string;
  value: string | number;
}

interface IProps extends UseControllerProps<any> {
  placeholder?: string;
  options: Option[];
  customControl?: CustomControl;
  label?: string;
  disabled?: boolean;
  onBlur?: (e: any) => void;
  className?: string;
  buttonClassName?: string;
  optionsClassName?: string;
  errors?: FieldErrors<FieldValues>;
  mainClass?: string;
  selectOptClass?: string;
  noErrorMessage?: boolean;
}

function StatusSelect({
  placeholder,
  options,
  customControl,
  label,
  disabled,
  onBlur,
  buttonClassName,
  errors,
  className,
  mainClass,
  selectOptClass,
  optionsClassName,
  noErrorMessage = false,
  ...rest
}: IProps) {
  const props = customControl ? customControl : useController(rest).field;
  const errorMsg = errors?.[rest.name]?.message as string | undefined;
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLLabelElement>(null);
  const [selectedOption, setSelectedOption] = useState(props.value);

  useEffect(() => {
    if (props.value) {
      setSelectedOption(props.value);
    }
  }, [props.value]);

  function toggleOpen() {
    setIsOpen((prevValue) => !prevValue);
  }

  const handleSelectOption = useCallback(
    (optionValue: string | number) => {
      setSelectedOption(optionValue);
      props.onChange(optionValue as string);
      onBlur?.(optionValue);
      toggleOpen();
    },
    [setSelectedOption],
  );

  const handleOutsideClick = useCallback(
    (event: MouseEvent | React.MouseEvent<HTMLElement, MouseEvent>, clickedOut: boolean) => {
      if (clickedOut && event.target !== labelRef.current) {
        setIsOpen(false);
      }
    },
    [setIsOpen],
  );

  useOutsideClick(containerRef, handleOutsideClick);

  const getLabel = () => {
    return options.find((option) => `${selectedOption}` === `${option.value}`)?.label;
  };

  return (
    <div
      className={`max-w-[8rem] min-w-[8rem] w-max bg-transparent rounded-full [&>div]:border-none ${className}`}
    >
      {label && (
        <label
          htmlFor={`button-${rest.name}`}
          ref={labelRef}
          className='text-left w-full text-blue-ocean-deep mb-2 inline-block leading-6 font-semibold'
        >
          {label}
        </label>
      )}
      <div
        ref={containerRef}
        className={clsx(
          isOpen ? 'border border-b-transparent rounded-b-none' : 'border',
          `rounded-[0.625rem] flex items-center relative max-h-[3rem] text-blue-ocean-deep max-w-[8rem] ${errors?.[rest.name]?.message ? 'border-danger-100 border-2' : 'border-blue-ocean-deep'}`,
          mainClass,
        )}
      >
         <Tooltip position='right' arrowClass='top-[6px]' className='w-max' text={getLabel()}>
          <button
            type='button'
            disabled={disabled}
            id={`button-${rest.name}`}
            onClick={toggleOpen}
            className={clsx(
              `flex flex-1 items-center rounded-[0.625rem] text-white ${
                disabled ? 'cursor-not-allowed' : 'cursor-pointer'
              }`,
              buttonClassName,
            )}
          >
            <span
              className={clsx(
                'text-base truncate flex-1 text-start max-w-[6rem]',
                !selectedOption && 'text-gray-medium',
                selectOptClass,
              )}
            >
              {selectedOption ? getLabel() : placeholder}
            </span>
            <FiChevronRight className={clsx('transition-all text-2xl ', isOpen && ' rotate-90')} />
          </button>
         </Tooltip>
        <div
          className={clsx(
            'absolute flex z-10 top-[26px] box-content max-h-40 no-scrollbar border-white bg-blue-ocean-deep text-white overflow-hidden w-full origin-top -left-px right-0 bottom-0',
            isOpen ? 'min-h-fit border overflow-y-auto' : 'h-0 overflow-hidden',
          )}
        >
          <ul className='w-full'>
            {options.map((option) => (
              <Tooltip key={option?.value} position='right' arrowClass='top-[6px]' className='w-max' text={option.label}>
                <li
                  className={clsx(
                    'hover:text-blue-white-ice h-[2.5rem] hover:bg-blue-oil truncate transition-colors px-3 py-2 cursor-pointer text-left',
                    optionsClassName,
                  )}

                  onClick={() => handleSelectOption(option.value)}
                  data-value={option?.value}
                >
                  <p className='truncate'>{option.label}</p>
                </li>
              </Tooltip>
            ))}
          </ul>
        </div>
      </div>
      {!noErrorMessage && errorMsg ? <div className='text-error text-left'>{errorMsg}</div> : ''}
    </div>
  );
}

export default memo(StatusSelect);
