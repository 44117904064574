import ScrollContainer from 'react-indiana-drag-scroll';
import {
  Chart as ChartJS,
  Tooltip,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Filler,
  PointElement,
  type ChartOptions,
  type ChartData,
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import { useCallback, useEffect, useRef, useState } from 'react';
import { customPlugins, hideScales } from './constants';
import { getHeartData, getStepsData } from './util';
import { ChartCard } from './ChartCard';
import { useParticipantStore } from 'store/participantStore';
import { calculateAge } from 'utils/number';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuestionnaireStore } from 'store/QuestionnaireStore';
import { IStepEproList } from 'types/questionnaire';
import { format } from 'date-fns';
import { useStudyStore } from '../../../../store/StudyStore';
import { useCentersStore } from '../../../../store/centers';

ChartJS.register(Tooltip, CategoryScale, LinearScale, PointElement, BarElement, LineElement, Filler);

export default function ParticipantDashboard() {
  const intl = useIntl();
  const cardContainerRef = useRef<HTMLDivElement>(null);
  const [cardContainerHeight, setCardContainerHeight] = useState<number | 'auto'>('auto');
  const { participantData } = useParticipantStore();
  const { stepEproList } = useQuestionnaireStore();
  const { loggedInStudyUser } = useStudyStore();
  const { centers } = useCentersStore();

  const realStepData =
    participantData?.numberOfStep?.length > 0 ? getStepsData(participantData?.numberOfStep.slice(participantData?.numberOfStep?.length - 7, participantData?.numberOfStep?.length)) : [];

  const stepData: ChartData<'bar'> = {
    labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
    datasets: [
      {
        label: intl.formatMessage({ id: 'step.steps' }),
        data: realStepData,
        backgroundColor: '#0973D4',
        borderRadius: 10,
        borderSkipped: false,
        barThickness: 22,
      },
    ],
  };

  const stepOptions: ChartOptions<'bar'> = {
    responsive: true,
    plugins: {
      ...customPlugins,
      tooltip: {
        ...customPlugins.tooltip,
        callbacks: {
          title(tooltipItem: any) {
            return tooltipItem[0].label;
          },
          label: function (tooltipItem: any) {
            return tooltipItem.formattedValue;
          },
          footer: function () {
            return intl.formatMessage({ id: 'step.steps' });
          },
        },
      } as any,
    },
    scales: {
      y: {
        ...hideScales.y,
        title: {
          display: true,
          text: intl.formatMessage({ id: 'step.steps' }),
        },
        beginAtZero: false,
        ticks: {
          padding: 10,
          display: true,
          autoSkip: false,
          count: 6,
          color: '#939393',
          font: {
            weight: 700,
          },
        },
        suggestedMin: realStepData?.length > 0 ? Math.floor(Math.min(...realStepData) / 2) : 0,
        suggestedMax:realStepData?.length > 0 ? Math.max(...realStepData) : 50,
      },
      x: {
        ...hideScales.x,
        display: true,
        ticks: {
          padding: 10,
          display: true,
          autoSkip: false,
          color: '#939393',
          font: {
            weight: 700,
          },
        }
      }
    },
  };

  const realHeartData =
    participantData?.heartRate?.length > 0 ? getHeartData(participantData?.heartRate) : [];

  const getQuestionaireData = (eproRepeatation: any) => {
    const questData = eproRepeatation?.length > 0 ? eproRepeatation?.map((ep: any) => ep?.score) : [];
    const labelsX = eproRepeatation?.length > 0 ? eproRepeatation?.map((ep: any) => [format(new Date(ep?.date), 'EEE'), format(new Date(ep?.date), 'dd')]) : [];

    return {
      labels: labelsX,
      datasets: [
        {
          type: 'line' as const,
          label: 'Dataset 1',
          borderColor: '#004381',
          borderWidth: 2,
          fill: true,
          data: questData,
        },
        {
          label: intl.formatMessage({ id: 'patient-profile.score' }),
          data: questData,
          backgroundColor: '#004381',
          borderRadius: 30,
          borderSkipped: true,
          barThickness: 22,
        },
      ],
    }
  }

  const getQuestionaireOptions = (eproRepeatation: any) => {
    const labelsY: any = eproRepeatation?.length > 0 ? eproRepeatation?.map((ep: any) => ep?.score) : [];
    // const labelsX = eproRepeatation?.length > 0 ? eproRepeatation?.map((ep: any) => [format(new Date(ep?.date), 'EEE'), format(new Date(ep?.date), 'dd')]) : [];

    const questionOptions: ChartOptions<'bar'> = {
      responsive: true,
      plugins: {
        ...customPlugins,
        tooltip: {
          ...customPlugins.tooltip,
          callbacks: {
            title(tooltipItem: any) {
              return tooltipItem[0].label;
            },
            label: function (tooltipItem: any) {
              return tooltipItem.formattedValue;
            },
          },
        } as any,
      },
      scales: {
        y: {
          beginAtZero: true,
          type: 'linear',
          title: {
            display: true,
            text: intl.formatMessage({ id: 'patient-profile.score' }),
          },
          ticks: {
            padding: 10,
            display: true,
            autoSkip: false,
            count: 4,
            color: '#939393',
            font: {
              weight: 700,
            },
          },
          suggestedMin: labelsY?.length > 0 ? Math.min(...labelsY) : 0,
          suggestedMax:labelsY?.length > 0 ? Math.max(...labelsY) + 5 : 50,
        },
        x: {
          ...hideScales.x,
          display: true,
          ticks: {
            padding: 10,
            maxTicksLimit: 7,
            display: true,
            autoSkip: false,
            color: '#939393',
            font: {
              weight: 700,
            },
          }
        },
      },
    };
    return questionOptions;
  }

  const heartData: ChartData<'line'> = {
    labels: participantData?.heartRate?.length > 0 ? participantData?.heartRate?.map((ep: any) => [format(new Date(ep?.date), 'EEE'), format(new Date(ep?.date), 'dd')]) :
      [['Mon', '10'], ['Tue', '10'], ['Wed', '10'], ['Thu', '10'], ['Fri', '10'], ['Sat', '10']],

    datasets: [
      {
        label: intl.formatMessage({ id: 'patient-profile.bpm' }),
        data: realHeartData,
        borderWidth: 1,
        fill:'start',
        borderJoinStyle: 'round',
        pointRadius: 0,
        backgroundColor: (context: any) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 200);
          gradient.addColorStop(0, 'rgba(173, 183, 249, 1)');
          gradient.addColorStop(1, 'rgba(177, 185, 248, 0)');
          return gradient;
        },
        borderColor: '#0973D4',
      },
    ],
  };

  const heartOptions: ChartOptions<'line'> = {
    responsive: true,
    interaction: {
      intersect: false,
    },
    plugins: {
      ...customPlugins,
      tooltip: {
        ...customPlugins.tooltip,
        callbacks: {
          ...customPlugins.tooltip.callbacks,
          title(tooltipItem: any) {
            return `${intl.formatMessage({ id: 'patient-profile.tick' })} ${tooltipItem[0].label}`;
          },
          footer() {
            return intl.formatMessage({ id: 'patient-profile.bpm' });
          },
        },
      } as any,
    },
    scales: {
      ...hideScales,
      y: {
        ...hideScales.y,
        beginAtZero: false,
        title: {
          display: true,
          text: intl.formatMessage({ id: 'patient-profile.beatsPerMin' }),
        },
        ticks: {
          display: true,
          autoSkip: false,
          padding: 10,
          count: 6,
          color: '#939393',
          font: {
            weight: 700,
          },
        },
        suggestedMin: realHeartData?.length > 0 ? Math.min(...realHeartData) : 0,
        suggestedMax: realHeartData?.length > 0 ? Math.max(...realHeartData) + 5 : 50,
      },
      x: {
        position: 'top',
        border:{
          display:false
        },
        ticks: {
          maxTicksLimit: 7,
          padding: 10,
          color: '#939393',
          font: {
            weight: 700,
          },
        },
      },
    },
  };


  const getCardContainerHeight = useCallback(() => {
    if (cardContainerRef.current?.parentElement) {
      const current = cardContainerRef.current;
      const parentElement = cardContainerRef.current.parentElement;
      let currentHeight = parentElement.clientHeight;
      const currentPadding = parseInt(getComputedStyle(current).padding.split('p')[0]);
      const currentMargin = parseInt(getComputedStyle(current).marginTop.split('p')[0]);
      const currentGap = parseInt(getComputedStyle(current).gap.split('p')[0]);

      currentHeight -= currentPadding * 2 + currentMargin + currentGap / 2;

      if (cardContainerRef.current.previousElementSibling) {
        const siblingElement = cardContainerRef.current.previousElementSibling;
        currentHeight -= siblingElement.clientHeight;
        currentHeight -= parseInt(getComputedStyle(siblingElement).marginBottom.split('p')[0]);
      }

      setCardContainerHeight(currentHeight);
    }
  }, [cardContainerRef.current]);

  useEffect(() => {
    getCardContainerHeight();
  }, [cardContainerRef]);

  const isSiteStaff = loggedInStudyUser?.role?.category === 'SITE_STAFF';
  const orderIndex = participantData?.id < 10 ? `00${participantData?.id}` : `0${participantData?.id}`;
  const foundCenter = centers?.find((cn: any) => cn.id === participantData?.centerId);

  return (
    <section className='w-full ml-9 mx-4'>
      <header className='flex gap-10 text-blue-oil items-center mt-7'>
        <h1 className='leading-[2.125rem] font-sans text-3xl container mx-auto text-left'>
          <FormattedMessage id="patient-status.progress" />
        </h1>
      </header>
      <ScrollContainer
        innerRef={cardContainerRef}
        nativeMobileScroll
        hideScrollbars={false}
        style={{
          minHeight: cardContainerHeight,
        }}
        className='bg-white border w-full border-purple rounded-lg py-6 relative mt-4 no-scrollbar scroll-sm overflow-y-auto gap-6 container justify-between'
      >
        <div className="flex items-center bg-blue-onahau w-[70%] px-10 py-4 mb-6 gap-10 text-blue-ocean-deep">
          <p className="text-xl font-semibold text-blue-ocean-deep">{isSiteStaff ? participantData?.userProfile?.firstName : `${orderIndex}${foundCenter?.address?.country?.countryIsoCode}${participantData.centerId}`}</p>
          <div>
            <p className='font-inter uppercase text-sm text-blue-ocean-deep'><FormattedMessage id='patient-profile.age' /></p>
            <p className='font-inter text-sm text-blue-ocean-deep'>{participantData?.dateOfBirth && calculateAge(participantData?.dateOfBirth)} <FormattedMessage id='patient-profile.yrs' /></p>
          </div>
          <div>
            <p className='font-inter uppercase text-sm text-blue-ocean-deep'><FormattedMessage id='patient-profile.height' /></p>
            <p className='font-inter uppercase text-sm text-blue-ocean-deep'>{participantData?.height} <FormattedMessage id='patient-profile.cm' /></p>
          </div>
          <div>
            <p className='font-inter uppercase text-sm text-blue-ocean-deep'><FormattedMessage id='patient-profile.weight' /></p>
            <p className='font-inter uppercase text-sm text-blue-ocean-deep'>{participantData?.weight} <FormattedMessage id='patient-profile.kg' /></p>
          </div>
          <div>
            <p className='font-inter uppercase text-sm text-blue-ocean-deep'><FormattedMessage id='patient-profile.gender' /></p>
            <p className='font-inter uppercase text-sm text-blue-ocean-deep'>{participantData?.gender || ''}</p>
          </div>
          <div>
            <p className='font-inter uppercase text-sm text-blue-ocean-deep'><FormattedMessage id='patient-profile.bmi' /></p>
            <p className='font-inter uppercase text-sm text-blue-ocean-deep'>{(participantData?.weight / participantData?.height ** 2).toFixed(1) || 0.0}</p>
          </div>
          <div>
            <p className='font-inter uppercase text-sm text-blue-ocean-deep'><FormattedMessage id='patient-profile.bloodType' /></p>
            <p className='font-inter uppercase text-sm text-blue-ocean-deep'>{participantData?.bloodType || ''}</p>
          </div>
        </div>
        <div className='flex flex-wrap gap-10 px-6'>
          <ChartCard
            Chart={Bar}
            chartData={stepData}
            chartOptions={stepOptions}
            title={intl.formatMessage({ id: 'step.steps' })}
            footerTitle={''}
            footerDesc={''}
            footerDate=''
            className={{ card: '!bg-green-very-light' }}
          />
          <ChartCard
            Chart={Line}
            chartData={heartData}
            chartOptions={heartOptions}
            title={intl.formatMessage({ id: 'patient-profile.heartRate' })}
            footerTitle={''}
            footerDesc={''}
            footerDate=''
            className={{ card: '!bg-orange' }}
          />
          {stepEproList && stepEproList?.length > 0 && stepEproList?.map((stp: IStepEproList, i: number) => {
            return (
              <div key={stp?.questionnaireId}>
                {stp?.eproRepeatation && stp?.eproRepeatation?.length > 0 && <ChartCard
                  Chart={Bar}
                  key={stp?.questionnaireId}
                  chartData={getQuestionaireData(stp?.eproRepeatation)}
                  chartOptions={getQuestionaireOptions(stp?.eproRepeatation)}
                  title={stp?.questionnaire?.title || ''}
                  footerTitle={''}
                  footerDesc={''}
                  footerDate=''
                  className={{ card: i % 2 === 0 ? '!bg-green-very-light' : '!bg-orange' }}
                />}
              </div>
            )
          })}
        </div>
      </ScrollContainer>
    </section>
  );
}
