import { UseFormRegister, FieldValues } from 'react-hook-form';
import { FieldErrors } from '../../../types/form';
import { RadioIcon } from 'components/shared/QuestionnaireField/Icons';
import clsx from 'classnames';

interface IOption {
  label: string;
  value: string;
}
interface IProps {
  label?: string;
  name?: string;
  options: IOption[];
  wrapperClassName?: string;
  className?: string;
  optionsClassName?: string;
  radioClassName?: string;
  labelClassName?: string;
  selectedValue?: string;
  error?: string;
  handleBlur?: (value: any) => void;
  control?: {
    onChange: (value: any) => void;
    value: any;
  };
  placeholder?: string;
  // defaultValue?: string;
  disabled?: boolean;
  register?: UseFormRegister<any>;
  errors?: FieldErrors<FieldValues>;
  noErrorMessage?: boolean;
  methods?: any;
  noOnchangeCall?: any;
  defaultValue?: any;
  onFocus?: any;
}
export const Radio = ({
  name = '',
  control,
  disabled,
  handleBlur,
  label,
  className,
  methods,
  options,
  wrapperClassName,
  optionsClassName,
  radioClassName,
  labelClassName,
  noErrorMessage,
  register,
  selectedValue,
  errors,
  // defaultValue,
  noOnchangeCall = false,
  onFocus,
}: IProps) => {
  const errorMsg = errors?.[name]?.message as string | undefined;
  // const checkProps = control ? defaultValue : methods?.getValues(name);
  const checkProps = methods?.getValues(name) ?? selectedValue;
  const handleChange = (value: any) => {
    
    if (!noOnchangeCall) {
      control?.onChange(checkProps === value ? '' : value);
      handleBlur?.(value);
    }
  };
  
  return (
    <div className={clsx('flex flex-col', wrapperClassName)}>
      <div className='flex justify-start flex-col items-start '>
        {label && (
          <label
            data-testid='radioinput-label'
            htmlFor={name}
            className='pl-1 block text-blue-oil mb-2 font-medium leading-6'
          >
            {label}
          </label>
        )}
        <section className={`flex gap-x-4 justify-start items-center flex-1 ${optionsClassName}`}>
          {options &&
            options.map(({ label, value }) => {
              return (
                <div
                  key={value}
                  onClick={() => (disabled ? null : handleChange(value))}
                  className={clsx(
                    'rounded-[12px] border-ocean-deep border-2 py-[9px] px-[17px] flex items-center gap-1 gap-x-3',
                    disabled ? 'cursor-not-allowed' : 'cursor-pointer',
                    // value && 'bg-pink-light',
                    className,
                  )}
                >
                  <input
                    {...(control ? { ...control } : register ? { ...register(name) } : {})}
                    onChange={() => control?.onChange(checkProps === value ? '' : value)}
                    value={value}
                    disabled={disabled}
                    onFocus={onFocus}
                    checked={checkProps === value}
                    type='radio'
                    className={clsx(
                      'w-6 h-6 accent-blue-ocean-deep !text-danger bg-ocean-deep focus:!ring-danger border-ocean-deep text-ocean-deep',
                      disabled ? 'cursor-not-allowed' : 'cursor-pointer',
                      radioClassName,
                    )}
                  />
                  {selectedValue === value && checkProps === value && <RadioIcon />}
                  <span onClick={() => control?.onChange(checkProps === value ? '' : value)} className={clsx('text-base text-dark', labelClassName)}>{label}</span>
                </div>
              );
            })}
        </section>
      </div>
      {!noErrorMessage && errorMsg ? <div className='text-left text-error'>{errorMsg}</div> : ''}
    </div>
  );
};
export default Radio;
