import React, { useState, useEffect, useRef } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';

interface ScaleInputProps extends UseControllerProps<any> {
  start: number;
  end: number;
  increment: number;
  name: string;
  mainClass?: string;
  inputClass?: string;
  numberClass?: string;
  legendClass?: {
    start?: string;
    end?: string;
  };
  customControl?: any;
  onChange: (value: number) => void;
  onBlur?: (value: any) => void;
  onFocus?: () => void;
  startLegend?: any;
  endLegend?: any;
  islegendSide?: any;
}

const ScaleInput: React.FC<ScaleInputProps> = ({
  start,
  end,
  increment,
  onChange,
  name,
  control,
  disabled,
  onBlur,
  mainClass,
  inputClass,
  numberClass,
  legendClass,
  onFocus,
  startLegend,
  endLegend,
  islegendSide,
}) => {
  const [value, setValue] = useState(start);
  const [percentage, setPercentage] = useState(0);
  const rangeRef = useRef<HTMLInputElement>(null);
  const props = useController({ control, name, disabled })?.field;

  useEffect(() => {
    setPercentage(((value - start) / (end - start)) * 100);
  }, [value]);
  const diff = startLegend && endLegend ? end - start : -1;

  useEffect(() => {
    props?.value ? setValue(props?.value) : setValue(start);
  }, [props?.value]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (+event.target.value < -10 || +event.target.value > 10) return;
    const newValue = Number(event.target.value);
    setValue(newValue);
    onChange(newValue);
  };

  const divisions = [];
  if (diff > 10) {
    const increment = diff / 10; // Calculate the increment for 11 segments
    for (let i = start; i <= end; i += increment) {
      divisions.push(Math.round(i)); // Round to avoid floating-point issues
    }
  } else {
    for (let i = start; i <= end; i += increment) {
      divisions.push(i);
    }
  }

  return (
    <div className={`${islegendSide ? 'w-[410px] !pl-0' : 'w-[181px] pl-4'} ${mainClass}`}>
      <div className='flex items-baseline	gap-x-2'>
        {startLegend && islegendSide && (
          <p
            className={`max-w-[64px] truncate w-auto text-[10.86px] text-blue-oil ${legendClass?.start}`}
          >
            {startLegend}
          </p>
        )}
        <div className='relative mb-4 mt-7 scaleSlider'>
          {!islegendSide && startLegend && (
            <p
              className={`absolute max-w-[59px] truncate w-auto text-[10px] left-0 -top-[8px] text-blue-oil ${legendClass?.start}`}
            >
              {startLegend}
            </p>
          )}
          <input
            ref={rangeRef}
            type='range'
            name={name}
            min={start}
            max={end}
            disabled={disabled}
            step={increment}
            value={value}
            onFocus={onFocus}
            onChange={handleInputChange}
            onMouseUp={(e: any) => {
              onBlur?.(e.target.value);
            }}
            style={{
              background: `linear-gradient(to right, #004381 ${percentage}%, rgb(205,217,230) ${percentage}%)`,
            }}
            className={`${islegendSide ? 'w-[258px]' : 'w-[181px]'} ${inputClass} appearance-none !accent-base-white bg-transparent h-1.5 rounded-full ${disabled ? 'cursor-not-allowed ' : 'cursor-pointer'}`}
          />
          {!islegendSide && endLegend && (
            <p
              className={`absolute max-w-[59px] truncate w-auto text-[10px] right-0 -top-[8px] text-blue-oil ${legendClass?.end}`}
            >
              {endLegend}
            </p>
          )}
          <div
            className='percent absolute bg-blue-ocean-deep text-white text-xs rounded py-1 px-2.5'
            style={{
              left: `calc(${percentage}% - 12px)`,
              top: '-24px',
              whiteSpace: 'nowrap',
            }}
          >
            {value}
            <span
              className='absolute block w-0 h-0 border-l-4 border-r-4 border-t-4 border-transparent border-t-blue-ocean-deep'
              style={{ top: '100%', left: '50%', transform: 'translateX(-50%)' }}
            />
          </div>
          <div
            className={`absolute top-0 left-0 ${islegendSide ? 'w-[258px]' : 'w-[181px]'} ${numberClass} h-1 flex justify-between px-1.5`}
          >
            {divisions.map((division, index) => (
              <div key={index} className='relative flex items-center justify-center'>
                <p className='absolute border-[0.905px] border-blue-oil h-1 bg-blue-oil rounded-full block top-6 !important'></p>
                <span className='division absolute top-7 text-[9px] text-blue-oil'>{division}</span>
              </div>
            ))}
          </div>
        </div>
        {islegendSide && endLegend && (
          <p
            className={`max-w-[64px] truncate w-auto text-[10.86px] text-blue-oil ${legendClass?.end}`}
          >
            {endLegend}
          </p>
        )}
      </div>
    </div>
  );
};

export default ScaleInput;
