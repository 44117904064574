import { memo, useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'classnames';
import { FiCheck, FiChevronDown } from 'react-icons/fi';
import { FieldErrors, FieldValues, useController, UseControllerProps } from 'react-hook-form';
import { CustomControl } from './CustomControl';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { useIntl } from 'react-intl';
import Tooltip from '../Tooltip';

interface Option {
  label: string;
  value: string | number;
}

interface IProps extends UseControllerProps<any> {
  placeholder?: string;
  options: Option[];
  customControl?: CustomControl;
  label?: string;
  disabled?: boolean;
  isTooltip?: boolean;
  onBlur?: (e: any) => void;
  className?: string;
  buttonClassName?: string;
  iconClass?: string;
  style?: any;
  optionsClassName?: string;
  errors?: FieldErrors<FieldValues>;
  mainClass?: string;
  selectOptClass?: string;
  noErrorMessage?: boolean;
  optWrapperClassName?: string;
  noTranslation?: boolean;
  isCheckIcon?: boolean;
  onFocus?: any;
  isSearchable?: any;
}

function Select({
  placeholder,
  options,
  isTooltip,
  customControl,
  label,
  disabled,
  style,
  onBlur,
  buttonClassName,
  errors,
  className,
  iconClass,
  mainClass,
  selectOptClass,
  optionsClassName,
  noErrorMessage = false,
  optWrapperClassName,
  noTranslation,
  onFocus,
  isCheckIcon,
  isSearchable,
  ...rest
}: IProps) {
  const intl = useIntl();
  const props = customControl ? customControl : useController(rest)?.field;
  const errorMsg = errors?.[rest.name]?.message as string | undefined;
  const [isOpen, setIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLLabelElement>(null);
  const [selectedOption, setSelectedOption] = useState(props?.value);
  const [filteredOpts, setFilteredOpts] = useState<any>();
  const [searchQuery, setSearchQuery] = useState(''); // Search input state

  useEffect(() => {
    setSelectedOption(props?.value);
  }, [props?.value]);

  function toggleOpen() {
    setIsOpen((prevValue) => !prevValue);
  }

  useEffect(() => {
    if (selectedOption && isCheckIcon && searchQuery === '') {
      const newOpts = options?.filter(
        (opt: any) => opt?.value && !selectedOption?.includes(opt?.value),
      );
      const selectedOpts = options?.find(
        (opt: any) => opt?.value && selectedOption?.includes(opt?.value),
      );
      setFilteredOpts([selectedOpts, ...newOpts]);
    } else if (options && options?.length > 0 && searchQuery === '') {
      setFilteredOpts(options);
    }
  }, [options, selectedOption, searchQuery]);

  useEffect(() => {
    searchQuery &&
      setFilteredOpts(
        options.filter((option) => option.label.toLowerCase().includes(searchQuery.toLowerCase())),
      );
  }, [searchQuery, options]);

  const handleSelectOption = useCallback((option: any) => {
      let newValue: any =  option?.value;
      if (props?.value && props?.value === newValue) {
        newValue = null;
      }
      setSearchQuery(newValue === null ? '' : noTranslation ? option?.label : intl.formatMessage({ id: `${option?.label}` }));
      setSelectedOption(newValue);
      props.onChange(newValue as string);
      onBlur?.(newValue);
      onFocus?.();
      toggleOpen();
    },
    [setSelectedOption, props.value],
  );

  const getLabel = () => {
    return options?.find((option) => option?.value && `${selectedOption}` === `${option?.value}`)
      ?.label;
  };

  const handleOutsideClick = useCallback(
    (event: MouseEvent | React.MouseEvent<HTMLElement, MouseEvent>, clickedOut: boolean) => {
      if (clickedOut && event.target !== labelRef.current) {
        setIsOpen(false);
      }
    },
    [setIsOpen],
  );

  useOutsideClick(containerRef, handleOutsideClick);

  const getValue = () => {
    return searchQuery
      ? searchQuery
      : !isEdit && !isOpen && selectedOption
        ? noTranslation
          ? getLabel()
          : intl.formatMessage({ id: `${getLabel()}` })
        : ''
  }

  const renderBtn = () => {
    return (
      <button
        type='button'
        disabled={disabled}
        id={`button-${rest.name}`}
        onClick={() => {
          toggleOpen();
          !isOpen && setSearchQuery('');
        }}
        className={clsx(
          `flex w-full flex-1 gap-x-4 outline-none focus:outline-none ring-0 items-center p-3 rounded-[0.625rem] ${
            disabled ? 'cursor-not-allowed' : 'cursor-pointer'
          }`,
          buttonClassName,
        )}
      >
        {isSearchable ? (
          <input
            type='text'
            value={getValue()}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              e.target.value === '' && setIsEdit(true);
              setIsOpen(true);
            }}
            disabled={disabled}
            placeholder={placeholder}
            className={`w-full !bg-white !text-blue-ocean-deep text-base truncate flex-1 !text-left text-ellipsis focus:outline-none focus:ring-0 focus:border-none border-none outline-none ${selectOptClass}`}
          />
        ) : (
          <p
            className={clsx(
              'text-base truncate flex-1 !text-left w-[50%] text-ellipsis',
              (!selectedOption || selectedOption === ' ') && '!text-gray-very-light',
              selectOptClass,
            )}
          >
            {selectedOption === ' '
              ? placeholder
              : selectedOption
                ? noTranslation
                  ? getLabel()
                  : intl.formatMessage({ id: `${getLabel()}` })
                : placeholder}
          </p>
        )}
        <FiChevronDown
          className={clsx('transition-all ', iconClass ? iconClass : isOpen && ' -rotate-180')}
        />
      </button>
    );
  };

  return (
    <div className={className}>
      {label && (
        <label
          htmlFor={`button-${rest.name}`}
          ref={labelRef}
          className='text-left w-full text-blue-ocean-deep mb-2 inline-block leading-6 font-semibold'
        >
          {label}
        </label>
      )}
      <div
        ref={containerRef}
        style={style}
        className={clsx(
          isOpen ? 'border border-b-transparent rounded-b-none' : 'border',
          `rounded-[0.625rem] flex items-center relative max-h-[3rem] text-blue-ocean-deep ${errors?.[rest.name]?.message ? 'border-danger-100 border-2' : 'border-blue-ocean-deep'}`,
          mainClass,
          isOpen && 'open',
        )}
      >
        {isTooltip ? (
          <Tooltip
            position='right'
            arrowClass='top-[6px]'
            className='w-max'
            text={isTooltip ? getLabel() : ''}
          >
            {renderBtn()}
          </Tooltip>
        ) : (
          renderBtn()
        )}
        <div
          className={clsx(
            'absolute flex z-100 box-content max-h-40 no-scrollbar bg-base-white overflow-hidden rounded-b-xl origin-top top-full -left-[2px] right-0 bottom-0 !w-[99.9%]',
            isOpen ? 'min-h-fit border overflow-y-auto' : 'hidden',
            optWrapperClassName,
          )}
        >
          <ul className='w-full'>
            {filteredOpts &&
              filteredOpts?.length > 0 &&
              filteredOpts?.map((option: any, index: number) => (
                <li
                  className={clsx(
                    'hover:bg-blue-white-ice flex items-center break-normal transition-colors px-3 py-2 cursor-pointer text-left',
                    optionsClassName,
                    isCheckIcon && option && option?.value === selectedOption
                      ? 'font-semibold'
                      : 'font-normal',
                  )}
                  key={`${option?.value}-${index}`}
                  onClick={() => handleSelectOption(option)}
                  data-value={option?.value}
                >
                  {isCheckIcon && option?.value === selectedOption && (
                    <FiCheck className='font-bold mr-1 stroke-[4px] !h-4 !w-4 !min-w-4 !min-h-4' />
                  )}
                  {noTranslation ? option?.label : intl.formatMessage({ id: `${option?.label}` })}
                </li>
              ))}
          </ul>
        </div>
      </div>
      {!noErrorMessage && errorMsg ? <div className='text-error text-left'>{errorMsg}</div> : ''}
    </div>
  );
}

export default memo(Select);
