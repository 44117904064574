import clsx from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FiCheck, FiChevronDown } from 'react-icons/fi';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';
import ReactDOM from 'react-dom';
const DropdownOptions = ({
   optWrapperClassName,
   filteredOpts,
   optionsClassName,
   handleSelectOption,
   isOpen,
   isSelected,
   dropdownPosition
 }: any) => {
  const dropdownRoot: any = document.getElementById('dropdown-root'); // Portal target

  return (
    ReactDOM.createPortal(
      <div
        className={clsx(
          'dropdown-portal absolute mt-0.5 flex z-100 box-content max-h-40 no-scrollbar bg-base-white overflow-auto rounded-b-xl min-w-[236px]',
          isOpen ? 'min-h-auto border overflow-y-auto' : 'hidden',
          optWrapperClassName,
        )}
        style={{
          position: 'fixed', // Fixed positioning for dropdown
          top: `${dropdownPosition.top}px`,
          left: `${dropdownPosition.left}px`,
          width: `${dropdownPosition.width}px`,
          zIndex: 10, // Ensure z-index is high enough to be clickable
        }}
      >
        <ul className="dropdown-portal-ul w-full !p-0">
          {filteredOpts && filteredOpts?.length > 0 && filteredOpts.map((option: any, index: number) => (
            <li
              className={clsx(
                'dropdown-portal-li hover:bg-blue-white-ice text-blue-ocean-deep flex items-center break-normal transition-colors px-2 py-2 cursor-pointer text-left',
                optionsClassName,
                isSelected(option.value) ? 'font-semibold' : 'font-normal',
              )}
              key={`${option?.value}-${index}`}
              onClick={() => handleSelectOption(option.value)}
              data-value={option?.value}
            >
              {isSelected(option.value) &&
                <FiCheck className="font-bold text-blue-ocean-deep mr-1 stroke-[4px] !h-4 !w-4 !min-w-4 !min-h-4" />}
              {option.label}
            </li>
          ))}
        </ul>
      </div>,
      dropdownRoot
    )
  )
}
const QuestionMultiSelect = ({
  control,
  methods,
  optWrapperClassName,
  optionsClassName,
  options,
  onBlur,
  placeholder,
  disabled,
  renderValue,
  mainClass,
  buttonClassName,
  selectOptClass,
  iconClass,
}: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOption] = useState<any>(control?.value);
  const [filteredOpts, setFilteredOpts] = useState<any>();
  const [searchQuery, setSearchQuery] = useState(''); // Search input state
  const containerRef = useRef<HTMLDivElement>(null);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0, width: 0 }); // Dropdown position

  // Recalculate dropdown position
  const calculateDropdownPosition = () => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      setDropdownPosition({
        top: rect.bottom + window.scrollY, // Adjust for vertical scroll
        left: rect.left + window.scrollX,  // Adjust for horizontal scroll
        width: rect.width,
      });
    }
  };

  useEffect(() => {
    calculateDropdownPosition();
  }, [isOpen]);

  // Recalculate the position on scroll and resize
  useEffect(() => {
    const handleScrollOrResize = (e: any) => {
      if (e?.target?.classList && !e?.target?.classList[0]?.includes('dropdown-portal') && isOpen) {
        setIsOpen(false);
      }
    };

    window.addEventListener('scroll', handleScrollOrResize, isOpen);
    window.addEventListener('resize', handleScrollOrResize, isOpen);

    return () => {
      window.removeEventListener('scroll', handleScrollOrResize, isOpen);
      window.removeEventListener('resize', handleScrollOrResize, isOpen);
    };
  }, [isOpen]);

  function toggleOpen() {
    setIsOpen((prevValue) => !prevValue);
  }

  const handleSelectOption = (optionValue: any) => {
    let newData = [];
    const foundOpt = selectedOptions?.length > 0 && selectedOptions?.find((opt: any) => opt === optionValue);
    if (foundOpt) {
      newData = selectedOptions?.filter((opt: any) => opt !== optionValue);
    } else {
      newData = selectedOptions && selectedOptions?.length > 0 ? [...selectedOptions, optionValue] : [optionValue];
    }
    setSelectedOption(newData);
    control.onChange(newData);
    toggleOpen();
  };

  const isSelected = (option: any) => {
    return selectedOptions && selectedOptions?.length > 0 && selectedOptions?.find((opt: any) => opt === option);
  };

  const handleOutsideClick = useCallback(
    (event: any | React.MouseEvent<HTMLElement, MouseEvent>, clickedOut: boolean) => {
      if (clickedOut) {
        if (event?.target?.classList && !event?.target?.classList[0]?.includes('dropdown-portal')) {
          setIsOpen(false);
          onBlur?.(methods?.watch());
        }
      }
    },
    [setIsOpen, methods, onBlur],
  );

  useEffect(() => {
    searchQuery && setFilteredOpts(
      options.filter((option: any) =>
        option.label.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery, options]);

  useEffect(() => {
    if (selectedOptions && selectedOptions?.length > 0 && searchQuery === '') {
      const newOpts = options?.filter((opt: any) => !selectedOptions?.includes(opt.value));
      const selectedOpts = options?.filter((opt: any) => selectedOptions?.includes(opt.value));
      setFilteredOpts([ ...selectedOpts, ...newOpts ]);
    } else if (options && options?.length > 0 && searchQuery === '') {
      setFilteredOpts(options);
    }
  }, [options, selectedOptions, searchQuery]);

  useEffect(() => {
    (!control?.value || (control?.value && control?.value?.length === 0)) && setSelectedOption([]);
  }, [control?.value]);

  useOutsideClick(containerRef, handleOutsideClick);

  return (
    <div className={`relative ${mainClass}`} ref={containerRef}>
      <button
        type="button"
        disabled={disabled}
        onClick={toggleOpen}
        className={clsx(
          `flex gap-x-4 outline-none focus:outline-none ring-0 items-center bg-white border border-blue-light-200 w-[120px] max-w-[120px] h-[28px] rounded px-2 pt-0.5 ${
            disabled ? 'cursor-not-allowed' : 'cursor-pointer'
          }`,
          buttonClassName,
        )}
      >
        <input
          type="text"
          value={selectedOptions && selectedOptions?.length > 0 ? renderValue?.(selectedOptions) ? renderValue(selectedOptions) : selectedOptions?.map((val: any, i: number) => (`${val}${i === selectedOptions?.length ? '' : ', '}`)) : searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
            setIsOpen(true);
          }}
          disabled={disabled}
          placeholder={placeholder}
          className={`w-full !text-blue-ocean-deep text-base truncate flex-1 !text-left text-ellipsis focus:outline-none focus:ring-0 focus:border-none border-none outline-none ${selectOptClass}`}
        />
        <FiChevronDown
          className={clsx('transition-all ', iconClass ? iconClass : isOpen && ' -rotate-180')}
        />
      </button>
      <DropdownOptions
        optWrapperClassName={optWrapperClassName}
        filteredOpts={filteredOpts}
        optionsClassName={optionsClassName}
        handleSelectOption={handleSelectOption}
        isOpen={isOpen}
        isSelected={isSelected}
        dropdownPosition={dropdownPosition}
      />
    </div>
  )
}

export default QuestionMultiSelect;