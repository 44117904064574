import { Calendar, CheckBoxList, Radio, Select } from 'components/common';
import FileUploadWithProgressBar from 'components/common/FileUploadWithProgressBar';
import RatingStar from 'components/common/RatingStar';
import Input from 'components/common/TextInput';
import { useEffect, useLayoutEffect, useState } from 'react';
import clsx from 'classnames';
import ScaleInput from './ScaleInput';
import usePermissions from '../../../../../hooks/usePermissions';
import PreRepeatedQuestion from './PreRepeatedQuestions';
import { useIntl } from 'react-intl';
import { addAsterisk } from '../../../../../utils/string';

interface IQuestionOptions {
  id: number;
  label: string;
  value: string;
}

interface QuestionProps {
  title: string;
  id?: number;
  questionType?: string;
  uploadLoading?: boolean;
  questionOptions?: any;
  inputPlaceHolder?: any;
  description?: any;
  methods?: any;
  answer: string;
  dateTime?: string;
  checkType?: () => void;
  onBlur?: (value: any) => void;
  questionairetype?: string;
  dateTimeType?: string;
  styleType?: string;
  stars?: string;
  subtitle?: string;
  preview?: boolean;
  startValue?: any;
  endValue?: any;
  increment?: any;
  parentQuestionId?: any;
  titleQuestionId?: any;
  isLastQuestion?: any;
  previewMobileStyle?: boolean;
  mobileView?: boolean;
  required?: boolean;
  questionNumber?: number;
  repeatedQuestions?: any;
  isSubLastQuestion?: boolean;
  numberType?: 'DECIMAL' | 'INTEGER';
  isQuestionEmpty?: boolean;
  startLegend?: any;
  endLegend?: any;
}

const PickerTypeEnum: any = {
  DATE: 'date',
  TIME: 'time',
  DATE_AND_TIME: 'datetime',
  YEAR: 'year',
  YEAR_AND_MONTH: 'yearmonth',
};

const Question = ({
  id,
  methods,
  title,
  answer,
  uploadLoading,
  checkType,
  questionType = '',
  questionOptions,
  questionairetype,
  inputPlaceHolder = '',
  dateTimeType,
  onBlur,
  stars,
  preview,
  startValue,
  endValue,
  increment,
  styleType,
  parentQuestionId,
  titleQuestionId,
  isLastQuestion,
  previewMobileStyle,
  questionNumber,
  subtitle,
  mobileView,
  repeatedQuestions,
  isSubLastQuestion,
  required,
  numberType,
  isQuestionEmpty,
  startLegend,
  endLegend,
}: QuestionProps & { mobileView?: boolean }) => {
  const intl = useIntl();
  const { hasNotAccess } = usePermissions();
  const isViewPatient = !hasNotAccess('VIEW_PATIENTS');
  const [selectedTable, setSelectedTable] = useState(null);
  const [selectedRadioBtns, setSelectedRadioBtns] = useState(undefined);
  const extendedName = `${id}`

  const getCheckboxAns = (answer: string) => {
    const idList = answer.replace('[', '').replace(']', '').split(',');
    const valueList = questionOptions?.reduce((newValue: any, ques: any) => {
      if (idList?.find((ids) => `${ids}` === `${ques.id}`)) {
        newValue.push(ques.value);
      }
      return newValue;
    }, []);
    return valueList || [];
  };

  const getAnsValue = (answer: string) => {
    const ans = answer.replace('[', '').replace(']', '');
    return questionOptions?.find((qs: any) => `${qs.id}` === `${ans}`)?.value || '';
  };

  useLayoutEffect(() => {
    if (answer) {
      setSelectedRadioBtns(methods.getValues(`radioBtns${extendedName}`));
    }
  }, [answer, getAnsValue]);

  useEffect(() => {
    switch (questionType) {
      case 'SCALE':
        methods.setValue(`scale${extendedName}`, answer);
        break;
      case 'TEXT_INPUT':
        methods.setValue(`textInput${extendedName}`, answer);
        break;
      case 'NUMBER_VALUE':
        methods.setValue(`numberTxt${extendedName}`, answer);
        break;
      case 'CHECKBOX':
        methods.setValue(`checkList${extendedName}`, getCheckboxAns(`${answer}`));
        break;
      case 'DROPDOWN':
        methods.setValue(`selectField${extendedName}`, getAnsValue(`${answer}`));
        break;
      case 'DATE_TIME':
        answer &&
          answer !== '' &&
          `${new Date(answer)}` !== 'Invalid Date' &&
          methods.setValue(`dateTime${extendedName}`, new Date(answer)?.toISOString() || null);
        break;
      case 'RADIO_BUTTONS':
        methods.setValue(`radioBtns${extendedName}`, getAnsValue(`${answer}`));
        break;
      case 'RATING':
        methods.setValue(`rating${extendedName}`, answer);
        break;
      case 'FILE_UPLOAD':
        methods.setValue(`fileUpload${extendedName}`, answer);
        break;
      default:
        null;
    }
  }, [answer, questionType]);

  const renderAnswer = () => {
    const values = methods?.watch();
    switch (questionType) {
      case 'SCALE':
        return (
          <div className={previewMobileStyle ? '-rotate-90 h-full mt-[100px]' : ''}>
            <ScaleInput
              name={`scale${extendedName}`}
              control={methods.control}
              start={startValue}
              end={endValue}
              startLegend={startLegend}
              endLegend={endLegend}
              increment={increment}
              onChange={() => {}}
              mainClass={previewMobileStyle ? '[&>div>.percent]:rotate-90' : ''}
              numberClass={previewMobileStyle ? '[&>div>.division]:rotate-90' : ''}
            />{' '}
          </div>
        );
      case 'TEXT_INPUT':
        return (
          <div
            className={clsx(
              'h-12 flex w-[27rem] items-center  ',
              preview && 'w-full',
              previewMobileStyle ? 'pl-0' : 'pl-4',
            )}
          >
            <Input
              name={`textInput${extendedName}`}
              placeholder={inputPlaceHolder}
              register={methods?.register}
              onBlur={onBlur}
              control={{
                value: values[`textInput${extendedName}`],
                onChange: (e: any) => {
                  methods?.setValue(`textInput${extendedName}`, e.target.value);
                },
              }}
              onKeyDown={(e: any) => {
                if (e.key === 'Enter') {
                  if (!preview) e.target.blur(e.target.value);
                }
              }}
              className={clsx('!mt-0 w-full ')}
              inputClassName={clsx(
                'h-[28px] !border-blue-normal-35 !rounded-[4px]',
              )}
              readOnly={preview || checkType?.() ? false : true}
            />
          </div>
        );
      case 'NUMBER_VALUE':
        return (
          <div
            className={clsx(
              'h-12 flex w-[5rem] items-center  ',
              preview && 'w-full',
              previewMobileStyle ? '' : 'pl-4',
            )}
          >
            <Input
              name={`numberTxt${extendedName}`}
              placeholder={inputPlaceHolder}
              register={methods?.register}
              onBlur={onBlur}
              type='number'
              control={{
                value: values[`numberTxt${extendedName}`],
                onChange: (e: any) => {
                  methods?.setValue(`numberTxt${extendedName}`, e.target.value);
                },
              }}
              onKeyDown={(e: any) => {
                if (e.key === 'e' || e.key === '-' || e.key === '+' || e.key === '.' || e.key === ',') {
                  if (numberType === 'DECIMAL' && e.key === '.') return;
                  e.preventDefault();
                }
                if (e.key === 'Enter') {
                  e.target.blur(e.target.value);
                }
              }}
              className='!mt-0 w-full'
              inputClassName={clsx(
                'h-[28px] !border-blue-normal-35 !rounded-[4px] text-right !p-0 !pr-4 hide-arrow',
              )}
              readOnly={checkType?.() || preview ? false : true}
            />
          </div>
        );
      case 'DROPDOWN':
        return (
          <div
            className={clsx(
              'h-12 items-center',
              preview && 'w-full',
              values &&
                values[`selectField${extendedName}`] &&
                values[`selectField${extendedName}`]?.trim().toLowerCase() === 'other'
                ? 'mt-0 mb-4 block'
                : 'flex',
            )}
          >
            <Select
              control={methods?.control}
              label=''
              isSearchable={true}
              onBlur={() => {
                methods?.setValue(`otherDropdown-${id}`, '')
              }}
              optionsClassName='!text-blue-oil !text-sm !py-[6px]'
              optWrapperClassName='!border !border-blue-normal-35 !rounded-b-[4px]'
              className={clsx(
                'shadow-none !p-0 min-w-[120px] ',
                preview && 'w-full',
                previewMobileStyle ? '' : '!pl-4',
              )}
              mainClass={clsx(
                '[&>button]:p-0 [&>button>p]:!w-[88px] py-1 px-2 [&>div]:w-full rounded-[4px] !max-h-[28px] !border-blue-normal-35 !bg-white [&>div>ul]:!pl-0',
              )}
              buttonClassName={clsx('pl-4', preview && '!py-1')}
              noTranslation={true}
              isCheckIcon={true}
              options={questionOptions.map((item: IQuestionOptions) => ({
                label: item.label,
                value: item.value,
              }))}
              placeholder={intl.formatMessage({ id: 'questionnaire-field.select' })}
              selectOptClass={values[`selectField${extendedName}`] ? '' : '!text-gray-very-light'}
              name={`selectField${extendedName}`}
            />
            {values &&
              values[`selectField${extendedName}`] &&
              typeof values[`selectField${extendedName}`] === 'string' &&
              values[`selectField${extendedName}`]?.trim().toLowerCase() === 'other' && (
                <div className={clsx('h-12 flex items-center w-full', previewMobileStyle ? '' : 'pl-4')}>
                  <Input
                    name={`otherDropdown-${extendedName}`}
                    placeholder={intl.formatMessage({ id: 'questionnaire-field.pleaseSpecify' })}
                    register={methods?.register}
                    control={{
                      value: values[`otherDropdown-${extendedName}`],
                      onChange: (e: any) => {
                        methods?.setValue(`otherDropdown-${extendedName}`, e.target.value);
                      },
                    }}
                    onKeyDown={(e: any) => {
                      if (e.key === 'Enter') {
                        if (!preview) e.target.blur(e.target.value);
                      }
                    }}
                    className={clsx( '!mt-3 w-full' )}
                    inputClassName={clsx(
                      'h-[28px] !border-blue-normal-35 !rounded-[4px]',
                    )}
                    readOnly={preview || checkType?.() ? false : true}
                  />
                </div>
              )}
          </div>
        );
      case 'CHECKBOX':
        if (preview && questionOptions?.length === 0)
          questionOptions = [{ id: 26424, label: '', score: null, value: 'check' }];
        return (
          <div className='h-max w-full'>
            <CheckBoxList
              register={methods.register}
              name={`checkList${extendedName}`}
              className={clsx(
                `!gap-x-0 w-full pl-4 ${styleType === 'vertical' ? '!block' : 'flex !flex-wrap !max-w-[464px] !justify-start'}`,
                mobileView && '!grid !grid-cols-1 !pl-0',
              )}
              options={questionOptions}
              handleBlur={(e) => {
                e && e?.length > 0 && e[e?.length - 1] === 'OTHER' && methods?.setValue(`otherCheck-${id}`, '')
              }}
              optionsClassName={
                previewMobileStyle
                  ? '!border-2 border-[rgb(140,169,196)] !py-1.5 pl-2 pr-4 mt-3 !rounded-md'
                  : '!border-none !py-1.5 !pl-0 pr-4 !items-start !gap-x-[0.5rem] [&>svg]:m-[2px]'
              }
              disabled={checkType?.() || preview || previewMobileStyle ? false : true}
              checkboxClassName={clsx(
                '!w-4 !h-4 !rounded',
                previewMobileStyle && '!border-blue-ocean-deep !rounded-[4px]',
              )}
              labelClassName={clsx(
                `text-base-black ${styleType === 'vertical' ? 'max-w-[40rem] min-w-[200px] !w-full' : 'text-wrap min-w-[80px] !w-auto -mt-[4px]'}`,
                previewMobileStyle && 'grow !max-w-[170px]',
              )}
              selectedOptionClassName={previewMobileStyle ? 'bg-[rgb(255,243,243)]' : ''}
              methods={methods}
            />
            {values &&
              values[`checkList${extendedName}`] &&
              values[`checkList${extendedName}`]?.length > 0 &&
              values[`checkList${extendedName}`]?.find(
                (val: any) => val && val?.trim().toLowerCase() === 'other',
              ) && (
                <div className={clsx('flex items-center w-full', previewMobileStyle ? '' : 'pl-4')}>
                  <Input
                    name={`otherCheck-${extendedName}`}
                    placeholder={intl.formatMessage({ id: 'questionnaire-field.pleaseSpecify' })}
                    register={methods?.register}
                    control={{
                      value: values[`otherCheck-${extendedName}`],
                      onChange: (e: any) => {
                        methods?.setValue(`otherCheck-${extendedName}`, e.target.value);
                      },
                    }}
                    onKeyDown={(e: any) => {
                      if (e.key === 'Enter') {
                        if (!preview) e.target.blur(e.target.value);
                      }
                    }}
                    className={clsx('!mt-2 w-full')}
                    inputClassName={clsx(
                      'h-[28px] !border-blue-normal-35 !rounded-[4px]',
                    )}
                    readOnly={preview || checkType?.() ? false : true}
                  />
                </div>
              )}
          </div>
        );
      case 'DATE_TIME':
        return (
          <div className='flex items-center gap-5 h-12 pl-1'>
            <Calendar
              control={methods.control}
              name={`dateTime${extendedName}`}
              label=''
              className='!border-none no-border-mui [&>div>div>input]:!text-left'
              placeholder='Select a date'
              onBlur={!preview ? onBlur : () => {}}
              inputDisable={true}
              disabled={checkType?.() || preview ? false : true}
              type={(dateTimeType && PickerTypeEnum[dateTimeType]) || 'date'}
            />
          </div>
        );
      case 'RADIO_BUTTONS':
        return (
          <div className={`${previewMobileStyle ? clsx('h-12 items-center mt-1', preview && 'w-full') : 'h-max relative w-full pl-4'}`}>
            {previewMobileStyle ? (
              <Select
                control={methods?.control}
                label=''
                isSearchable={true}
                onBlur={() => {
                  methods?.setValue(`otherRadio-${id}`, '')
                }}
                optWrapperClassName='border border-blue-ocean-deep border-t-gray-light !rounded-b-[6px]'
                className={clsx(
                  'shadow-none !p-0 min-w-[120px] ',
                  preview && 'w-full',
                  previewMobileStyle ? '' : '!pl-4',
                )}
                mainClass='rounded-[6px]'
                buttonClassName={clsx('pl-4', preview && '!py-1 !px-2')}
                noTranslation={true}
                options={questionOptions.map((item: IQuestionOptions) => ({
                  label: item.label,
                  value: item.value,
                }))}
                placeholder={intl.formatMessage({ id: 'questionnaire-field.select' })}
                name={`radioBtns${extendedName}`}
              />
            ) : (
              <Radio
                register={methods.register}
                name={`radioBtns${extendedName}`}
                options={questionOptions}
                handleBlur={(e: any) => {
                  methods?.setValue(`otherRadio-${id}`, '')
                  onBlur?.(e);
                  if (preview) setSelectedRadioBtns(e);
                }}
                optionsClassName={`w-full !gap-x-0 ${styleType === 'vertical' ? ' !block !my-1' : 'flex !flex-wrap !max-w-[464px] !justify-start'}`}
                disabled={checkType?.() || preview ? false : true}
                labelClassName={`text-base-black ${styleType === 'vertical' ? 'max-w-[40rem] min-w-[200px] !w-full' : 'text-wrap min-w-[80px] !w-auto -mt-[4px]'}`}
                className='border-none !py-2 !pl-0 !items-start ml-[0rem] !gap-x-[0.5rem]'
                radioClassName='!w-4 !h-4 !min-w-4 !min-h-4 gap-x-2'
                selectedValue={selectedRadioBtns}
                methods={methods}
              />
            )}
            {values &&
              values[`radioBtns${extendedName}`] &&
              typeof values[`radioBtns${extendedName}`] === 'string' &&
              values[`radioBtns${extendedName}`]?.trim().toLowerCase() === 'other' && (
                <div className={clsx('flex items-center w-full')}>
                  <Input
                    name={`otherRadio-${extendedName}`}
                    placeholder={intl.formatMessage({
                      id: 'questionnaire-field.pleaseSpecify',
                    })}
                    control={{
                      value: values[`otherRadio-${extendedName}`],
                      onChange: (e: any) => {
                        methods?.setValue(`otherRadio-${extendedName}`, e.target.value);
                      },
                    }}
                    register={methods?.register}
                    // onBlur={onBlur}
                    onKeyDown={(e: any) => {
                      if (e.key === 'Enter') {
                        if (!preview) e.target.blur(e.target.value);
                      }
                    }}
                    className={clsx(
                      '!mt-2 w-full',
                    )}
                    inputClassName={clsx(
                      'h-[28px] !border-blue-normal-35 !rounded-[4px]',
                    )}
                    readOnly={preview || checkType?.() ? false : true}
                  />
                </div>
              )}
          </div>
        );
      case 'FILE_UPLOAD':
        return (
          <div className={`h-12 flex items-center pl-3 ${previewMobileStyle && 'mt-4'}`}>
            <FileUploadWithProgressBar
              register={methods.register}
              disabled={checkType?.() || preview ? false : true}
              name={`fileUpload${extendedName}`}
              label='Upload file'
              uploadId={-1}
              progress={0}
              imageCard={true}
              loading={uploadLoading}
              accept='.png,.jpeg,.WebP,.HEIF,.pdf,.word,.txt,.ODF'
              handleUpload={(e: any) => {
                methods?.setValue(`fileUpload${extendedName}`, e);
              }}
              control={methods?.control}
            />
          </div>
        );
      case 'RATING':
        return (
          <div
            className={clsx(
              ' ',
              previewMobileStyle
                ? 'mx-auto flex border-[1px] border-[#0973D4_25%] flex-wrap h-auto min-h-12 mt-4 pl-[2px] [&>div]:flex-wrap [&>div]:gap-x-[2px] [&>div]:pr-0'
                : 'h-12 pl-4',
            )}
          >
            <RatingStar
              control={methods.control}
              name={`rating${extendedName}`}
              stars={stars}
              onBlur={(e) => !preview && onBlur?.(e)}
              disabled={checkType?.() || preview ? false : true}
            />
          </div>
        );
      default:
        return null;
    }
  };

  const checkVerify = () => {
    return questionairetype === 'E_CRF' && isViewPatient;
  };
  const isStyledQuestion = !['TITLE'].includes(questionType) && !parentQuestionId;
  const questionNumbering = titleQuestionId ? String.fromCharCode(65 + (questionNumber || 0)).toLowerCase() : questionNumber;

  // const isStyledQuestion = ['REPEATED', 'TITLE']?.includes(questionType);
  return (
    <div
      className={clsx(
        `${title && !parentQuestionId && !isLastQuestion && (questionType !== 'TITLE' || (questionType === 'TITLE' && isQuestionEmpty)) && (!titleQuestionId || isSubLastQuestion) ? 'border-b pb-[24px] mb-[20px] pt-[4px]' : ''}`,
        previewMobileStyle ? 'pl-2 border-none ' : 'pl-[32px] pr-2 border-pink-lace border-dashed ',
      )}
    >
      <div
        className={clsx(
          'questionValues group flex justify-between gap-4 h-max mb-1 items-center',
          previewMobileStyle && 'w-full [&>div]:w-full',
        )}
        key={title}
      >
        {parentQuestionId === null &&
          (questionType === 'REPEATED' ? (
            <PreRepeatedQuestion
              repeatedQuestions={repeatedQuestions}
              title={title}
              preview={preview}
              checkType={checkType}
              questionOptions={questionOptions?.length > 0 ? questionOptions : []}
              PickerTypeEnum={PickerTypeEnum}
              checkVerify={checkVerify}
              setSelectedTable={setSelectedTable}
              selectedTable={selectedTable}
              questionNumber={questionNumber}
            />
          ) : (
            <div className='w-full'>
              {!titleQuestionId ? (
                <div
                  className={clsx(
                    'flex justify-between items-center w-full',
                    previewMobileStyle && 'flex-col ',
                  )}
                >
                  <div className={clsx(previewMobileStyle && 'w-full')}>
                    <p
                      className={clsx(
                        ' mr-1 w-[375px] flex text-left break-words ',
                        questionType === 'TITLE' && '!text-xl font-normal text-blue-oil font-sans',
                        previewMobileStyle
                          ? 'w-full !text-[rgb(14,49,87)] font-medium text-left'
                          : 'font-normal text-blue-oil font-sans',
                      )}
                    >
                      {isStyledQuestion ? (
                        <p className="flex">
                          <span className="mr-1"> {`${questionNumbering}. `} </span>
                          <p className={`${previewMobileStyle ? 'max-w-[200px] w-full' : 'w-[90%] min-w-[375px]'} px-1 mr-0.5`} dangerouslySetInnerHTML={{ __html: questionType !== 'TITLE' && required ? addAsterisk(title) : title }}></p>
                        </p>
                      ) : (
                        `${questionNumbering}. ${title} ${questionType !== 'TITLE' && required ? '*' : ''}`
                      )}
                    </p>
                    {subtitle && (
                      <p
                        className={clsx(
                          'mr-1  mt-4 w-[375px] text-left break-words ',
                          previewMobileStyle
                            ? 'w-full text-[rgb(7,44,63)] font-medium text-left'
                            : 'font-medium text-blue-oil font-sans !pl-[1.4rem]',
                        )}
                      >
                        {subtitle}
                      </p>
                    )}
                  </div>
                  {questionType !== 'TITLE' && (
                    <div
                      className={clsx(
                        'max-w-[30rem] w-[30rem] flex justify-start',
                        previewMobileStyle && 'w-full ',
                      )}
                    >
                      {questionType && renderAnswer()}
                    </div>
                  )}
                </div>
              ) : (
                <div
                  className={clsx(
                    'flex justify-between items-center w-full',
                    previewMobileStyle && 'flex-col ',
                  )}
                >
                  <div className={clsx(previewMobileStyle && 'w-full')}>
                    <p
                      className={clsx(
                        ' pl-[1.4rem] w-[375px] text-left break-words ',
                        questionType === 'TITLE' && '!text-xl font-medium text-blue-oil font-sans',
                        previewMobileStyle
                          ? 'w-full !text-[rgb(14,49,87)] font-medium text-left !pl-0'
                          : 'font-medium text-blue-oil font-sans',
                      )}
                    >
                      {isStyledQuestion ? (
                        <p className='flex'>
                          {`${questionNumbering}. `}{' '}
                          <p className={`${previewMobileStyle ? 'w-[55%]' : 'w-[90%] min-w-[375px]'} px-1`} dangerouslySetInnerHTML={{ __html: questionType !== 'TITLE' && required ? addAsterisk(title) : title }}></p>
                        </p>
                      ) : (
                        `${questionNumbering}. ${title}${questionType !== 'TITLE' && required ? ' *' : ''}`
                      )}
                    </p>
                  </div>
                  {questionType !== 'TITLE' && (
                    <div
                      className={clsx(
                        'max-w-[30rem] w-[30rem] my-3 flex justify-start',
                        previewMobileStyle && 'w-full ',
                      )}
                    >
                      {questionType && renderAnswer()}
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default Question;
