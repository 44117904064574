import { useCentersStore } from 'store/centers';
import SitesTable, { ISiteDataItem, ISitesHeaderItem } from './SitesTable';
import { useEffect, useState } from 'react';
import { retrieveCenters } from 'services/api/study';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

const SiteList: React.FC = () => {
  const intl = useIntl();
  const [columns, setColumns] = useState<ISiteDataItem[]>([])
  const { centers } = useCentersStore();
  const { studyId } = useParams();
  const [selectedRow, setSelectedRow] = useState<ISiteDataItem | null>(null);
  const [showBlur, setShowBlur] = useState(false);

  const onRowClick = (siteCode: string) => {
    if (siteCode === '-1' || selectedRow && selectedRow.siteCode === siteCode) {
      setSelectedRow(null);
      setShowBlur(false);
    } else {
      const site = columns.find(site => site.siteCode === siteCode);
      setSelectedRow(site || null);
      // setShowBlur(true);
    }
  }

  useEffect(() => {
    centers && centers?.length > 0 && setColumns(centers.map((cnt: any) => ({
      ...cnt, 
      id: cnt?.id,
      siteCode: `${cnt?.address?.country?.countryIsoCode || ''}${cnt?.id}`,
      name: cnt?.name,
      country: cnt?.address?.country?.name,
      address: cnt?.address,
      numberOfPatients: cnt?.numberOfPatients || 0,
      durationOfRecruitment: cnt?.durationOfRecruitment,
    })))
  }, [centers]);

  const { refetch } = useQuery({
    queryKey: ['centers'],
    queryFn: () => studyId && retrieveCenters(studyId),
  });

  const sitesHeaderItems: ISitesHeaderItem[] = [
    {
      title: intl.formatMessage({ id: 'study-dashboard.siteCode' }),
      name: 'siteCode',
    },
    {
      title: intl.formatMessage({ id: 'site.siteName' }),
      name: 'name',
      width: 'w-[15rem]',
    },
    {
      title: intl.formatMessage({ id: 'site.noOfPatients' }),
      name: 'numberOfPatients',
    },
    {
      title: intl.formatMessage({ id: 'site.durationRecruitment' }),
      name: 'durationOfRecruitment',
    },
    {
      title: intl.formatMessage({ id: 'site.country' }),
      name: 'country',
    },
    {
      title: intl.formatMessage({ id: 'site.address' }),
      name: 'address',
    },
  ];
  
  return (
    <div className='flex-1 grow container mx-auto'>
      <h2 className='font-sans text-blue-ocean-deep text-[40px] text-left leading-[34px] font-normal'>
        <FormattedMessage id="site.sites" /> - <FormattedMessage id="allStudies.all" />
      </h2>
      <div className='bg-white mt-4 px-4 pt-0 rounded-lg w-full h-[calc(100vh_-_179px)]  overflow-auto no-scrollbar relative border-[1px] border-purple '>
        <img src='/assets/images/bg-logo.png' className='w-[413px] h-[378px] absolute bottom-[4.875rem] left-1/2 -translate-x-1/2' alt='logo' />
        <div className='relative pt-4'>
          <SitesTable
            headerItems={sitesHeaderItems}
            columns={columns}
            onRowClick={onRowClick}
            refetch={refetch}
            selectedRow={selectedRow}
          />
        </div>
        {showBlur && <div className='w-full h-full absolute top-0 left-0 z-10 backdrop-blur-[1.5px]'/>}
      </div>
    </div>
  );
};
export default SiteList;
