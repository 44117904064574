import { FormattedMessage } from 'react-intl';
import { calculateAge } from '../../../../../utils/number';
import { useParticipantStore } from '../../../../../store/participantStore';
import { useCentersStore } from '../../../../../store/centers';
import { useStudyStore } from '../../../../../store/StudyStore';

const InfoBar: React.FC = () => {
  const { participantData } = useParticipantStore();
  const { loggedInStudyUser } = useStudyStore();
  const { centers } = useCentersStore();

  const isSiteStaff = loggedInStudyUser?.role?.category === 'SITE_STAFF';
  const orderIndex = participantData?.id < 10 ? `00${participantData?.id}` : `0${participantData?.id}`;
  const foundCenter = centers?.find((cn: any) => cn.id === participantData?.centerId);
  const BMI = (participantData?.weight && participantData?.height) ?  (participantData?.weight / (participantData?.height ** 2)) * 10000 : 0.0;

  return (
    <div className="flex items-center bg-base-white border-purple border border-l-0 px-10 py-4 mb-6 gap-10 text-blue-ocean-deep">
      <p className="text-xl font-semibold text-blue-ocean-deep">{isSiteStaff ? participantData?.userProfile?.firstName : `${orderIndex}${foundCenter?.address?.country?.countryIsoCode}${participantData.centerId}`}</p>
      <div>
        <p className='font-inter uppercase text-sm text-blue-ocean-deep'><FormattedMessage id='patient-profile.age' /></p>
        <p className='font-inter text-sm text-blue-ocean-deep'>{participantData?.dateOfBirth && calculateAge(participantData?.dateOfBirth)} <FormattedMessage id='patient-profile.yrs' /></p>
      </div>
      <div>
        <p className='font-inter uppercase text-sm text-blue-ocean-deep'><FormattedMessage id='patient-profile.height' /></p>
        <p className='font-inter uppercase text-sm text-blue-ocean-deep'>{participantData?.height} <FormattedMessage id='patient-profile.cm' /></p>
      </div>
      <div>
        <p className='font-inter uppercase text-sm text-blue-ocean-deep'><FormattedMessage id='patient-profile.weight' /></p>
        <p className='font-inter uppercase text-sm text-blue-ocean-deep'>{participantData?.weight} <FormattedMessage id='patient-profile.kg' /></p>
      </div>
      <div>
        <p className='font-inter uppercase text-sm text-blue-ocean-deep'><FormattedMessage id='patient-profile.gender' /></p>
        <p className='font-inter uppercase text-sm text-blue-ocean-deep'>{participantData?.gender || ''}</p>
      </div>
      <div>
        <p className='font-inter uppercase text-sm text-blue-ocean-deep'><FormattedMessage id='patient-profile.bmi' /></p>
        <p className='font-inter uppercase text-sm text-blue-ocean-deep'>{BMI.toFixed(1) || 0.0}</p>
      </div>
      <div>
        <p className='font-inter uppercase text-sm text-blue-ocean-deep'><FormattedMessage id='patient-profile.bloodType' /></p>
        <p className='font-inter uppercase text-sm text-blue-ocean-deep'>{participantData?.bloodType || ''}</p>
      </div>
    </div>
  );
};
export default InfoBar;
