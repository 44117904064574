import { ICenter } from 'types/centers';
import { IRoleCard } from 'types/roles';
import { DoctorSpecialty, Intervention, StudyStatus, StudyType } from 'types/study';
import { create } from 'zustand';

interface ILoggedInStudyUser {
  centerId?: number;
  country?: any;
  id?: number;
  onboardingProgress?: any;
  user?: any;
  role?: IRoleCard;
  studyId?: number | null;
}

interface Study {
  id: number;
  title: string;
  shortTitle: string;
  studyType?: StudyType;
  status?: StudyStatus;
  completionDate?: string;
  sponsorName?: string;
  interventionType?: Intervention;
  enrollmentDate?: string;
  trialPhase?: string;
  conditions?: number[] | string[];
  summaryStudy?: string;
  primaryObjective?: string;
  secondaryObjective?: string;
  financialIncentiveAmount?: string;
  studyDesign?: string;
  startDate?: string | Date;
  totalPatient?: number;
  medicalArea?: DoctorSpecialty;
  numberOfPatient?: number;
  center: string;
  inclusionCriteria?: string;
  exclusionCriteria?: string;
  role: string;
  steps?: any;
  centers?: ICenter[];
  loggedInStudyUser?: ILoggedInStudyUser | null;
  consentQuestionnaire?: any;
  cipNumber?: string;
}

interface StudyStore {
  currentStudy: Study | null;
  selectedStandardForm: any;
  loggedInStudyUser: ILoggedInStudyUser | null;
  setCurrentStudy: (study: Study) => void;
  setSelectedStandardForm: (form: any) => void;
  setLoggedInStudyUser: (loggedInStudyUser: ILoggedInStudyUser) => void;
}

export const useStudyStore = create<StudyStore>((set) => ({
  currentStudy: null,
  loggedInStudyUser: null,
  selectedStandardForm: null,
  setSelectedStandardForm(form) {
    set(() => ({ selectedStandardForm: form }));
  },
  setLoggedInStudyUser(loggedInStudyUser: ILoggedInStudyUser) {
    set(() => ({ loggedInStudyUser }));
  },
  setCurrentStudy(study) {
    set(() => ({ currentStudy: study }));
  },
}));
